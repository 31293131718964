const insuranceCompanies = require("../insuranceCompanies");
module.exports = {
  //  ╔═╗╦═╗╦╔╦╗╦╔╦╗╦╦  ╦╔═╗╔═╗
  //  ╠═╝╠╦╝║║║║║ ║ ║╚╗╔╝║╣ ╚═╗
  //  ╩  ╩╚═╩╩ ╩╩ ╩ ╩ ╚╝ ╚═╝╚═╝
  name: {
    type: "string",
  },

  agreeOrMarket: {
    isIn: ["A", "M"], //market or agree value
    type: "string",
  },
  sumInsured: {
    type: "number",
  },
  price: {
    type: "number",
  },

  deliveryCost: {
    type: "number",
  },

  provider: {
    type: "string",
    isIn: insuranceCompanies.map((company) => company.name),
  },

  features_en: {
    type: "string",
  },
  features_cn: {
    type: "string",
  },

  sort: {
    type: "number",
    defaultsTo: 0,
  },

  riskExcess: {
    type: "number",
    defaultsTo: 0,
  },

  optionals: {
    type: "json",
  },

  //  ╔═╗╔╦╗╔╗ ╔═╗╔╦╗╔═╗
  //  ║╣ ║║║╠╩╗║╣  ║║╚═╗
  //  ╚═╝╩ ╩╚═╝╚═╝═╩╝╚═╝

  //  ╔═╗╔═╗╔═╗╔═╗╔═╗╦╔═╗╔╦╗╦╔═╗╔╗╔╔═╗
  //  ╠═╣╚═╗╚═╗║ ║║  ║╠═╣ ║ ║║ ║║║║╚═╗
  //  ╩ ╩╚═╝╚═╝╚═╝╚═╝╩╩ ╩ ╩ ╩╚═╝╝╚╝╚═╝

  parentQuotation: {
    model: "quotation",
  },
};
