import { createReducer } from "reduxsauce";
import { QuotationTypes } from "store/actions/Quotation";

export const INITIAL_STATE = {
  quotations: {},
};

export const updateQuotation = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line no-param-reassign
  state.quotations[action.quotation.id] = action.quotation;

  Object.assign(state.quotations, { ...state.quotations });

  // Object.assign(state.quotations[action.quotation.id], action.quotation);

  return { ...state };
};

export const HANDLERS = {
  [QuotationTypes.SET_QUOTATION]: updateQuotation,
};

export default createReducer(INITIAL_STATE, HANDLERS);
