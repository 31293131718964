import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import queryString from "query-string";
import QuotationStep from "components/QuotationStep";
import ApiContext from "services/ApiContext";
import { ReactFitty } from "react-fitty";
import { formatMoneyRaw } from "lib";
import { useTranslation } from "react-i18next";

function PaymentStatus(props) {
  const { history, location, match } = props;
  const { Axios } = useContext(ApiContext);
  const { t } = useTranslation();

  const [receipt, setReceipt] = useState(null);

  const quotationId = match.params.id;

  const parsed = queryString.parse(location.search);
  const paid = parsed["billplz[paid]"] === "true";
  // get receipt detail if payment success
  useEffect(() => {
    async function getReceipt() {
      try {
        const newReceipt = await Axios.get(
          `/quotation/${quotationId}/receipt`,
          {
            params: {
              billId: parsed["billplz[id]"],
              xSignature: parsed["billplz[x_signature]"],
            },
          }
        );

        setReceipt(newReceipt);
      } catch (error) {
        setTimeout(() => {
          getReceipt();
        }, 2000);
      }
    }

    if (paid) {
      getReceipt();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const infoAvailable = useMemo(
    () => [
      {
        name: "carPlate",
        displayName: "Car Plate",
      },
      {
        name: "vehicleModel",
        displayName: "Model",
      },
      {
        name: "vehicleVariant",
        displayName: "Variant",
      },
      {
        name: "vehicleYear",
        displayName: "Year",
      },
    ],
    []
  );

  const paymentDetail = useMemo(() => {
    if (!receipt) {
      return [];
    }

    return [
      {
        name: "amount",
        display: receipt.chosenQuotation
          ? `Insurance ${receipt.chosenQuotation.name}`
          : "",
      },
      {
        name: "windscreenSumInsured",
        display: "Windscreen Sum Insured",
      },
      {
        name: "windscreenCoverage",
        display: "Windscreen Coverage",
        type: "boolean",
      },
      {
        name: "llop",
        display: "LLOP",
        type: "boolean",
      },
      {
        name: "specialPerils",
        display: "Special Perils",
        type: "boolean",
      },
      {
        name: "waiverBetterment",
        display: "Waiver of Betterment",
        type: "boolean",
      },
      {
        name: "allDriverCoverage",
        display: "All Driver Coverage",
        type: "boolean",
      },
    ];
  }, [receipt]);

  const renderSuccess = useCallback(() => {
    const { payment } = receipt;
    return (
      <div className="py-3 text-center">
        <i className="ni ni-satisfied ni-3x" />

        <h4 className="heading mt-4">Payment Success!</h4>
        <p>Thank you for your payment! We will contact you soon!</p>

        <Container>
          <h4 className="heading-small text-muted mt-3 text-left">Summary</h4>

          <Row>
            <Col xs="6" className="text-left">
              {infoAvailable.map((info) => {
                if (!receipt[info.name]) {
                  return null;
                }

                return (
                  <Row key={info.name}>
                    <Col xs="6">
                      <h4>{info.displayName}</h4>
                    </Col>
                    <Col xs="6">
                      <ReactFitty maxSize={15}>
                        <p>{receipt[info.name]}</p>
                      </ReactFitty>
                    </Col>
                  </Row>
                );
              })}
              <Row>
                <Col xs="6">
                  <h4>Bill Id:</h4>
                </Col>
                <Col xs="6">
                  <ReactFitty maxSize={15}>
                    <p>{parsed["billplz[id]"]}</p>
                  </ReactFitty>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="text-left">
              <h4 className="heading-small text-muted mt-3 text-left">
                Detail
              </h4>

              {paymentDetail.map((infoKey) => {
                if (Object.keys(payment.detail).indexOf(infoKey.name) !== -1) {
                  let display = t(infoKey.name);
                  if (infoKey.name === "amount") {
                    display = receipt.chosenQuotation
                      ? `Insurance ${t("insuranceAmount", {
                          name: receipt.chosenQuotation.name,
                        })}`
                      : "";
                  }
                  return (
                    <Row key={infoKey.name}>
                      <Col xs="6">{display}</Col>
                      <Col className="text-right">RM</Col>
                      <Col className="text-right">
                        {payment.detail[infoKey.name].toFixed(2)}
                      </Col>
                    </Row>
                  );
                }
                return <React.Fragment key={infoKey.name} />;
              })}

              <Row>
                <Col xs="6">
                  <strong>{t("grossPremium")}</strong>
                </Col>
                <Col className="text-right">
                  <strong>RM</strong>
                </Col>
                <Col className="text-right ">
                  <strong>{formatMoneyRaw(payment.detail.grossPremium)}</strong>
                </Col>
              </Row>

              <Row>
                <Col xs="6">{`SST ${payment.detail.sstRate}%`}</Col>
                <Col className="text-right">RM</Col>
                <Col className="text-right ">
                  {formatMoneyRaw(payment.detail.sstAmount)}
                </Col>
              </Row>
              {receipt.renewRoadTax && (
                <>
                  <Row>
                    <Col xs="6">{t("renewRoadTax")}</Col>
                    <Col className="text-right">RM</Col>
                    <Col className="text-right ">
                      {formatMoneyRaw(payment.detail.roadTax)}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">{t("runnerCost")}</Col>
                    <Col className="text-right">RM</Col>
                    <Col className="text-right ">
                      {formatMoneyRaw(parseFloat(payment.detail.deliveryCost))}
                    </Col>
                  </Row>
                </>
              )}

              <Row>
                <Col xs="6">{t("stampDuty")}</Col>
                <Col className="text-right">RM</Col>
                <Col className="text-right ">
                  {formatMoneyRaw(parseFloat(payment.detail.stampDuty))}
                </Col>
              </Row>

              <Row className="mt-3">
                <Col xs="6">
                  <h3 className="text-success">{t("totalPaid")}</h3>
                </Col>

                <Col className="text-right ">
                  <h3 className="text-success">
                    {`RM ${payment.amount.toFixed(2)}`}
                  </h3>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                color="primary"
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals
                  print();
                }}
                type="button"
              >
                {t("Print")}
              </Button>
              <Button
                color="success"
                onClick={() => {
                  history.push("/");
                }}
                type="button"
              >
                {t("ok")}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }, [history, infoAvailable, parsed, paymentDetail, receipt, t]);

  const renderUnpaid = useCallback(
    () => (
      <div className="py-3 text-center">
        <i className="fa fa-exclamation-triangle ni-3x" />

        <h4 className="heading mt-4">{t("paymentFailed")}</h4>
        <p>{t("paymentFailedInstruction")}</p>
      </div>
    ),
    [t]
  );

  const renderBody = useCallback(() => {
    if (paid) {
      if (!receipt) {
        return (
          <div className="text-center">
            <Spinner />
          </div>
        );
      }
      return renderSuccess();
    }
    return renderUnpaid();
  }, [paid, renderUnpaid, receipt, renderSuccess]);

  let step = 2;

  if (!paid) {
    step = 1;
  }

  return (
    <Row className="mt-md--9">
      <Col xs="12">
        <QuotationStep step={step} />
      </Col>
      <Col xs="12">
        <Card>
          <CardBody>{renderBody()}</CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default PaymentStatus;
