import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions(
  {
    setQuotation: ['quotation'],
  },
  {},
);

export const QuotationTypes = Types;
export default Creators;
