import React, { useMemo } from "react";

import { Stepper } from "react-form-stepper";

export default function AdminQuotationStep(props) {
  const { status } = props;

  const labels = useMemo(
    () => [
      {
        name: "detailFilled",
        label: "Detail Filled",
      },
      {
        name: "pendingCarInfo",
        label: "NCD Retrieved",
      },
      {
        name: "tooEarlyRenew",
        label: "Too Early to Renew",
      },
      {
        name: "pendingQuotation",
        label: "Pending Quotation",
      },
      {
        name: "quotationReady",
        label: "Quotation Ready",
      },
      {
        name: "quotationSent",
        label: "Quotation Sent",
      },
      {
        name: "expired",
        label: "Quotation Expired",
      },
      {
        name: "paid",
        label: "Paid",
      },
      {
        name: "processed",
        label: "Processed",
      },
      {
        name: "dropped",
        label: "Dropped",
      },
    ],
    []
  );

  const currentStep = useMemo(() => {
    const ptr = labels.find((label) => label.name === status);

    return labels.indexOf(ptr);
  }, [labels, status]);

  return (
    <Stepper
      styleConfig={{
        completedBgColor: "#172b4d",
        activeBgColor: "#5e72e4",
      }}
      connectorStyleConfig={{
        completedColor: "#172b4d",
        activeColor: "#5e72e4",
      }}
      steps={labels.map((ptr) => ({
        label: ptr.label,
      }))}
      activeStep={currentStep}
    />
  );
}
