/* eslint-disable */
import React from "react";
import { Modal } from "reactstrap";

function NotificationModal(props) {
  return (
    <Modal
      className={`modal-dialog-centered modal-${props.type}`}
      contentClassName={`bg-gradient-${props.type}`}
      isOpen={props.isOpen}
      toggle={() => props.toggle}
    >
      {props.header && <div className="modal-header">{props.header}</div>}
      <div className="modal-body">{props.body}</div>
    </Modal>
  );
}

export default NotificationModal;
