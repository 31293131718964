/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReactQueryDevtools } from "react-query-devtools";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import { Provider } from "react-redux";
import { ReactQueryCacheProvider, QueryCache } from "react-query";
import { ApiProvider } from "./services/ApiContext";
import App from "./App";
import "./i18n";

import "assets/css/global.css";

import store from "./store";

const queryCache = new QueryCache();

ReactDOM.render(
  <Provider store={store}>
    <ApiProvider>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <App />
        <ReactQueryDevtools initialIsOpen />
      </ReactQueryCacheProvider>
    </ApiProvider>
  </Provider>,
  document.getElementById("root")
);
