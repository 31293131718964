/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useMemo, useState, useCallback } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Spinner,
  Col,
  CardHeader,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Collapse,
} from "reactstrap";
import { connect } from "react-redux";
import Button from "reactstrap-button-loader";

import { withRouter } from "react-router-dom";

import ApiContext from "services/ApiContext";
import { useMutation, useQuery } from "react-query";
import QuotationStep from "components/QuotationStep";
import NotificationModal from "components/NotificationModal";
import { formatMoney } from "lib";
import { useTranslation } from "react-i18next";
import AddQuotationDetailModal from "./AddQuotationDetailModal";
import QuotationCard from "./QuotationCard";
import AdminBar from "./AdminBar";
import CheckoutModal from "./CheckoutModal";
import AdminQuotationInfo from "./AdminQuotationInfo";

function Quotation(props) {
  const { role, match, history } = props;
  const quotationId = match.params.id;
  const { t } = useTranslation();

  const { Axios } = useContext(ApiContext);
  const [sortBy, setSortBy] = React.useState("feature");
  const [filterInsurance, setFilterInsurance] = React.useState({});
  const [insuranceShown, setToggleInsurance] = React.useState(false);
  const [sortShown, setToggleSort] = React.useState(false);
  const [quotationModal, setQuotationModal] = React.useState(false);
  const [selectedDetail, setSelectedDetail] = React.useState({});
  const [form, setForm] = useState({ optional: {} });
  const [checkoutModal, setCheckoutModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);

  const infoAvailable = useMemo(
    () => [
      {
        name: "carPlate",
        displayName: "Car Plate",
      },
      {
        name: "vehicleModel",
        displayName: "Model",
      },
      {
        name: "vehicleVariant",
        displayName: "Variant",
      },
      {
        name: "vehicleCapacity",
        displayName: "CC",
      },

      {
        name: "vehicleYear",
        displayName: "Year",
      },
      {
        name: "NCDexpiryDate",
        displayName: "Expiry Date",
      },
      {
        name: "NCDnext",
        displayName: "Current NCD",
      },
    ],
    []
  );

  const {
    data: quotationDetailData,
    isLoading,
    isError,
    isSuccess,
    error,
  } = useQuery(`quotationDetail/${quotationId}`, async () =>
    Axios.get(`/detail/${quotationId}`)
  );

  const data = useMemo(() => {
    if (quotationDetailData && quotationDetailData.quotationDetails) {
      switch (sortBy) {
        case "priceLowHigh":
          quotationDetailData.quotationDetails.sort(
            (a, b) => a.price - b.price
          );
          break;

        case "priceHighLow":
          quotationDetailData.quotationDetails.sort(
            (a, b) => b.price - a.price
          );
          break;

        default:
          quotationDetailData.quotationDetails.sort((a, b) => a.sort - b.sort);
          break;
      }
    }

    return quotationDetailData;
  }, [quotationDetailData, sortBy]);

  const [mutate, { isLoading: mutateIsLoading }] = useMutation(
    async () => {
      await Axios.post(`/detail/${quotationId}/request-again`);
    },
    {
      onSuccess: () => {
        setNotificationModal(true);
      },
      onError: () => {
        alert(t("requestFail"));
      },
    }
  );

  const optionsAvailable = useMemo(() => {
    const options = [
      {
        name: "windscreenCoverage",
        displayName:
          typeof data === "undefined"
            ? "Windscreen Coverage"
            : `Windscreen Coverage (Sum Insured: ${formatMoney(
                data.windscreenSumInsured
              )})`,
        shortName: "Windscreen Coverage",
      },
      {
        name: "llop",
        displayName: "Legal Liability of Passenger (LLOP)",
        shortName: "LLOP",
      },
      {
        name: "specialPerils",
        displayName: "Special Perils",
        shortName: "Special Perils",
      },
      {
        name: "allDriverCoverage",
        displayName: "All Driver Coverage",
        shortName: "All Driver Coverage",
      },
      {
        name: "waiverBetterment",
        displayName: "Waiver of Betterment",
        shortName: "Waiver of Betterment",
      },
      {
        name: "unlimitedTowing",
        displayName: "Unlimited Towing",
        shortName: "Unlimited Towing",

      },
      {
        name: "driverPassengerPersonalAccident",
        displayName: "Driver and Passenger Personal Accident",
        shortName: "Unlimited Towing",

      },
      {
        name: "extendedWarranty",
        displayName: "Extended Warranty",
        shortName: "Unlimited Towing",

      },
    ];

    return options;
  }, [data]);

  const toggleCheckoutModal = useCallback(() => {
    setCheckoutModal(!checkoutModal);
  }, [checkoutModal]);

  const toggleModal = () => {
    setQuotationModal(!quotationModal);
  };

  const toggleSort = React.useCallback(() => {
    setToggleSort(!sortShown);
  }, [sortShown]);

  const toggleInsurance = React.useCallback(() => {
    setToggleInsurance(!insuranceShown);
  }, [insuranceShown]);

  const onChange = (e) => {
    const newForm = { ...form };
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  };

  const chooseQuotationDetail = (detailId) => {
    const newForm = { ...form };
    newForm.chosenQuotation = detailId;

    setForm(newForm);
    toggleCheckoutModal();
  };

  const optionals = useMemo(() => {
    if (data && data.quotationDetails) {
      return data.quotationDetails
        .map((quotationDetail) => Object.keys(quotationDetail.optionals))
        .reduce((prev, curr) => {
          for (let i = 0; i < curr.length; i += 1) {
            if (prev.indexOf(curr[i]) === -1) {
              prev.push(curr[i]);
            }
          }

          return prev;
        }, []);
    }

    return [];
  }, [data]);

  const successBody = useMemo(
    () => (
      <div className="py-3 text-center">
        <i className="ni ni-notification-70 ni-3x" />

        <h4 className="heading mt-4">{t("thankYou")}</h4>
        <p>{t("willGetBack")}</p>
        <Button
          color="secondary"
          type="button"
          onClick={() => history.push("/")}
        >
          {t("ok")}
        </Button>
      </div>
    ),
    [history, t]
  );

  if (!isSuccess) {
    return (
      <Row>
        <Col>
          <Card>
            <CardBody>
              {isLoading && (
                <div className="py-3 text-center">
                  <Spinner type="grow" color="primary" />
                </div>
              )}
              {isError && (
                <div className="py-3 text-center">
                  <i className="fa fa-exclamation-triangle ni-3x" />
                  {error.response.status === 404 && (
                    <h4 className="heading mt-4">{`${t("noQuotation")}!`}</h4>
                  )}
                  {error.response.status !== 404 && (
                    <h4 className="heading mt-4">
                      {t("noQuotationInstruction")}
                    </h4>
                  )}
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  if (data.expired) {
    return (
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="py-3 text-center">
                <i className="fa fa-exclamation-triangle ni-3x" />

                <h4 className="heading mt-4">
                  {t("expiredQuotationInstruction")}
                </h4>
                <Button
                  color="primary"
                  className="m-4"
                  loading={mutateIsLoading}
                  onClick={() => {
                    mutate();
                  }}
                >
                  {t("requestAgain")}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
        <NotificationModal
          isOpen={notificationModal}
          body={successBody}
          type="success"
        />
      </Row>
    );
  }
  return (
    <>
      <Row className="mt-md--9">
        <Col xs="12">
          <QuotationStep step={1} />
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="3">
          <Card className="bg-secondary shadow mb-2">
            <CardBody>
              <h3 className="card-title">{t("filter")}</h3>
              <h5>
                <NavLink
                  href="#"
                  id="insuranceToggler"
                  onClick={() => {
                    toggleInsurance();
                  }}
                >
                  {`${t("insuranceCompany")} `}
                  <i
                    className={
                      insuranceShown ? "ni ni-bold-down" : "ni ni-bold-up"
                    }
                  />
                </NavLink>
              </h5>
              <Collapse isOpen={insuranceShown}>
                {data.quotationDetails
                  .map((quotationDetail) => quotationDetail.provider)
                  .reduce((prev, provider) => {
                    if (prev.indexOf(provider) === -1) {
                      prev.push(provider);
                    }
                    return prev;
                  }, [])
                  .map((provider) => (
                    <div
                      className="custom-control custom-checkbox mb-3"
                      key={provider}
                    >
                      <input
                        className="custom-control-input"
                        id={`${provider}check`}
                        type="checkbox"
                        checked={!filterInsurance[provider]}
                        onChange={() => {
                          const newFilter = {
                            ...filterInsurance,
                          };
                          newFilter[provider] = !filterInsurance[provider];
                          setFilterInsurance(newFilter);
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`${provider}check`}
                      >
                        {/* {company.name} */}
                        <img
                          width="50%"
                          src={require(`assets/img/insuranceCompanies/${provider}.png`)}
                          alt={provider}
                        />
                      </label>
                    </div>
                  ))}
              </Collapse>
            </CardBody>
          </Card>
          <Card className="bg-secondary shadow mb-2">
            <CardBody>
              <h3 className="card-title">{t("yourInfo")}</h3>

              <Row>
                {infoAvailable.map((info) => {
                  if (
                    data[info.name] &&
                    info.name.toLowerCase().endsWith("date")
                  ) {
                    return (
                      <Col md="12" key={info.name}>
                        <h4>{t(info.name)}</h4>
                        <p>{new Date(data[info.name]).toLocaleDateString()}</p>
                      </Col>
                    );
                  }

                  if (data[info.name]) {
                    return (
                      <Col md="12" key={info.name}>
                        <h4>{t(info.name)}</h4>
                        <p>{data[info.name]}</p>
                      </Col>
                    );
                  }

                  return null;
                })}
                {role !== "user" && (
                  <AdminQuotationInfo quotationId={quotationId} />
                )}
                {/* 
                <Col md="12">
                  <FormGroup>
                    <h4>Marriage Status</h4>
                    <Input
                      type="select"
                      name="marriageStatus"
                      value={form.marriageStatus}
                      onChange={onChange}
                    >
                      <option value="">Please select</option>
                      <option value="single">Single</option>
                      <option value="married">Married</option>
                      <option value="divorce">Divorce</option>
                    </Input>
                  </FormGroup>
                </Col> */}
              </Row>
            </CardBody>
          </Card>
          <Card className="bg-secondary shadow mb-2">
            <CardBody>
              <h3 className="card-title">{t("renewRoadTax")}</h3>

              <Row>
                <Col xs="12">
                  {/* <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      id="customCheck5"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck5"
                    >
                      Unchecked
                    </label>
                  </div> */}
                  <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      id="roadTax"
                      type="checkbox"
                      onChange={(e) => {
                        const newForm = { ...form };
                        newForm.renewRoadTax = e.target.checked;
                        setForm(newForm);
                      }}
                    />
                    <label className="custom-control-label" htmlFor="roadTax">
                      {t("renewWithAmount", {
                        amount: formatMoney(data.roadTax ? data.roadTax : 0),
                      })}
                    </label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="bg-secondary shadow mb-2">
            <CardBody>
              <h3 className="card-title">{t("optionalCover")}</h3>
              <Row>
                {optionals.map((optionKey) => {
                  const option = optionsAvailable.find(
                    (optionPtr) => optionPtr.name === optionKey
                  );

                  if (!option) {
                    return null;
                  }

                  let display = t(option.name);

                  if (option.name === "windscreenCoverage") {
                    display =
                      typeof data === "undefined"
                        ? t("windscreenCoverage")
                        : t("windSreenCoverageWithSumInsured", {
                            sumInsured: formatMoney(data.windscreenSumInsured),
                          });
                  }

                  return (
                    <Col xs="12" key={option.name}>
                      <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                        <input
                          className="custom-control-input"
                          id={option.name}
                          type="checkbox"
                          onChange={(e) => {
                            const newForm = { ...form };

                            newForm.optional[option.name] = e.target.checked;

                            setForm(newForm);
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={option.name}
                        >
                          {display}
                        </label>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col xs="12" md="9">
          <Row>
            {role !== "user" && (
              <AdminBar quotationId={quotationId} data={data} />
            )}

            <Col md="12">
              <Card className="shadow" style={{ minHeight: "800px" }}>
                <CardHeader>
                  <div className="text-right">
                    <Dropdown isOpen={sortShown} toggle={toggleSort}>
                      <DropdownToggle caret>
                        {t(`sortByOption.${sortBy}`)}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setSortBy("feature");
                          }}
                        >
                          {t("sortByOption.feature")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setSortBy("priceLowHigh");
                          }}
                        >
                          {t("sortByOption.priceLowHigh")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setSortBy("priceHighLow");
                          }}
                        >
                          {t("sortByOption.priceHighLow")}
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    {data.quotationDetails.map((quotationDetail) => {
                      if (filterInsurance[quotationDetail.provider]) {
                        return null;
                      }

                      return (
                        <QuotationCard
                          role={role}
                          key={quotationDetail.id}
                          quotation={data}
                          detail={quotationDetail}
                          form={form}
                          chooseQuotationDetail={chooseQuotationDetail}
                          onEdit={() => {
                            setSelectedDetail(quotationDetail);
                            toggleModal();
                          }}
                        />
                      );
                    })}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      {role !== "user" && (
        <AddQuotationDetailModal
          quotationId={quotationId}
          quotationModal={quotationModal}
          toggleModal={toggleModal}
          details={selectedDetail}
        />
      )}

      <CheckoutModal
        form={form}
        isOpen={checkoutModal}
        onChange={onChange}
        data={data}
        toggleModal={toggleCheckoutModal}
        optionsAvailable={optionsAvailable}
        quotationId={quotationId}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  let role = "user";
  if (state.User.user) {
    role = state.User.user.role;
  }
  return {
    role,
  };
};

export default connect(mapStateToProps)(withRouter(Quotation));
