/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AdminLayout from "layouts/Admin";
import PublicLayout from "layouts/Public";
import UserAction from "store/actions/User";

import axios from "axios";

export default function App() {
  const token = useSelector((state) => state.User.token);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getCSRFToken() {
      const resp = await axios.get("/api/csrftoken");

      // eslint-disable-next-line no-underscore-dangle
      dispatch(UserAction.setCSRF(resp.data._csrf));
    }

    getCSRFToken();
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Switch>
        {token && (
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        )}
        <Route path="/" render={(props) => <PublicLayout {...props} />} />
      </Switch>
    </BrowserRouter>
  );
}
