import React, { useContext, useEffect, useState } from "react";
// reactstrap components
import { FormGroup, Form, Input, Modal, Row, Col, Label } from "reactstrap";
import Button from "reactstrap-button-loader";
import { useMutation, useQueryCache } from "react-query";
import ApiContext from "services/ApiContext";

function AddUserModal({ isEdit, user, isOpen }) {
  const [userModal, setUserModal] = useState(isOpen);
  const [form, setForm] = useState(user);
  const { Axios } = useContext(ApiContext);
  const queryCache = useQueryCache();

  useEffect(() => {
    if (isOpen) {
      setForm(user);
      setUserModal(isOpen);
    }
  }, [isOpen, user]);

  useEffect(() => {
    if (!userModal) {
      setForm({});
    }
  }, [userModal]);

  const [mutate, { isLoading }] = useMutation(
    async (newForm) => {
      if (isEdit) {
        const { id } = newForm;
        const newData = {
          ...newForm,
        };
        delete newData.id;
        await Axios.patch(`/user/${id}`, newData);
      } else {
        await Axios.post("/user", newForm);
      }
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("users");
        setUserModal();
      },

      onError: (error) => {
        if (error.response.data.code === "E_UNIQUE") {
          return alert("User already exists");
        }
        return alert("Error, please try again later");
      },
    }
  );

  const toggleModal = () => {
    setUserModal(!userModal);
  };

  const onChange = (e) => {
    form[e.target.name] = e.target.value;
    setForm(form);
  };

  return (
    <>
      {/* Button trigger modal */}
      <Button
        color="primary"
        type="button"
        className="float-right"
        onClick={() => toggleModal("userModal")}
      >
        Add New User
      </Button>
      {/* Modal */}
      <Modal
        className="modal-dialog-centered"
        isOpen={userModal}
        toggle={() => toggleModal("userModal")}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="userModalLabel">
            Add New User
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal("userModal")}
          >
            <span aria-hidden>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label for="email">Email Address</Label>
                  <Input
                    name="emailAddress"
                    placeholder="name@example.com"
                    type="email"
                    value={form.emailAddress}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="fullname">Full Name</Label>
                  <Input
                    name="fullName"
                    placeholder="John Doe"
                    type="string"
                    value={form.fullName}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    name="password"
                    placeholder="password"
                    type="password"
                    value={form.password}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="role">Role</Label>
                  <Input
                    type="select"
                    name="role"
                    value={form.role}
                    onChange={onChange}
                  >
                    <option>Please select</option>
                    <option value="superadmin">Superadmin</option>
                    {/* <option value="admin">Admin</option> */}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal("userModal")}
          >
            Close
          </Button>
          <Button
            color="success"
            type="button"
            loading={isLoading}
            onClick={() => {
              mutate(form);
            }}
          >
            {isEdit ? "Edit" : "Add"}
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default AddUserModal;
