/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";

import "flag-icon-css/css/flag-icon.min.css";
import { useTranslation } from "react-i18next";

function AdminNavbar() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const lang = i18n.language;

  return (
    <>
      <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
        <Container className="px-4">
          <NavbarBrand to="/" tag={Link}>
            <img alt="..." src={require("assets/img/brand/blue-logo.png")} />
          </NavbarBrand>

          <div>
            <Button
              color={lang === "en" ? "primary" : "secondary"}
              size="sm"
              active={lang === "en"}
              onClick={() => {
                changeLanguage("en");
              }}
            >
              <span className="btn-inner--icon">
                <i className="flag-icon flag-icon-us flag-icon-squared" />
                <span className="btn-inner--text">EN</span>
              </span>
            </Button>
            <Button
              color={lang === "cn" ? "primary" : "secondary"}
              active={lang === "cn"}
              size="sm"
              onClick={() => {
                changeLanguage("cn");
              }}
            >
              <span className="btn-inner--icon">
                <i className="flag-icon flag-icon-cn flag-icon-squared" />
                <span className="btn-inner--text">中文</span>
              </span>
            </Button>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            id="navbar-collapse-main"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/">
                    <img
                      alt="..."
                      src={require("assets/img/brand/blue-logo.png")}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    id="navbar-collapse-main"
                    type="button"
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
