import React, { useState } from "react";
import { Card, CardBody, CardHeader, Col, Collapse, Row } from "reactstrap";

import { models } from "lib";
import RenderForm from "components/RenderForm";

export default function QuotationInfo(props) {
  const { quotation, customer, editMode, onChange, onCustomerChange } = props;
  const [isCollapse, setIsCollapse] = useState(true);

  const toggleCollapse = () => {
    setIsCollapse(!isCollapse);
  };
  const customerInfo = [
    "vehicleModel",
    "vehicleVariant",
    "vehicleCapacity",
    "vehicleYear",
    "type",
  ];

  return (
    <Card className="mb-3">
      <CardHeader
        onClick={() => {
          toggleCollapse();
        }}
      >
        <Row className="align-items-center">
          <Col xs="8"> Quotation Info</Col>
          <Col className="text-right" xs="4">
            {isCollapse ? (
              <i className=" ni ni-bold-down" />
            ) : (
              <i className=" ni ni-bold-up" />
            )}
          </Col>
        </Row>
      </CardHeader>

      <Collapse isOpen={isCollapse}>
        <CardBody>
          <h3>Customer Info</h3>
          <Row>
            {customerInfo.map((objKey) => (
              <RenderForm
                attributeObj={models.Customer[objKey]}
                value={customer[objKey]}
                key={objKey}
                name={objKey}
                editMode={editMode}
                onChange={onCustomerChange}
                id={customer.id}
              />
            ))}
          </Row>
          <h3>Quotation Info</h3>
          <Row>
            {Object.keys(models.Quotation).map((objKey) => (
              <RenderForm
                attributeObj={models.Quotation[objKey]}
                value={quotation[objKey]}
                key={objKey}
                name={objKey}
                editMode={editMode}
                onChange={onChange}
                id={quotation.id}
              />
            ))}
          </Row>
        </CardBody>
      </Collapse>
    </Card>
  );
}
