import React, { useContext, useMemo } from "react";

import { useQuery } from "react-query";
import { Col } from "reactstrap";
import ApiContext from "services/ApiContext";

export default function AdminQuotationInfo(props) {
  const { quotationId } = props;
  const { Axios } = useContext(ApiContext);

  const { data, isSuccess } = useQuery(`quotation/${quotationId}`, async () =>
    Axios.get(`/quotation/${quotationId}`)
  );

  const info = useMemo(
    () => [
      {
        name: "name",
        display: "Name",
      },
      {
        name: "emailAddress",
        display: "Email Address",
      },
      {
        name: "icNo",
        display: "Ic No.",
      },
      {
        name: "postcode",
        display: "Postcode",
      },
      {
        name: "phoneNo",
        display: "Phone No.",
      },
      {
        name: "type",
        display: "Vehicle Type",
      },
      {
        name: "previousProvider",
        display: "Previous Provider",
      },
      {
        name: "NCDcurrent",
        display: "Current Percentage",
      },
      {
        name: "NCDnext",
        display: "Next Percentage",
      },
      {
        name: "NCDcurrentDate",
        display: "Renewed Date",
        type: "date",
      },
      {
        name: "NCDnextDate",
        display: "Expiry Date",
        type: "date",
      },
      {
        name: "NCDcoverPeriod",
        display: "Coverage Period",
      },
    ],
    []
  );

  if (isSuccess) {
    return (
      <>
        {info.map((ptr) => (
          <Col md="12" key={ptr.name}>
            <h4>{ptr.display}</h4>

            <p>
              {ptr.type === "date"
                ? new Date(data[ptr.name]).toDateString()
                : data[ptr.name]}
            </p>
          </Col>
        ))}
      </>
    );
  }

  return <></>;
}
