import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
// reactstrap components
import {
  FormGroup,
  Form,
  Input,
  Modal,
  Row,
  Col,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import Button from "reactstrap-button-loader";
import { useMutation, useQueryCache } from "react-query";

import {
  insuranceCompanies,
  optionals,
  kurniaCoverageTypes,
  coverageTypes,
  axaCoverageTypes,
  formatMoney,
} from "lib";

import ApiContext from "services/ApiContext";

function AddQuotationDetailModal(props) {
  const { quotationId, details, quotationModal, toggleModal } = props;

  const [inputForm, setForm] = useState({
    name: "",
    parentQuotation: quotationId,
    agreeOrMarket: "A",
    riskExcess: 0,
    optionals: optionals
      .map((option) => {
        const obj = {};
        obj[option.name] = "";
        return obj;
      })
      .reduce(
        (prev, curr) => ({
          ...prev,
          ...curr,
        }),
        {}
      ),
    ...details,
  });
  const [enabledOptions, setEnableOptions] = useState(
    optionals
      .map((option) => {
        const obj = {};
        if (option.name !== "waiverBetterment") {
          obj[option.name] = true;
        }
        return obj;
      })
      .reduce(
        (prev, curr) => ({
          ...prev,
          ...curr,
        }),
        {}
      )
  );
  // const [features, setFeatures] = useState({});
  // const [featureList, setFeature] = useState([]);

  const runnerCostOption = useMemo(() => [5, 10, 15, 20], []);

  const { Axios } = useContext(ApiContext);
  const queryCache = useQueryCache();

  const [mutate, { isLoading }] = useMutation(
    async () => {
      const insuranceCompanyDetail = insuranceCompanies.find(
        (ptr) => ptr.name === inputForm.provider
      );

      if (!insuranceCompanyDetail) {
        throw new Error("Please select the insurance provider");
      }

      if (inputForm.id) {
        await Axios.patch(`/quotationdetail/${inputForm.id}`, {
          ...inputForm,
          // features,
        });
      } else {
        await Axios.post("/quotationdetail", { ...inputForm });
      }
    },

    {
      onSuccess: () => {
        queryCache.invalidateQueries(`quotationDetail/${quotationId}`);
        toggleModal();
      },
      onError: (error) => {
        alert(`Failed to create quotatio: ${error}`);
      },
    }
  );

  React.useEffect(() => {
    // setFeatures();

    setForm({
      name: "",
      parentQuotation: quotationId,
      agreeOrMarket: "A",
      riskExcess: 0,
      optionals: optionals
        .map((option) => {
          const obj = {};
          obj[option.name] = "";
          return obj;
        })
        .reduce(
          (prev, curr) => ({
            ...prev,
            ...curr,
          }),
          {}
        ),

      ...details,
    });
    if (details && details.optionals) {
      setEnableOptions(
        Object.keys(details.optionals)
          .map((key) => {
            const obj = {};
            obj[key] = true;

            return obj;
          })
          .reduce(
            (prev, curr) => ({
              ...prev,
              ...curr,
            }),
            {}
          )
      );
    }
  }, [details, quotationId, quotationModal]);

  const onChange = useCallback(
    (e) => {
      const newForm = { ...inputForm };
      newForm[e.target.name] = e.target.value;
      setForm(newForm);
    },
    [inputForm]
  );

  const onOptionalChange = useCallback(
    (e) => {
      const newForm = { ...inputForm };
      if (!newForm.optionals) {
        newForm.optionals = {};
      }
      newForm.optionals[e.target.name] = parseFloat(e.target.value);
      setForm(newForm);
    },
    [inputForm]
  );

  const retrieveOptionalCost = useCallback(async () => {
    const resp = await Axios.get("/quotation-detail/calculate-formula", {
      params: {
        quotationId,
        provider: inputForm.provider,
        sumInsured: inputForm.sumInsured,
        // windscreenSumInsured: inputForm.windscreenSumInsured,
      },
    });

    const newOptionals = {};
    Object.keys(enabledOptions).map((key) => {
      if (enabledOptions[key]) {
        newOptionals[key] = resp[key];
      }
      return "";
    });

    setForm({
      ...inputForm,
      optionals: newOptionals,
    });
  }, [Axios, enabledOptions, inputForm, quotationId]);

  // const addNewFeature = () => {
  //   const newFeatureList = [...featureList];

  //   newFeatureList.push({
  //     key: uuidv1(),
  //   });

  //   console.log(newFeatureList);

  //   setFeature(newFeatureList);
  // };

  // const retrieveFeature = useCallback(
  // }, [Axios, inputForm]);

  const coverageTypesOptions = useMemo(() => {
    if (inputForm.provider === "kurnia") {
      return kurniaCoverageTypes;
    }
    if (inputForm.provider === "axa") {
      return axaCoverageTypes;
    }
    return coverageTypes;
  }, [inputForm.provider]);

  useEffect(() => {
    const retrieveFeature = async () => {
      if (!inputForm.provider) {
        return;
      }

      const featuresObj = await Promise.all(
        ["en", "cn"].map(async (lang) => {
          const obj = {};
          obj[`features_${lang}`] = "";
          const data = await Axios.get(
            `/setting?key=${inputForm.provider}Features_${lang}`
          );

          if (data && data.length > 0) {
            obj[`features_${lang}`] = data[0].value;
          }
          return obj;
        })
      );
      const features = featuresObj.reduce(
        (prev, curr) => ({
          ...prev,
          ...curr,
        }),
        {}
      );

      setForm((i) => ({
        ...i,
        ...features,
      }));
    };

    retrieveFeature();
  }, [Axios, inputForm.provider]);

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={quotationModal}
        toggle={() => toggleModal()}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="userModalLabel">
            Add a quotation
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            <span aria-hidden>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <Row>
              <Col md="12">
                {inputForm.provider && inputForm.provider !== "" && (
                  <img
                    src={require(`assets/img/insuranceCompanies/${inputForm.provider}.png`)}
                    alt={inputForm.provider}
                    style={{ maxHeight: "100px" }}
                  />
                )}
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="provider">Insurance Company</Label>
                  <Input
                    type="select"
                    name="provider"
                    onChange={onChange}
                    value={inputForm.provider}
                  >
                    <option value="">Please select</option>
                    {insuranceCompanies.map((insuranceCompany) => (
                      <option
                        value={insuranceCompany.name}
                        key={`${insuranceCompany.name}opt`}
                      >
                        {insuranceCompany.displayName}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>

              <Col md="12">
                <FormGroup>
                  <Label for="name">Coverage Type</Label>
                  <InputGroup className="mb-4">
                    <Input
                      type="select"
                      name="name"
                      onChange={onChange}
                      value={inputForm.name}
                    >
                      <option value="">Please select</option>
                      {coverageTypesOptions.map((coverageType) => (
                        <option value={coverageType} key={coverageType}>
                          {coverageType}
                        </option>
                      ))}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col md="12">
                <FormGroup>
                  <Label for="price">Price</Label>

                  <InputGroup className="mb-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>RM</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="price"
                      placeholder="XXX"
                      type="number"
                      value={inputForm.price}
                      onChange={onChange}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="12">
                <div className="custom-control custom-radio mb-3">
                  <input
                    className="custom-control-input"
                    defaultChecked={inputForm.agreeOrMarket === "A"}
                    id="agreeValue"
                    name="agreeOrMarket"
                    type="radio"
                    onChange={() => {
                      setForm({
                        ...inputForm,
                        agreeOrMarket: "A",
                      });
                    }}
                  />
                  <label className="custom-control-label" htmlFor="agreeValue">
                    Agreed Value
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    className="custom-control-input"
                    defaultChecked={inputForm.agreeOrMarket === "M"}
                    id="marketValue"
                    name="agreeOrMarket"
                    type="radio"
                    onChange={() => {
                      setForm({
                        ...inputForm,
                        agreeOrMarket: "M",
                      });
                    }}
                  />
                  <label className="custom-control-label" htmlFor="marketValue">
                    Market Value
                  </label>
                </div>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="sumInsured">Sum Insured</Label>
                  <InputGroup className="mb-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>RM</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="sumInsured"
                      value={inputForm.sumInsured}
                      placeholder="XXX"
                      type="number"
                      onChange={onChange}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="riskExcess">Risk Excess</Label>
                  <InputGroup className="mb-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>RM</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="riskExcess"
                      value={inputForm.riskExcess}
                      placeholder="XXX"
                      type="number"
                      onChange={onChange}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              {/* <Col md="12">
                <FormGroup>
                  <Label for="windscreenSumInsured">Windscreen Insured</Label>
                  <InputGroup className="mb-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>RM</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="windscreenSumInsured"
                      value={inputForm.windscreenSumInsured}
                      placeholder="XXX"
                      type="number"
                      onChange={onChange}
                    />
                  </InputGroup>
                </FormGroup>
              </Col> */}
              <hr />
              <h3>Optional Cover Cost</h3>
              <Col md="12" className="mb-3">
                <Button
                  color="primary"
                  onClick={() => {
                    retrieveOptionalCost();
                  }}
                >
                  Calculate All Optional Cover Cost Below
                </Button>
              </Col>

              {optionals.map((option) => (
                <Col md="12" key={option.name}>
                  <FormGroup>
                    <Label for={option.name}>{option.displayName}</Label>

                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        defaultChecked={enabledOptions[option.name]}
                        id={`${option.name}-enable`}
                        type="checkbox"
                        onChange={(e) => {
                          const newOptions = {
                            ...enabledOptions,
                          };
                          newOptions[option.name] = e.target.checked;

                          if (!e.target.checked) {
                            const newOptionals = inputForm.optionals;

                            delete newOptionals[option.name];

                            const newInputForm = inputForm;
                            newInputForm.optionals = newOptionals;
                            setForm(newInputForm);
                          }

                          setEnableOptions(newOptions);
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`${option.name}-enable`}
                      >
                        Enabled
                      </label>
                    </div>
                    {enabledOptions[option.name] && (
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>RM</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name={option.name}
                          placeholder="XXX"
                          type="number"
                          value={
                            inputForm.optionals
                              ? inputForm.optionals[option.name]
                              : ""
                          }
                          onChange={onOptionalChange}
                        />
                      </InputGroup>
                    )}
                  </FormGroup>
                </Col>
              ))}

              <Col md="12">
                {/* <FormGroup>
                  <Label for="deliveryCost">Runner Cost</Label>
                </FormGroup>
               <InputGroup className="mb-4">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>RM</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="deliveryCost"
                    placeholder="XXX"
                    type="number"
                    value={inputForm.deliveryCost}
                    onChange={onChange}
                  />
                </InputGroup> */}
                <FormGroup tag="fieldset">
                  <Label>Runner Cost</Label>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="radio1"
                        defaultChecked={inputForm.deliveryCost === 0}
                        onChange={() => {
                          setForm({
                            ...inputForm,
                            deliveryCost: 0,
                          });
                        }}
                      />
                      Free
                    </Label>
                  </FormGroup>
                  {runnerCostOption.map((runnerCost) => (
                    <FormGroup check key={`runnerCost-${runnerCost}`}>
                      <Label check>
                        <Input
                          type="radio"
                          name="radio1"
                          defaultChecked={inputForm.deliveryCost === runnerCost}
                          onChange={() => {
                            setForm({
                              ...inputForm,

                              deliveryCost: runnerCost,
                            });
                          }}
                        />
                        {formatMoney(runnerCost)}
                      </Label>
                    </FormGroup>
                  ))}
                </FormGroup>
              </Col>
              {["en", "cn"].map((lang) => (
                <Col md="12" key={`feature_${lang}`}>
                  <FormGroup>
                    <Label for="feature">
                      Feature
                      {`(${lang})`}
                    </Label>
                    <Input
                      name="feature"
                      placeholder="Write your feature here ..."
                      value={inputForm[`features_${lang}`]}
                      rows="3"
                      onChange={(e) => {
                        const obj = {};
                        obj[`features_${lang}`] = e.target.value;
                        setForm({
                          ...inputForm,
                          ...obj,
                        });
                      }}
                      type="textarea"
                    />
                  </FormGroup>
                </Col>
              ))}

              {/* <Col md="12">
                <Label>Features</Label>
                {featureList.map((featureElement) => {
                  return (
                    <>
                      <Row>
                        <Input
                          key={featureElement.key}
                          name={featureElement.key}
                          placeholder="Write your feature here ..."
                          rows="3"
                          type="textarea"
                        />
                      </Row>
                    </>
                  );
                })}
                <br />
                <Button
                  className="btn-icon btn-2"
                  color="success"
                  size="sm"
                  type="button"
                  onClick={addNewFeature}
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-fat-add" />
                  </span>
                </Button>
              </Col> */}
            </Row>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            Close
          </Button>
          <Button
            color="success"
            type="button"
            loading={isLoading}
            onClick={() => {
              mutate(inputForm);
            }}
          >
            {inputForm.id ? "Edit" : "Create"}
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default AddQuotationDetailModal;
