import React, { useContext, useMemo, useState } from "react";
import { useMutation, useQueryCache } from "react-query";
import { FormGroup, Form, Input, Modal, Row, Col, Label } from "reactstrap";
import Button from "reactstrap-button-loader";
import ApiContext from "services/ApiContext";

export default function AddBotJobModal(props) {
  const { quotationId } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [form, setForm] = useState({});
  const queryCache = useQueryCache();
  const { Axios } = useContext(ApiContext);

  const onChange = (e) => {
    form[e.target.name] = e.target.value;
    setForm(form);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const [mutate, { isLoading }] = useMutation(
    async () => {
      await Axios.post(`/quotation/${quotationId}/rerun-job`, {
        type: form.type,
      });
    },

    {
      onSuccess: () => {
        queryCache.invalidateQueries(`quotation/${quotationId}/jobs`);
        toggleModal();
      },
      onError: () => {
        alert("Failed to submit this job");
      },
    }
  );

  const options = useMemo(
    () => [
      {
        value: "kurnia-comprehensive-plus",
        display: "Kurnia Comprehensive Plus",
      },
      {
        value: "kurnia-comprehensive-premier",
        display: "Kurnia Comprehensive Premier",
      },
      {
        value: "ncdcheck",
        display: "NCD Check",
      },
    ],
    []
  );

  return (
    <>
      <Button
        color="primary"
        type="button"
        size="sm"
        className="float-right"
        onClick={() => toggleModal()}
      >
        Get Bot Quotation
      </Button>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={() => toggleModal()}
      >
        <div className="modal-header">
          <h5 className="modal-title">Get Bot Quotation</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            <span aria-hidden>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label for="type">Type</Label>
                  <Input type="select" name="type" onChange={onChange}>
                    <option value="">Please select</option>
                    {options.map((optionPtr) => (
                      <option value={optionPtr.value} key={optionPtr.value}>
                        {optionPtr.display}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            Close
          </Button>
          <Button
            color="success"
            type="button"
            loading={isLoading}
            onClick={() => {
              mutate();
            }}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
}
