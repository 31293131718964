import React, { useContext, useState } from "react";
import { useMutation, useQueryCache } from "react-query";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap";
import ApiContext from "services/ApiContext";

export default function ModifyCarModel(props) {
  const { isOpen, toggleModal, quotationId } = props;
  const { Axios } = useContext(ApiContext);
  const queryCache = useQueryCache();

  const [form, setForm] = useState({});

  const [mutate, { isLoading }] = useMutation(
    async () => Axios.post(`/quotation/${quotationId}/updateCar`, form),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(`quotation/${quotationId}`);
        toggleModal();
      },
      onError: () => {
        alert("Failed to update quotation, please try again later");
      },
    }
  );

  const onChange = (e) => {
    form[e.target.name] = e.target.value;
    setForm(form);
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="userModalLabel">
            Modify Car Model
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            <span aria-hidden>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label for="vehicleModel">Vehicle Model</Label>
                  <Input
                    name="vehicleModel"
                    placeholder="PERODUA MYVI EZ"
                    type="string"
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="vehicleVariant">Vehicle Variant</Label>
                  <Input
                    name="vehicleVariant"
                    placeholder="EZ FACELIFT 4 SP AUTOMATIC"
                    type="string"
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="vehicleCapacity">CC</Label>
                  <Input
                    name="vehicleCapacity"
                    placeholder="1499"
                    type="string"
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="vehicleYear">Vehicle year</Label>
                  <Input
                    name="vehicleYear"
                    placeholder="2020"
                    type="number"
                    className="no-spin"
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            Close
          </Button>
          <Button
            color="success"
            type="button"
            loading={isLoading}
            onClick={() => {
              mutate();
            }}
          >
            Update
          </Button>
        </div>
      </Modal>
    </>
  );
}
