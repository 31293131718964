import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
// import { v4 as uuidv4 } from "uuid";

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Input,
  Row,
  Col,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Badge,
  Spinner,
} from "reactstrap";
import Button from "reactstrap-button-loader";

import ApiContext from "services/ApiContext";

import { useQuery, useMutation, useQueryCache } from "react-query";

// core components
import Header from "components/Headers/Header";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import DataTable from "components/DataTable";
import { v4 as uuidv4 } from "uuid";
import DatepickerRange from "components/DatepickerRange";

function Quotations() {
  const [data, setData] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [checkedRows, setCheckedRows] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [searchBy, setSearchBy] = React.useState({
    key: "carPlate",
    display: "Car Plate",
  });
  const [renderKey, setRenderKey] = React.useState(uuidv4());

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateSearchBy, setDateSearchBy] = useState({
    key: "NCDexpiryDate",
    display: "Expiry Date",
  });
  const [quotationCount, setQuotationCount] = React.useState(0);
  const [isError, setIsError] = useState(false);

  const { Axios } = useContext(ApiContext);
  const queryCache = useQueryCache();
  const tableRef = useRef();

  const { data: pendingJobs, isSuccess: pendingJobsSuccess } = useQuery(
    "customerPendingJob",
    async () => Axios.get("/customer/get-pending-jobs")
  );

  const { data: users, isSuccess: userSuccess } = useQuery("users", async () =>
    Axios.get("/user")
  );

  const [mutate, { isLoading: isMutateLoading }] = useMutation(
    async (userId) => {
      await Axios.delete(`/customer/${userId}`);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("customerCount");
        setRenderKey(uuidv4());
      },
      onError: () => {
        alert("Failed to remove the record, please try to refresh the page");
      },
    }
  );

  const deleteAlert = React.useCallback(
    (rowId) => {
      confirmAlert({
        title: "Confirm to delete",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              mutate(rowId);
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    },
    [mutate]
  );

  const retrieveLatestNCD = React.useCallback(
    async (customerId) => {
      try {
        await Axios.post(`/customer/${customerId}/ncdCheck`);
      } catch {
        alert(
          "Failed to initiate to retrieve the latest NCD, please try refreshing the page"
        );
      }
    },
    [Axios]
  );

  const checkAllNCDAlert = React.useCallback(() => {
    confirmAlert({
      title: "Initiate NCD checking for these customer(s)?",
      message: "Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            checkedRows.map((rowId) => retrieveLatestNCD(rowId));
            setCheckedRows([]);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }, [checkedRows, retrieveLatestNCD]);

  const deleteAllCheckedAlert = React.useCallback(() => {
    confirmAlert({
      title: `Confirm to delete ${checkedRows.length} customer(s)`,
      message: "Are you sure to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            checkedRows.map((rowId) => mutate(rowId));
            setCheckedRows([]);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }, [checkedRows, mutate]);

  const toggleAllCheckbox = React.useCallback(
    (isChecked) => {
      if (isChecked) {
        setCheckedRows(data.map((row) => row.id));
      } else {
        setCheckedRows([]);
      }
    },
    [data]
  );

  const toggleCheckbox = React.useCallback(
    (id, isChecked) => {
      const newCheckedRows = [...checkedRows];

      if (isChecked) {
        newCheckedRows.push(id);
      } else {
        const index = newCheckedRows.indexOf(id);
        if (index > -1) {
          newCheckedRows.splice(index, 1);
        }
      }
      setCheckedRows(newCheckedRows);
    },
    [checkedRows]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: " ",
        isCheckbox: true,
        Cell: ({ row }) => {
          const { id } = row.original;
          return (
            <Input
              type="checkbox"
              checked={checkedRows.indexOf(id) !== -1}
              onChange={(e) => {
                toggleCheckbox(id, e.target.checked);
              }}
              style={{
                position: "relative",
                verticalAlign: "middle",
              }}
            />
          );
        },
      },

      {
        Header: "Car Plate",
        accessor: "carPlate",
        // Cell: (cell) => (
        //   <a href={`/admin/customers/${cell.row.original.id}`}>{cell.value}</a>
        // ),
      },
      {
        Header: "IC No",
        accessor: "icNo",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Phone Number",
        accessor: "phoneNo",
      },
      {
        Header: "type",
        accessor: "type",
      },
      {
        Header: "Vehicle Model",
        accessor: "vehicleModel",
      },
      {
        Header: "Vehicle Variant",
        accessor: "vehicleVariant",
      },
      {
        Header: "CC",
        accessor: "vehicleCapacity",
      },
      {
        Header: "Expiry Date",
        accessor: "NCDexpiryDate",
        Cell: (cell) => {
          if (cell.value === 0) {
            return "";
          }

          const ncdDate = new Date(cell.value);

          if (pendingJobsSuccess) {
            const pendingJob = pendingJobs.find(
              (ptr) => ptr.initiator === cell.row.original.id
            );

            const pendingState = ["wait", "active", "delayed"];
            if (pendingJob && pendingState.indexOf(pendingJob.state) !== -1) {
              return <Spinner />;
            }
          }

          if (new Date() >= ncdDate) {
            return <Badge color="danger">{ncdDate.toDateString()}</Badge>;
          }

          return ncdDate.toDateString();
        },
      },
      {
        Header: "Last updated",
        accessor: "updatedAt",
        Cell: (cell) => {
          if (cell.value === 0) {
            return "";
          }

          const NCDUpdatedDate = new Date(cell.value);

          if (pendingJobsSuccess) {
            const pendingJob = pendingJobs.find(
              (ptr) => ptr.initiator === cell.row.original.id
            );

            const pendingState = ["wait", "active", "delayed"];
            if (pendingJob && pendingState.indexOf(pendingJob.state) !== -1) {
              return <Spinner />;
            }
          }

          return NCDUpdatedDate.toDateString();
        },
      },
      {
        Header: "Source",
        accessor: "source",
        Cell: ({ value }) => {
          if (value === "web") {
            return "Website";
          }
          if (userSuccess) {
            const user = users.find((ptr) => ptr.id === value);
            if (user) {
              return user.fullName;
            }
            return "";
          }

          return <Spinner />;
        },
      },

      {
        Header: "Operation",
        Cell: (cell) => (
          <>
            <UncontrolledDropdown>
              <DropdownToggle caret size="sm">
                Operation
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  disabled={isMutateLoading}
                  onClick={() => {
                    retrieveLatestNCD(cell.row.original.id);
                  }}
                >
                  Get Latest NCD
                </DropdownItem>
                <DropdownItem
                  disabled={isMutateLoading}
                  onClick={() => {
                    deleteAlert(cell.row.original.id);
                  }}
                >
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        ),
      },
    ],
    [
      checkedRows,
      deleteAlert,
      isMutateLoading,
      pendingJobs,
      pendingJobsSuccess,
      retrieveLatestNCD,
      toggleCheckbox,
      userSuccess,
      users,
    ]
  );

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      const retrieveCount = async (filter) => {
        const url = "/customer/count";

        if (Object.keys(filter).length > 0) {
          return Axios.get(url, {
            params: {
              criteria: { where: filter },
            },
          });
        }

        return Axios.get(url);
      };

      try {
        setLoading(true);

        const skip = pageSize * pageIndex;

        let url = `/customer?skip=${skip}&limit=${pageSize}`;

        if (!sortBy || sortBy.length === 0) {
          url += "&sort=createdAt DESC";
        } else {
          url += sortBy
            .map((column) => {
              const desc = column.desc ? "DESC" : "ASC";
              return `&sort=${column.id} ${desc}`;
            })
            .reduce((prev, cur) => prev + cur, "");
        }

        const wherefilter = {};

        if (searchValue !== "") {
          wherefilter[searchBy.key] = {
            contains: searchValue,
          };
        }

        if (startDate || endDate) {
          const startingPoint = startDate?.valueOf();
          const endingPoint = endDate?.valueOf();

          wherefilter[dateSearchBy.key] = {
            ">=": startingPoint,
            "<=": endingPoint,
          };
        }

        if (Object.keys(wherefilter).length > 0) {
          url += `&where=${JSON.stringify(wherefilter)}`;
        }

        const [count, response] = await Promise.all([
          retrieveCount(wherefilter),
          Axios.get(url),
        ]);
        setData(response);
        setPageCount(Math.ceil(count / pageSize));
        setQuotationCount(count);
      } catch (_error) {
        setIsError(true);
      } finally {
        setLoading(false);
      }
    },
    [Axios, dateSearchBy.key, endDate, searchBy.key, searchValue, startDate]
  );

  const searchByItems = useMemo(
    () => [
      {
        key: "carPlate",
        display: "Car Plate",
      },
      {
        key: "icNo",
        display: "Ic No.",
      },
      {
        key: "vehicleModel",
        display: "Vehicle Model",
      },
    ],
    []
  );

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.setTablePage(0);
    }
  }, [searchValue]);

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader className="border-0">
            <Row>
              <Col md="3">
                <FormGroup>
                  <UncontrolledDropdown>
                    <DropdownToggle caret>
                      {"Search By "}
                      {searchBy.display}
                    </DropdownToggle>
                    <DropdownMenu>
                      {searchByItems.map((searchByItem) => (
                        <DropdownItem
                          key={searchByItem.key}
                          onClick={() => {
                            setSearchBy({
                              ...searchByItem,
                            });
                          }}
                        >
                          {searchByItem.display}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <InputGroup className="mb-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-zoom-split-in" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={searchBy.display}
                      value={searchValue}
                      onChange={(e) => {
                        setSearchValue(e.target.value.toUpperCase());
                      }}
                      type="text"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>

            <DatepickerRange
              searchByItems={[
                {
                  key: "NCDexpiryDate",
                  display: "Expiry Date",
                },
              ]}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              searchBy={dateSearchBy}
              setSearchBy={setDateSearchBy}
            />
            {checkedRows.length > 0 && (
              <Row style={{ marginTop: 10 }}>
                <Col className="text-right">
                  <Button
                    color="danger"
                    onClick={() => {
                      deleteAllCheckedAlert();
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      checkAllNCDAlert();
                    }}
                  >
                    Check For Latest NCD
                  </Button>
                </Col>
              </Row>
            )}
          </CardHeader>

          <DataTable
            ref={tableRef}
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            totalCount={quotationCount}
            renderKey={renderKey}
            onSelectAllChange={toggleAllCheckbox}
          />
          {isError && (
            <div className="text-center">
              <i className="fa fa-exclamation-triangle ni-3x" />
              <h4 className="heading mt-4">Failed to retrieve data</h4>
            </div>
          )}
        </Card>
      </Container>
    </>
  );
}

export default Quotations;
