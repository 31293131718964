import React from "react";
import { Stepper } from "react-form-stepper";
import { useTranslation } from "react-i18next";

export default function QuotationStep(props) {
  const { step } = props;
  const { t } = useTranslation();

  return (
    <Stepper
      styleConfig={{
        completedBgColor: "#172b4d",
        activeBgColor: "#5e72e4",
      }}
      connectorStyleConfig={{
        completedColor: "#172b4d",
        activeColor: "#5e72e4",
      }}
      steps={[
        { label: t("quotationSteps.0") },
        { label: t("quotationSteps.1") },
        { label: t("quotationSteps.2") },
      ]}
      activeStep={step}
    />
  );
}
