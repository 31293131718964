import { createReducer } from "reduxsauce";
import { UserTypes } from "../actions/User";

// the initial state of this reducer

export const INITIAL_STATE = {
  token: localStorage.getItem("token") || null,
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
  csrf: null,
};

// the eagle has landed
export const setToken = (state = INITIAL_STATE, action) => {
  localStorage.setItem("token", action.token);
  localStorage.setItem("user", JSON.stringify(action.user));

  return { ...state, user: action.user, token: action.token };
};

export const setCSRF = (state = INITIAL_STATE, action) => ({
  ...state,
  csrf: action.csrf,
});

export const logout = async (state = INITIAL_STATE, action) => {
  localStorage.clear();

  await action.axios.post("/user/logout");

  return { ...state, token: null, user: null };
};

// map our action types to our reducer functions
export const HANDLERS = {
  [UserTypes.SET_TOKEN]: setToken,
  [UserTypes.SET_CSRF]: setCSRF,
  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
