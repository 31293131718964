/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import ReactDatetime from "react-datetime";

import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

function DatepickerRange(classProps) {
  const {
    searchByItems,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    searchBy,
    setSearchBy,
  } = classProps;

  return (
    <>
      <Row>
        <Col xs={3}>
          <FormGroup>
            <UncontrolledDropdown>
              <DropdownToggle caret>{searchBy.display}</DropdownToggle>
              <DropdownMenu>
                {searchByItems.map((searchByItem) => (
                  <DropdownItem
                    key={searchByItem.key}
                    onClick={() => {
                      setSearchBy({
                        ...searchByItem,
                      });
                    }}
                  >
                    {searchByItem.display}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </FormGroup>
        </Col>
        <Col xs={3}>
          <FormGroup>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                inputProps={{
                  placeholder: "Date Picker Here",
                }}
                timeFormat={false}
                renderDay={(props, currentDate) => {
                  let classes = props.className;
                  if (
                    startDate &&
                    endDate &&
                    `${startDate._d}` === `${currentDate._d}`
                  ) {
                    classes += " start-date";
                  } else if (
                    startDate &&
                    endDate &&
                    new Date(`${startDate._d}`) <
                      new Date(`${currentDate._d}`) &&
                    new Date(`${endDate._d}`) > new Date(`${currentDate._d}`)
                  ) {
                    classes += " middle-date";
                  } else if (
                    endDate &&
                    `${endDate._d}` === `${currentDate._d}`
                  ) {
                    classes += " end-date";
                  }
                  return (
                    <td {...props} className={classes}>
                      {currentDate.date()}
                    </td>
                  );
                }}
                onChange={(e) => setStartDate(e)}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col xs={3}>
          <FormGroup>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                inputProps={{
                  placeholder: "Date Picker Here",
                }}
                timeFormat={false}
                renderDay={(props, currentDate) => {
                  let classes = props.className;
                  if (
                    startDate &&
                    endDate &&
                    `${startDate._d}` === `${currentDate._d}`
                  ) {
                    classes += " start-date";
                  } else if (
                    startDate &&
                    endDate &&
                    new Date(`${startDate._d}`) <
                      new Date(`${currentDate._d}`) &&
                    new Date(`${endDate._d}`) > new Date(`${currentDate._d}`)
                  ) {
                    classes += " middle-date";
                  } else if (
                    endDate &&
                    `${endDate._d}` === `${currentDate._d}`
                  ) {
                    classes += " end-date";
                  }
                  return (
                    <td {...props} className={classes}>
                      {currentDate.date()}
                    </td>
                  );
                }}
                onChange={(e) => setEndDate(e)}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default DatepickerRange;
