import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    setToken: ["token", "user"],
    setCSRF: ["csrf"],
    logout: ["axios"],
  },
  {}
); // options - the 2nd parameter is optional

export const UserTypes = Types;
export default Creators;
