const crypto = require("crypto");
const moment = require("moment");

const models = require("./models");
exports.models = models;

exports.calculateCarYear = (vehicleYear) => {
  return parseInt(moment().format("YYYY"), 10) - vehicleYear;
};

exports.insuranceCompanies = require("./insuranceCompanies");

exports.validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

exports.axaCoverageTypes = [
  "Comprehensive",
  "Comprehensive Safe",
  "Third Party Fire and Theft",
  "Third Party",
];

exports.kurniaCoverageTypes = [
  "Comprehensive Premier",
  "Comprehensive Plus",
  "Third Party Fire and Theft",
  "Third Party",
];

exports.coverageTypes = [
  "Comprehensive",
  "Third Party Fire and Theft",
  "Third Party",
];

exports.formatMoney = function formatMoney(x) {
  if (typeof x === "undefined") {
    return "";
  }
  let num = x;
  if (typeof x === "string") {
    num = parseFloat(x);
  }

  return "RM " + num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

exports.formatMoneyRaw = function formatMoney(x) {
  if (typeof x === "undefined") {
    return "";
  }

  let num = x;
  if (typeof x === "string") {
    num = parseFloat(x);
  }

  return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
exports.roundToMoney = (x) => {
  return Math.round(x * 100) / 100;
};

exports.roundToDecimal = (x) => {
  return Math.round(x * 10) / 10;
};

exports.optionals = [
  {
    name: "windscreenCoverage",
    displayName: "Windscreen Coverage",
  },
  {
    name: "specialPerils",
    displayName: "Special Perils",
  },
  {
    name: "allDriverCoverage",
    displayName: "All Driver Coverage",
  },
  {
    name: "llop",
    displayName: "LLOP",
  },
  {
    name: "waiverBetterment",
    displayName: "Waiver Of Betterment",
  },
  {
    name: "unlimitedTowing",
    displayName: "Unlimited Towing",
  },
  {
    name: "driverPassengerPersonalAccident",
    displayName: "Driver and Passenger Personal Accident",
  },
  {
    name: "extendedWarranty",
    displayName: "Extended Warranty",
  },
];

exports.addons = [
  {
    name: "specialPerils",
    displayName: "Special Perils",
  },

  // {
  //   name: "waiverBetterment",
  //   displayName: "WAIVER OF BETTERMENT",
  // },
];

exports.generateXSignature = (XSignaturekey, jsonObject) => {
  function constructSignatureString(jsonObject) {
    const allKeys = Object.keys(jsonObject);

    const keyValueConcats = [];

    for (let i = 0; i < allKeys.length; i += 1) {
      const key = allKeys[i];
      keyValueConcats.push(key + jsonObject[key]);
    }

    const sortedKeyValueConcats = keyValueConcats.sort();

    return sortedKeyValueConcats.reduce((prev, cur) => {
      if (prev === "") {
        return cur;
      }
      return `${prev}|${cur}`;
    }, "");
  }

  const constructedString = constructSignatureString(jsonObject);

  const hmac = crypto.createHmac("sha256", XSignaturekey);
  hmac.update(constructedString);
  return hmac.digest("hex");
};

exports.getFilename = (fullpath) => {
  const split = fullpath.split("/");

  return split[split.length - 1];
};
