import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { FormGroup, Form, Input, Modal, Row, Col } from "reactstrap";
import Button from "reactstrap-button-loader";
import Spinner from "reactstrap/lib/Spinner";
import ApiContext from "services/ApiContext";

export default function SendQuotationModal(props) {
  const { isOpen, toggleModal, quotationId } = props;
  const { Axios } = useContext(ApiContext);

  const [checked, setChecked] = useState({
    email: true,
    whatsapp: true,
  });
  const [form, setForm] = useState({});

  const { isError, isLoading, isSuccess } = useQuery(
    `quotation/${quotationId}`,
    async () => Axios.get(`/quotation/${quotationId}`),
    {
      onSuccess: (incoming) => {
        setForm({
          emailAddress: incoming.emailAddress,
          phoneNo: incoming.phoneNo,
        });
      },
    }
  );

  const [mutate, { isLoading: isMutateLoading }] = useMutation(
    async () => {
      await Axios.patch(`/quotation/${quotationId}`, {
        status: "quotationSent",
      });

      if (checked.email) {
        await Axios.post(`/quotation/${quotationId}/send`, {
          emailAddress: form.emailAddress,
        });
      }

      if (checked.whatsapp) {
        window.open(
          `https://api.whatsapp.com/send?phone=+60${form.phoneNo}&text=${window.location.href}`,
          "_blank"
        );
      }
    },

    {
      onSuccess: () => {
        toggleModal();
      },
      onError: () => {
        alert("Failed to send quotation via email, please try again later");
      },
    }
  );

  useEffect(() => {
    setChecked({ email: true, whatsapp: true });
  }, [isOpen]);

  const onChecked = useCallback(
    (e) => {
      const newChecked = {
        ...checked,
      };
      newChecked[e.target.name] = e.target.checked;
      setChecked(newChecked);
    },
    [checked]
  );

  const onChange = useCallback(
    (e) => {
      const newForm = { ...form };
      newForm[e.target.name] = e.target.value;
      setForm(newForm);
    },
    [form]
  );

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={isOpen}
      toggle={() => toggleModal()}
    >
      <div className="modal-header">
        <h5 className="modal-title">Send the quotation</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggleModal()}
        >
          <span aria-hidden>×</span>
        </button>
      </div>
      {isError && (
        <div className="modal-body">Fail to retrieve quotation info</div>
      )}
      {isLoading && (
        <div className="modal-body">
          <Spinner />
        </div>
      )}

      {isSuccess && (
        <>
          <div className="modal-body">
            <Form>
              <Row>
                <Col md="12">
                  <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      defaultChecked
                      id="email"
                      name="email"
                      type="checkbox"
                      onChange={(e) => {
                        onChecked(e);
                      }}
                    />
                    <label className="custom-control-label" htmlFor="email">
                      Email
                    </label>
                  </div>
                </Col>
                {checked.email && (
                  <Col md="6">
                    <FormGroup>
                      <Input
                        className="form-control-alternative"
                        id="exampleFormControlInput1"
                        placeholder="name@example.com"
                        type="email"
                        name="emailAddress"
                        value={form.emailAddress}
                        onChange={onChange}
                      />
                    </FormGroup>
                  </Col>
                )}

                <Col md="12">
                  <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      defaultChecked
                      id="whatsapp"
                      name="whatsapp"
                      type="checkbox"
                      onChange={(e) => {
                        onChecked(e);
                      }}
                    />
                    <label className="custom-control-label" htmlFor="whatsapp">
                      Whatsapp
                    </label>
                  </div>
                </Col>
                {checked.whatsapp && (
                  <Col md="6">
                    <FormGroup>
                      <Input
                        className="form-control-alternative"
                        placeholder="0174xxxxxx"
                        type="string"
                        name="phoneNo"
                        value={form.phoneNo}
                        onChange={onChange}
                      />
                    </FormGroup>
                  </Col>
                )}
              </Row>
            </Form>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleModal()}
            >
              Close
            </Button>
            <Button
              color="success"
              type="button"
              loading={isMutateLoading}
              onClick={() => {
                mutate();
              }}
            >
              Send
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
}
