import React, { useContext, useMemo, useState } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Col,
  CardHeader,
  CardFooter,
  Badge,
  Row,
  PopoverBody,
} from "reactstrap";

import Button from "reactstrap-button-loader";
import ApiContext from "services/ApiContext";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ReadMore } from "@bisvarup/react-read-more";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { formatMoney, formatMoneyRaw, optionals, roundToMoney } from "lib";
import { useMutation, useQuery, useQueryCache } from "react-query";
import Popover from "reactstrap/lib/Popover";
import "./QuotationCard.css";
import { useTranslation } from "react-i18next";

export default function QuotationCard(props) {
  const { detail, onEdit, role, form, quotation, chooseQuotationDetail } =
    props;
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const { Axios } = useContext(ApiContext);
  const queryCache = useQueryCache();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [showPriceDetail, setShowPriceDetail] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  const { data: agentCode, isSuccess: agentCodeSuccess } = useQuery(
    `setting/${detail.provider}/agentCode`,
    async () => Axios.get(`setting/${detail.provider}/agentCode`)
  );

  const { data: coverage, isSuccess: isCoverageSuccess } = useQuery(
    `setting/${detail.provider}${detail.name}`,
    async () =>
      Axios.get("/setting/get-coverage", {
        params: {
          provider: detail.provider,
          coverageType: detail.name,
        },
      })
  );

  const [mutate, { isLoading }] = useMutation(
    async () => {
      await Axios.delete(`/quotationdetail/${detail.id}`);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(
          `quotationDetail/${detail.parentQuotation}`
        );
      },
      onError: () => {
        alert(t("deleteFail"));
      },
    }
  );

  const [bumpMutate, { isLoading: isBumpLoading }] = useMutation(
    async (bump) => {
      let { sort } = detail;
      if (bump) {
        sort -= 1;
      } else {
        sort += 1;
      }

      await Axios.patch(`/quotationdetail/${detail.id}`, {
        sort,
      });
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(
          `quotationDetail/${detail.parentQuotation}`
        );
      },
      onError: () => {
        alert(t("changeImportanceFail"));
      },
    }
  );

  const deleteAlert = React.useCallback(
    (userId) => {
      confirmAlert({
        title: t("confirmDelete"),
        message: t("sureDoThis"),
        buttons: [
          {
            label: t("yes"),
            onClick: () => {
              mutate(userId);
            },
          },
          {
            label: t("no"),
            onClick: () => {},
          },
        ],
      });
    },
    [mutate, t]
  );

  const grossPremium = useMemo(() => {
    let newAmount = detail.price;

    for (let i = 0; i < optionals.length; i += 1) {
      const optional = optionals[i];
      if (form.optional[optional.name] && detail.optionals[optional.name]) {
        newAmount += detail.optionals[optional.name];
      }
    }

    // if (
    //   form.optional.windscreenCoverage &&
    //   detail.optionals.windscreenCoverage
    // ) {
    //   newAmount += detail.optionals.windscreenCoverage;
    // }

    // if (form.optional.llop && detail.optionals.llop) {
    //   newAmount += detail.optionals.llop;
    // }
    // if (form.optional.specialPerils && detail.optionals.specialPerils) {
    //   newAmount += detail.optionals.specialPerils;
    // }

    // if (form.optional.waiverBetterment && detail.optionals.waiverBetterment) {
    //   newAmount += detail.optionals.waiverBetterment;
    // }

    // if (form.optional.allDriverCoverage && detail.optionals.allDriverCoverage) {
    //   newAmount += detail.optionals.allDriverCoverage;
    // }

    // if (form.optional.unlimitedTowing && detail.optionals.unlimitedTowing) {
    //   newAmount += detail.optionals.unlimitedTowing;
    // }

    // if (form.optional.driverPassengerPersonalAccident && detail.optionals.driverPassengerPersonalAccident) {
    //   newAmount += detail.optionals.driverPassengerPersonalAccident;
    // }

    // if (form.optional.extendedWarranty && detail.optionals.extendedWarranty) {
    //   newAmount += detail.optionals.extendedWarranty;
    // }

    return newAmount;
  }, [detail, form]);

  const totalInsurance = useMemo(() => {
    let total = grossPremium;
    total += roundToMoney((grossPremium * quotation.sstRate) / 100);
    total += quotation.stampDuty;

    return roundToMoney(total);
  }, [grossPremium, quotation.sstRate, quotation.stampDuty]);

  const total = useMemo(() => {
    let amount = totalInsurance;
    if (form.renewRoadTax) {
      amount += quotation.roadTax;
      amount += detail.deliveryCost;
    }
    return amount;
  }, [
    detail.deliveryCost,
    form.renewRoadTax,
    quotation.roadTax,
    totalInsurance,
  ]);

  return (
    <Col xs="12" xl="6" className="mb-2 p-0">
      <Card>
        {role !== "user" && (
          <CardHeader className="p-1">
            <Row>
              <Col>
                <Button
                  className="btn-icon btn-2"
                  size="sm"
                  color="primary"
                  type="button"
                  loading={isBumpLoading}
                  onClick={() => {
                    bumpMutate(true);
                  }}
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-bold-down" />
                  </span>
                </Button>
                {detail.sort}
                <Button
                  className="btn-icon btn-2"
                  size="sm"
                  color="primary"
                  type="button"
                  loading={isBumpLoading}
                  onClick={() => {
                    bumpMutate(false);
                  }}
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-bold-up" />
                  </span>
                </Button>
              </Col>
              <Col className="text-right">
                <Button
                  className="btn-icon btn-2"
                  size="sm"
                  color="primary"
                  type="button"
                  onClick={onEdit}
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-settings" />
                  </span>
                </Button>

                <Button
                  className="btn-icon btn-2"
                  size="sm"
                  color="danger"
                  type="button"
                  onClick={() => {
                    deleteAlert();
                  }}
                  loading={isLoading}
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-fat-remove" />
                  </span>
                </Button>
              </Col>
            </Row>
          </CardHeader>
        )}

        <CardImg
          alt="..."
          style={{
            objectFit: "contain",
            maxHeight: "10rem",
            minHeight: "10rem",
          }}
          src={require(`assets/img/insuranceCompanies/${detail.provider}.png`)}
          top
        />
        <CardBody>
          <CardTitle>
            {detail.name && (
              <h1 className="mb-3 coverageType">
                <Badge color="primary">{detail.name}</Badge>

                {isCoverageSuccess && coverage !== "" && (
                  <>
                    <Button
                      className="btn-icon btn-2 ml-3"
                      color="info"
                      id={`Popover-${detail.id}`}
                      type="button"
                      size="sm"
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-question" />
                      </span>
                    </Button>
                    <Popover
                      placement="right"
                      isOpen={popoverOpen}
                      target={`Popover-${detail.id}`}
                      toggle={toggle}
                    >
                      <PopoverBody>
                        <Card onClick={toggle}>
                          <div style={{ whiteSpace: "pre-wrap" }}>
                            {coverage[lang]}
                          </div>
                        </Card>
                      </PopoverBody>
                    </Popover>
                  </>
                )}
              </h1>
            )}

            <Row>
              <Col xs="5">
                <h3 className="big-responsive">Sum Insured</h3>
              </Col>
              <Col xs="1">
                <h3 className="big-responsive">:</h3>
              </Col>
              <Col xs="5">
                <h3 className="big-responsive">
                  {formatMoney(detail.sumInsured)}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col xs="5">
                <h3 className="big-responsive">Agreed Value</h3>
              </Col>
              <Col xs="1">
                <h3 className="big-responsive">:</h3>
              </Col>
              <Col xs="5">
                <h3 className="big-responsive">
                  {detail.agreeOrMarket === "A" ? (
                    <Badge color="success">{t("yes")}</Badge>
                  ) : (
                    <Badge color="danger" className="ml-3">
                      {t("no")}
                    </Badge>
                  )}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col xs="5">
                <h3 className="big-responsive">Risk Excess</h3>
              </Col>
              <Col xs="1">
                <h3 className="big-responsive">:</h3>
              </Col>
              <Col xs="5">
                <h3 className="big-responsive">
                  {formatMoney(detail.riskExcess)}
                </h3>
              </Col>
            </Row>
            <Row className="mb-0">
              <Col xs="5">
                <h3 className="text-success big-responsive">
                  <strong>{t("totalInsurance")}</strong>
                </h3>
              </Col>
              <Col xs="1">
                <h3 className="big-responsive">:</h3>
              </Col>
              <Col xs="5">
                <h3 className="text-success big-responsive">
                  <strong>{formatMoney(totalInsurance)}</strong>
                </h3>
              </Col>
            </Row>
            <Row>
              <Col xs="5">
                <h3 className="text-primary big-responsive">
                  <strong>{t("totalPayable")}</strong>
                </h3>
              </Col>
              <Col xs="1">
                <h3 className="big-responsive">:</h3>
              </Col>
              <Col xs="5" md="4">
                <h3 className="text-primary big-responsive">
                  <strong>{formatMoney(total)}</strong>
                </h3>
              </Col>
              <Col>
                <Button
                  className="btn-icon btn-2 float-sm-right"
                  size="sm"
                  color="secondary"
                  type="button"
                  loading={isBumpLoading}
                  onClick={() => {
                    setShowPriceDetail(!showPriceDetail);
                  }}
                >
                  <span className="btn-inner--icon">
                    <i
                      className={
                        showPriceDetail ? "ni ni-bold-down" : "ni ni-bold-up"
                      }
                    />
                    {showPriceDetail ? t("hide") : t("show")}
                  </span>
                </Button>
              </Col>
            </Row>
          </CardTitle>
          {showPriceDetail && (
            <div className="mb-3">
              <Row>
                <Col xs="5" className="p-0">
                  <h4 className="medium-responsive">{t("insurance")}</h4>
                </Col>
                <Col xs="3">
                  <h4 className="medium-responsive">: RM</h4>
                </Col>
                <Col xs="4" className="text-right">
                  <h4 className="medium-responsive">
                    {formatMoneyRaw(detail.price)}
                  </h4>
                </Col>
              </Row>

              {optionals.map((optional) => {
                if (
                  form.optional[optional.name] &&
                  detail.optionals &&
                  detail.optionals[optional.name]
                ) {
                  return (
                    <Row key={`${detail.id}-${optional.name}`}>
                      <Col xs="5" className="p-0">
                        <h4 className="medium-responsive">
                          {optional.displayName}
                        </h4>
                      </Col>
                      <Col xs="3">
                        <h4 className="medium-responsive">: RM</h4>
                      </Col>
                      <Col xs="4" className="text-right">
                        <h4 className="medium-responsive">
                          {formatMoneyRaw(
                            detail.optionals && detail.optionals[optional.name]
                              ? detail.optionals[optional.name]
                              : 0
                          )}
                        </h4>
                      </Col>
                    </Row>
                  );
                }
                return null;
              })}

              <Row>
                <Col xs="5" className="p-0">
                  <h4 className="medium-responsive">
                    <strong>{t("grossPremium")}</strong>
                  </h4>
                </Col>
                <Col xs="3">
                  <h4 className="medium-responsive">
                    <strong>: RM</strong>
                  </h4>
                </Col>
                <Col xs="4" className="text-right ">
                  <h4 className="medium-responsive">
                    <strong>{formatMoneyRaw(grossPremium)}</strong>
                  </h4>
                </Col>
              </Row>

              <Row>
                <Col xs="5" className="p-0">
                  <h4 className="medium-responsive">{`SST ${quotation.sstRate}%`}</h4>
                </Col>
                <Col xs="3">
                  <h4 className="medium-responsive">: RM</h4>
                </Col>
                <Col xs="4" className="text-right ">
                  <h4 className="medium-responsive">
                    {formatMoneyRaw(
                      roundToMoney((grossPremium * quotation.sstRate) / 100)
                    )}
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col xs="5" className="p-0">
                  <h4 className="medium-responsive">{t("stampDuty")}</h4>
                </Col>
                <Col xs="3">
                  <h4 className="medium-responsive">: RM</h4>
                </Col>
                <Col xs="4" className="text-right ">
                  <h4 className="medium-responsive">
                    {formatMoneyRaw(quotation.stampDuty)}
                  </h4>
                </Col>
              </Row>

              <Row>
                <Col xs="5" className="p-0">
                  <h4 className="medium-responsive">
                    <strong className="text-success ">
                      {t("totalInsurance")}
                    </strong>
                  </h4>
                </Col>
                <Col xs="3" className="text-success ">
                  <h4 className="medium-responsive">
                    <strong className="text-success ">: RM</strong>
                  </h4>
                </Col>
                <Col xs="4" className="text-right ">
                  <h4 className="medium-responsive">
                    <strong className="text-success ">
                      {formatMoneyRaw(totalInsurance)}
                    </strong>
                  </h4>
                </Col>
              </Row>
              {form.renewRoadTax && (
                <>
                  <Row className="mt-3">
                    <Col xs="5" className="p-0">
                      <h4 className="medium-responsive">{t("renewRoadTax")}</h4>
                    </Col>
                    <Col xs="3">
                      <h4 className="medium-responsive">: RM </h4>
                    </Col>
                    <Col xs="4" className="text-right ">
                      <h4 className="medium-responsive">
                        {formatMoneyRaw(quotation.roadTax)}
                      </h4>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs="5" className="p-0">
                      <h4 className="medium-responsive">{t("runnerCost")}</h4>
                    </Col>
                    <Col xs="3">
                      <h4 className="medium-responsive">: RM</h4>
                    </Col>
                    <Col xs="4" className="text-right ">
                      <h4 className="medium-responsive">
                        {formatMoneyRaw(detail.deliveryCost)}
                      </h4>
                    </Col>
                  </Row>
                </>
              )}

              <Row className="mb-4">
                <Col xs="5" className="p-0 text-primary">
                  <h4 className="text-primary medium-responsive">
                    <strong>{t("totalPayable")}</strong>
                    {form.renewRoadTax && (
                      <>
                        <br />
                        {`(${t("includeRoadTax")})`}
                      </>
                    )}
                  </h4>
                </Col>
                <Col xs="3">
                  <h4 className="medium-responsive">
                    <strong className="text-primary">: RM</strong>
                  </h4>
                </Col>
                <Col xs="4" className="text-right text-primary">
                  <h4 className="medium-responsive">
                    <strong className="text-primary medium-responsive">
                      {formatMoneyRaw(total)}
                    </strong>
                  </h4>
                </Col>
              </Row>
            </div>
          )}

          <ReadMore
            lineHeight={2}
            height={10}
            readMoreText={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Button color="info" size="sm" outline type="button">
                {t("showMore")}
              </Button>
            }
          >
            <div style={{ whiteSpace: "pre-wrap" }}>
              {detail[`features_${lang}`]}
            </div>
          </ReadMore>

          <Row className="justify-content-center mt-4">
            <Col className="text-center">
              <Button
                color="primary"
                onClick={() => {
                  chooseQuotationDetail(detail.id);
                }}
              >
                {role !== "user" ? "Choose for customer" : t("pay")}
              </Button>
            </Col>
          </Row>
        </CardBody>
        {agentCodeSuccess ? (
          <CardFooter>
            <Row>
              <Col>
                <h6>
                  Agent Code:
                  {agentCode}
                </h6>
              </Col>
              {/* <Col className="text-right">
                <h6>
                  <a
                    href={`/api/setting/${detail.provider}/policyWording`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Policy Wording
                  </a>
                </h6>
              </Col> */}
            </Row>
          </CardFooter>
        ) : (
          <></>
        )}
      </Card>
    </Col>
  );
}
