import { combineReducers } from "redux";

import User from "./User";
import Quotation from "./Quotation";
import Lang from "./Lang";

export default combineReducers({
  User,
  Quotation,
  Lang,
});
