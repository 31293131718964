import React, { useContext, useMemo, useState } from "react";
import { FormGroup, Form, Input, Modal, Row, Col, Label } from "reactstrap";
import ApiContext from "services/ApiContext";

import Button from "reactstrap-button-loader";
import { useMutation, useQueryCache } from "react-query";

export default function ChangeQuotationStatusModal(props) {
  const { quotationId } = props;
  const { Axios } = useContext(ApiContext);
  const queryCache = useQueryCache();

  const [quotationStatus, setStatus] = useState("pendingQuotation");
  const [modalOpen, setModalOpen] = useState(false);

  const availableStatus = useMemo(
    () => [
      {
        name: "pendingQuotation",
        display: "Pending Quotation",
      },
      {
        name: "quotationReady",
        display: "Quotation Ready",
      },
      {
        name: "quotationSent",
        display: "Quotation Sent",
      },
      {
        name: "paid",
        display: "Paid",
      },
      {
        name: "processed",
        display: "Processed",
      },
      {
        name: "dropped",
        display: "Dropped",
      },
    ],
    []
  );

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const [mutate, { isLoading }] = useMutation(
    async () => {
      await Axios.patch(`/quotation/${quotationId}`, {
        status: quotationStatus,
      });
    },

    {
      onSuccess: () => {
        queryCache.invalidateQueries(`quotation/${quotationId}`);
        toggleModal();
      },
      onError: () => {
        alert("Failed to submit this job");
      },
    }
  );

  return (
    <>
      <Button
        color="primary"
        type="button"
        size="sm"
        className="mb-3"
        onClick={() => toggleModal()}
      >
        Change Quotation Status
      </Button>
      <Modal
        className="modal-dialog-centered"
        isOpen={modalOpen}
        toggle={() => toggleModal()}
      >
        <div className="modal-header">
          <h5 className="modal-title">Change Quotation Status</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            <span aria-hidden>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label for="type">Type</Label>
                  <Input
                    type="select"
                    name="type"
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                    value={quotationStatus}
                  >
                    {availableStatus.map((ptr) => (
                      <option value={ptr.name} key={ptr.name}>
                        {ptr.display}
                      </option>
                    ))}
                  </Input>
                  {/* <Input type="select" name="type" onChange={onChange}>
                    <option value="">Please select</option>
                    {options.map((optionPtr) => (
                      <option value={optionPtr.value} key={optionPtr.value}>
                        {optionPtr.display}
                      </option>
                    ))}
                  </Input> */}
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            Close
          </Button>
          <Button
            color="warning"
            type="button"
            loading={isLoading}
            onClick={() => {
              mutate();
            }}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
}
