import { createReducer } from "reduxsauce";
import { LangTypes } from "store/actions/Lang";

export const INITIAL_STATE = {
  lang: "en",
};

export const setLang = (state = INITIAL_STATE, action) => ({
  ...state,
  lang: action.lang,
});

export const HANDLERS = {
  [LangTypes.SET_LANG]: setLang,
};
export default createReducer(INITIAL_STATE, HANDLERS);
