import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import XHRUpload from "@uppy/xhr-upload";

import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMutation, useQueryCache } from "react-query";
import { useSelector } from "react-redux";
import { FormGroup, Form, Input, Modal, Row, Col } from "reactstrap";
import Button from "reactstrap-button-loader";
import ApiContext from "services/ApiContext";

import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";

export default function UploadInsurancePolicyModal(props) {
  const { isOpen, toggleModal, quotationId, data } = props;
  const { Axios } = useContext(ApiContext);
  const [activateUpload, setActivateUpload] = useState(false);
  const token = useSelector((state) => state.User.token);

  const queryCache = useQueryCache();

  const csrf = useSelector((state) => state.User.csrf);
  const policyUppy = React.useMemo(
    () =>
      Uppy({
        restrictions: { maxNumberOfFiles: 1 },
        autoProceed: true,
      }).use(XHRUpload, {
        endpoint: `/api/quotation/${quotationId}/policy`,
        fieldName: "policy",
        headers: {
          "x-csrf-token": csrf,
        },
      }),
    [csrf, quotationId]
  );

  useEffect(() => {
    policyUppy.on("upload-success", () => {
      queryCache.invalidateQueries(`quotation/${quotationId}`);
    });
  }, [policyUppy, queryCache, quotationId]);

  const [checked, setChecked] = useState({
    email: true,
    whatsapp: true,
  });
  const [form, setForm] = useState({});

  const [mutate, { isLoading: isMutateLoading }] = useMutation(
    async () => {
      await Axios.patch(`/quotation/${quotationId}`, {
        status: "processed",
      });

      if (checked.email) {
        await Axios.post(`/quotation/${quotationId}/send`, {
          emailAddress: form.emailAddress,
        });
      }

      if (checked.whatsapp) {
        const text =
          "Your car insurance has been renewed, here is your insurance policy.";

        window.open(
          `https://api.whatsapp.com/send?phone=+60${form.phoneNo}&text=${text}`,
          "_blank"
        );
      }
    },

    {
      onSuccess: () => {
        queryCache.invalidateQueries(`quotation/${quotationId}`);

        toggleModal();
      },
      onError: () => {
        alert("Failed to send quotation via email, please try again later");
      },
    }
  );

  useEffect(() => {
    if (data) {
      setForm({ emailAddress: data.emailAddress, phoneNo: data.phoneNo });
    }
    setChecked({ email: true, whatsapp: true });
    setActivateUpload(false);
  }, [data, isOpen]);

  const onChecked = useCallback(
    (e) => {
      const newChecked = {
        ...checked,
      };
      newChecked[e.target.name] = e.target.checked;
      setChecked(newChecked);
    },
    [checked]
  );

  const onChange = useCallback(
    (e) => {
      const newForm = { ...form };
      newForm[e.target.name] = e.target.value;
      setForm(newForm);
    },
    [form]
  );

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={isOpen}
        toggle={() => toggleModal()}
      >
        <div className="modal-header">
          <h5 className="modal-title">Upload Policy</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            <span aria-hidden>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <Row>
              <Col className="mb-3" md="12">
                <h3>Policy</h3>
                {data && data.policyFd ? (
                  <Button
                    color="secondary"
                    onClick={() => {
                      window.open(
                        `/api/quotation/${quotationId}/policy?auth_token=${token}`,
                        "_blank"
                      );
                    }}
                  >
                    View
                  </Button>
                ) : (
                  <></>
                )}
                {activateUpload ? (
                  <Dashboard uppy={policyUppy} />
                ) : (
                  <Button
                    color="secondary"
                    onClick={() => {
                      setActivateUpload(true);
                    }}
                  >
                    Upload
                  </Button>
                )}
              </Col>
              <Col md="12">
                <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                  <input
                    className="custom-control-input"
                    defaultChecked
                    id="email"
                    name="email"
                    type="checkbox"
                    onChange={(e) => {
                      onChecked(e);
                    }}
                  />
                  <label className="custom-control-label" htmlFor="email">
                    Email
                  </label>
                </div>
              </Col>
              {checked.email && (
                <Col md="6">
                  <FormGroup>
                    <Input
                      className="form-control-alternative"
                      id="exampleFormControlInput1"
                      placeholder="name@example.com"
                      type="email"
                      name="emailAddress"
                      value={form.emailAddress}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              )}

              <Col md="12">
                <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                  <input
                    className="custom-control-input"
                    defaultChecked
                    id="whatsapp"
                    name="whatsapp"
                    type="checkbox"
                    onChange={(e) => {
                      onChecked(e);
                    }}
                  />
                  <label className="custom-control-label" htmlFor="whatsapp">
                    Whatsapp
                  </label>
                </div>
              </Col>
              {checked.whatsapp && (
                <Col md="6">
                  <FormGroup>
                    <Input
                      className="form-control-alternative"
                      placeholder="0174xxxxxx"
                      type="string"
                      name="phoneNo"
                      value={form.phoneNo}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            Close
          </Button>
          <Button
            color="success"
            type="button"
            loading={isMutateLoading}
            onClick={() => {
              mutate();
            }}
          >
            Complete the quotation
          </Button>
        </div>
      </Modal>
    </>
  );
}
