module.exports = [
  {
    name: "allianz",
    displayName: "Allianz",
  },
  {
    name: "kurnia",
    displayName: "Kurnia",
  },
  {
    name: "rhb",
    displayName: "RHB insurance",
  },
  {
    name: "pacific",
    displayName: "Pacific",
  },
  {
    name: "axa",
    displayName: "AXA",
  },
  {
    name: "lonpac",
    displayName: "Lonpac",
  },
  {
    name: "zurich",
    displayName: "Zurich",
  },
  {
    name: "liberty",
    displayName: "MSIG",
  },
  {
    name: "etiqa",
    displayName: "Etiqa",
  },
];
