import React, { useMemo } from "react";

import { Table, Input } from "reactstrap";

export default function WaiverBettermentTable(props) {
  const { onChange, prepend, form } = props;

  const yearOptions = useMemo(() => {
    const options = [];
    for (let i = 5; i <= 10; i += 1) {
      options.push(i);
    }
    return options;
  }, []);

  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">Year</th>
          <th scope="col">Percentage</th>
        </tr>
      </thead>
      <tbody>
        {yearOptions.map((option) => (
          <tr key={prepend + option}>
            <td>{option}</td>
            <td>
              <Input
                className="no-spin"
                placeholder="10"
                type="number"
                name={prepend + option}
                value={
                  form[prepend + option] ? form[prepend + option].value : ""
                }
                onChange={onChange}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
