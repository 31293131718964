import axios from "axios";
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT } from "store/types";
import UserAction from "store/actions/User";

import socketIOClient from "socket.io-client";
import sailsIOClient from "sails.io.js";

const ApiContext = React.createContext(null);

export const ApiProvider = ({ children }) => {
  const { token, csrf } = useSelector((state) => ({
    token: state.User.token,
    csrf: state.User.csrf,
  }));
  const [cbKeyPairs, setCBKeyPairs] = useState([]);

  const dispatch = useDispatch();

  const io = useMemo(() => {
    let newIo = null;

    if (!csrf) {
      return {
        socket: {
          on: (key, cb) => {
            const newArray = [...cbKeyPairs];
            newArray.push({
              key,
              cb,
            });
            setCBKeyPairs(newArray);
          },
        },
      };
    }

    if (socketIOClient.sails) {
      newIo = socketIOClient;
    } else {
      newIo = sailsIOClient(socketIOClient);
    }

    const headers = {};

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    // if (csrf) {
    //   headers["x-csrf-token"] = csrf;
    // }

    newIo.sails.headers = headers;
    newIo.sails.initialConnectionHeaders = headers;

    if (process.env.NODE_ENV !== "production") {
      newIo.sails.url = "http://localhost:1337";
    } else {
      newIo.sails.environment = "production";
    }

    newIo.sails.reconnection = true;

    cbKeyPairs.map((keypair) => newIo.socket.on(keypair.key, keypair.cb));

    return newIo;
  }, [cbKeyPairs, csrf, token]);

  const Axios = useMemo(() => {
    let axiosPtr = null;

    if (token !== "") {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      if (csrf) {
        headers["X-CSRF-Token"] = csrf;
      }

      axiosPtr = axios.create({
        baseURL: "/api/",
        headers,
      });

      axiosPtr.interceptors.response.use(
        async (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.data;
          }
          return null;
        },
        async (error) => {
          const { response, request } = error;
          if (response) {
            if (response.status === 401) {
              dispatch({ type: LOGOUT });
            }
            if (response.status === 403) {
              const resp = await axios.get("/api/csrftoken");

              // eslint-disable-next-line no-underscore-dangle
              dispatch(UserAction.setCSRF(resp.data._csrf));
            }
          } else if (request) {
            alert("Request failed. Please try again.", "error");
            return null;
          }
          return Promise.reject(error);
        }
      );
    } else {
      axiosPtr = axios.create({
        baseURL: "/api/",
      });
    }

    axiosPtr.defaults.headers.post["Content-Type"] = "application/json";

    return axiosPtr;
  }, [csrf, dispatch, token]);

  return (
    <ApiContext.Provider value={{ Axios, io }}>{children}</ApiContext.Provider>
  );
};

ApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApiContext;
