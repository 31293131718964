import React, { useState } from "react";

import { models, optionals } from "lib";
import RenderForm from "components/RenderForm";
import { Card, CardBody, CardHeader, Col, Collapse, Row } from "reactstrap";

export default function ChosenQuotation(props) {
  const { quotationDetail, editMode, onChange } = props;

  const [isCollapse, setIsCollapse] = useState(false);

  const toggleCollapse = () => {
    setIsCollapse(!isCollapse);
  };
  return (
    <Card className="mb-3">
      <CardHeader
        onClick={() => {
          toggleCollapse();
        }}
      >
        <Row className="align-items-center">
          <Col xs="8">Chosen Quotation</Col>
          <Col className="text-right" xs="4">
            {isCollapse ? (
              <i className=" ni ni-bold-down" />
            ) : (
              <i className=" ni ni-bold-up" />
            )}
          </Col>
        </Row>
      </CardHeader>

      <Collapse isOpen={isCollapse}>
        <CardBody>
          <Row>
            {Object.keys(models.QuotationDetail).map((objKey) => (
              <RenderForm
                attributeObj={models.QuotationDetail[objKey]}
                value={quotationDetail[objKey]}
                key={objKey}
                name={objKey}
                editMode={editMode}
                id={quotationDetail.id}
                onChange={onChange}
              />
            ))}
          </Row>
          <h3>Optionals</h3>
          <Row>
            {quotationDetail.optionals &&
              optionals.map((optional) => (
                <RenderForm
                  key={optional.name}
                  onChange={onChange}
                  value={quotationDetail.optionals[optional.name]}
                  name={optional.name}
                  editMode={false}
                  isMoney
                />
              ))}
          </Row>
        </CardBody>
      </Collapse>
    </Card>
  );
}
