import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalFooter,
  Row,
} from "reactstrap";
import Button from "reactstrap-button-loader";
import {
  insuranceCompanies,
  formatMoneyRaw,
  roundToMoney,
  optionals,
} from "lib";
import ApiContext from "services/ApiContext";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import "./CheckoutModal.css";

export default function CheckoutModal(props) {
  const {
    toggleModal,
    form,
    onChange,
    data,
    isOpen,
    optionsAvailable,
    quotationId,
  } = props;
  const { t } = useTranslation();

  const role = useSelector((state) => {
    let userRole = "user";

    if (state.User.user) {
      userRole = state.User.user.role;
    }

    return userRole;
  });
  const { Axios } = useContext(ApiContext);
  const [isLoading, setLoading] = useState(false);

  const infoAvailable = useMemo(
    () => [
      {
        name: "carPlate",
        displayName: "Car Plate",
      },
      {
        name: "vehicleModel",
        displayName: "Model",
      },
      {
        name: "vehicleVariant",
        displayName: "Variant",
      },
      {
        name: "vehicleYear",
        displayName: "Year",
      },
    ],
    []
  );

  const quotationDetail = useMemo(() => {
    const detailPtr = data.quotationDetails.find(
      (detail) => detail.id === form.chosenQuotation
    );

    if (!detailPtr) {
      return {};
    }
    return detailPtr;
  }, [data.quotationDetails, form.chosenQuotation]);

  const providerDisplayName = useMemo(() => {
    const provider = insuranceCompanies.find(
      (company) => company.name === quotationDetail.provider
    );

    if (provider) {
      return provider.displayName;
    }
    return "";
  }, [quotationDetail]);

  const OptionalDiv = useMemo(() => {
    const optionalCols = Object.keys(form.optional).map((optionKey) => {
      if (
        form.optional[optionKey] &&
        quotationDetail &&
        quotationDetail.optionals &&
        quotationDetail.optionals[optionKey]
      ) {
        return (
          <Row key={`checkout-${optionKey}`}>
            <Col xs="6" className="px-0 my-auto text-responsive-small ">
              {t(
                optionsAvailable.find((option) => option.name === optionKey)
                  .name
              )}
            </Col>
            <Col xs="2" className="px-0">
              <p className="text-responsive-small my-auto">RM</p>
            </Col>
            <Col className="text-right text-responsive-small my-auto">
              {quotationDetail.optionals
                ? formatMoneyRaw(quotationDetail.optionals[optionKey])
                : "0.00"}
            </Col>
          </Row>
        );
      }
      return null;
    });

    return optionalCols;
  }, [form, optionsAvailable, quotationDetail, t]);

  const grossPremium = useMemo(() => {
    if (Object.keys(quotationDetail).length === 0) {
      return 0;
    }
    let newAmount = quotationDetail.price;

    for (let i = 0; i < optionals.length; i += 1) {
      const optional = optionals[i];

      if (
        form.optional[optional.name] &&
        quotationDetail.optionals[optional.name]
      ) {
        newAmount += quotationDetail.optionals[optional.name];
      }
    }

    return newAmount;
  }, [form, quotationDetail]);

  const totalInsurance = useMemo(() => {
    let total = grossPremium;
    total += (grossPremium * data.sstRate) / 100;
    total += data.stampDuty;

    if (form.discount) {
      total -= form.discount;
    }

    return roundToMoney(total);
  }, [data.sstRate, data.stampDuty, form.discount, grossPremium]);

  const total = useMemo(() => {
    let amount = totalInsurance;

    if (form.renewRoadTax) {
      amount += data.roadTax;
      amount += quotationDetail.deliveryCost;
    }

    return amount;
  }, [
    data.roadTax,
    form.renewRoadTax,
    quotationDetail.deliveryCost,
    totalInsurance,
  ]);

  const chooseForCustomer = useCallback(async () => {
    setLoading(true);
    try {
      const formOptionals = {};

      for (let i = 0; i < optionals.length; i += 1) {
        const optional = optionals[i];
        formOptionals[optional.name] = form.optional[optional.name];
      }

      await Axios.post("/quotation-detail/choose-for-customer", {
        ...form,
        quotationId,
        quotationDetailId: form.chosenQuotation,
        discount: form.discount,
        ...formOptionals,
      });

      window.location.href = `/admin/quotations/${quotationId}`;
    } catch (error) {
      alert(
        "Fail to choose the quotation for customer, please refresh and try again"
      );
    } finally {
      setLoading(false);
    }
  }, [Axios, form, quotationId]);

  const goToPaymentPage = useCallback(async () => {
    setLoading(true);
    try {
      const paymentPageInfo = await Axios.post("/payment", {
        quotationId,
        quotationDetailId: form.chosenQuotation,
        windscreenCoverage: form.optional.windscreenCoverage,
        llop: form.optional.llop,
        specialPerils: form.optional.specialPerils,
        waiverBetterment: form.optional.waiverBetterment,
        allDriverCoverage: form.optional.allDriverCoverage,
        ...form,
      });

      window.location.href = paymentPageInfo.url;
    } catch (error) {
      alert(t("initPaymentFail"));
    } finally {
      setLoading(false);
    }
  }, [Axios, form, quotationId, t]);

  if (!form.chosenQuotation) {
    return <></>;
  }

  return (
    <Modal size="lg" isOpen={isOpen}>
      <div className="modal-header">
        <h1 className="modal-title">{t("yourOrder")}</h1>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggleModal()}
        >
          <span aria-hidden>×</span>
        </button>
      </div>

      <div className="modal-body">
        <Container>
          <Row>
            <Col xs="12" md="6">
              <Form>
                <h3>{t("yourDetail")}</h3>
                <h6 className="heading-small text-muted mb-4">
                  {t("personalInfo")}
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="name">
                          {t("name")}
                        </label>
                        <Input
                          className="form-control-alternative"
                          name="name"
                          placeholder={t("name")}
                          type="text"
                          onChange={onChange}
                          value={form.name}
                        />
                      </FormGroup>
                    </Col>

                    <Col xs="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-email"
                        >
                          {t("email")}
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-email"
                          name="emailAddress"
                          placeholder="jesse@example.com"
                          type="email"
                          onChange={onChange}
                          value={form.emailAddress}
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col md="12">
                      <FormGroup>
                        <h4>{t("marriageStatus")}</h4>
                        <Input
                          type="select"
                          name="marriageStatus"
                          value={form.marriageStatus}
                          onChange={onChange}
                        >
                          <option value={null}>{t("pleaseSelect")}</option>
                          <option value="single">
                            {t("marriageStatusOption.single")}
                          </option>
                          <option value="married">
                            {t("marriageStatusOption.married")}
                          </option>
                          <option value="divorce">
                            {t("marriageStatusOption.divorce")}
                          </option>
                        </Input>
                      </FormGroup>
                    </Col> */}
                  </Row>
                </div>
                <hr className="my-4" />

                <h6 className="heading-small text-muted mb-4">
                  {t("contactInfo")}
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-address"
                        >
                          {t("address")}
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-address"
                          placeholder={t("address")}
                          type="text"
                          name="address"
                          value={form.address}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="8">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-country"
                        >
                          {t("postCode")}
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-postal-code"
                          placeholder={t("postCode")}
                          type="number"
                          name="postcode"
                          onChange={onChange}
                          value={form.postcode}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="phoneNo">
                          {t("phoneNumber")}
                        </label>
                        <Input
                          className="form-control-alternative no-spin"
                          id="phoneNo"
                          name="phoneNo"
                          type="number"
                          onChange={onChange}
                          value={form.phoneNo}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
            <Col xs="12" md="6">
              <Card className="bg-secondary">
                <CardHeader className="bg-white border-0">
                  <h3 className="mb-0">{t("yourQuotation")}</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    {infoAvailable.map((info) => {
                      if (data[info.name]) {
                        return (
                          <Col md="12" key={info.name}>
                            <h4>{t(info.name)}</h4>
                            <p>{data[info.name]}</p>
                          </Col>
                        );
                      }
                      return null;
                    })}
                    <Col md="12">
                      <h4>{t("insurancePlan")}</h4>
                      <p>{`${providerDisplayName} ${quotationDetail.name}`}</p>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <Row>
                    <Col
                      xs="6"
                      className="px-0 my-auto text-responsive-small  "
                    >
                      {t("insurance")}
                    </Col>
                    <Col xs="2" className="px-0">
                      <p className="text-responsive-small my-auto">RM</p>
                    </Col>
                    <Col className="text-right text-responsive-small my-auto">
                      {quotationDetail.price
                        ? quotationDetail.price.toFixed(2)
                        : 0}
                    </Col>
                  </Row>
                  {OptionalDiv}
                  <Row>
                    <Col
                      xs="6"
                      className="px-0 my-auto text-responsive-small  "
                    >
                      <strong>{t("grossPremium")}</strong>
                    </Col>
                    <Col xs="2" className="px-0">
                      <p className="text-responsive-small my-auto">
                        <strong>RM</strong>
                      </p>
                    </Col>
                    <Col className="text-right text-responsive-small my-auto">
                      <strong>{formatMoneyRaw(grossPremium)}</strong>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      xs="6"
                      className="px-0 my-auto text-responsive-small  "
                    >
                      {`SST ${data.sstRate}%`}
                    </Col>
                    <Col xs="2" className="px-0">
                      <p className="text-responsive-small my-auto">RM</p>
                    </Col>
                    <Col className="text-right text-responsive-small my-auto ">
                      {formatMoneyRaw(
                        roundToMoney((grossPremium * data.sstRate) / 100)
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      xs="6"
                      className="px-0 my-auto text-responsive-small  "
                    >
                      {t("stampDuty")}
                    </Col>
                    <Col xs="2" className="px-0">
                      <p className="text-responsive-small my-auto">RM</p>
                    </Col>
                    <Col className="text-right text-responsive-small my-auto ">
                      {formatMoneyRaw(data.stampDuty)}
                    </Col>
                  </Row>

                  {form.discount && (
                    <Row>
                      <Col
                        xs="6"
                        className="px-0 my-auto text-responsive-small text-danger"
                      >
                        <strong>{t("discount")}</strong>
                      </Col>
                      <Col xs="2" className="px-0 text-danger">
                        <p className="text-responsive-small my-auto">
                          <strong>RM</strong>
                        </p>
                      </Col>
                      <Col className="text-right text-responsive-small my-auto text-danger">
                        <strong>{`-${formatMoneyRaw(form.discount)}`}</strong>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col
                      xs="6"
                      className="px-0 my-auto text-responsive-small text-success"
                    >
                      <strong>{t("totalInsurance")}</strong>
                    </Col>
                    <Col xs="2" className="px-0 text-success">
                      <p className="text-responsive-small my-auto">
                        <strong>RM</strong>
                      </p>
                    </Col>
                    <Col className="text-right text-responsive-small my-auto text-success">
                      <strong>{formatMoneyRaw(totalInsurance)}</strong>
                    </Col>
                  </Row>

                  {form.renewRoadTax && (
                    <>
                      <Row>
                        <Col
                          xs="6"
                          className="px-0 my-auto text-responsive-small  "
                        >
                          {t("renewRoadTax")}
                        </Col>
                        <Col xs="2" className="px-0">
                          <p className="text-responsive-small my-auto">RM</p>
                        </Col>
                        <Col className="text-right text-responsive-small my-auto ">
                          {formatMoneyRaw(data.roadTax)}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs="6"
                          className="px-0 my-auto text-responsive-small  "
                        >
                          {t("runnerCost")}
                        </Col>
                        <Col xs="2" className="px-0">
                          <p className="text-responsive-small my-auto">RM</p>
                        </Col>
                        <Col className="text-right text-responsive-small my-auto ">
                          {formatMoneyRaw(quotationDetail.deliveryCost)}
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Col
                          xs="6"
                          className="px-0 my-auto text-responsive-small  "
                        >
                          <strong>{t("totalInclRoadTax")}</strong>
                        </Col>
                        <Col xs="2" className="px-0">
                          <p className="text-responsive-small my-auto">
                            <strong>RM</strong>
                          </p>
                        </Col>
                        <Col className="text-right text-responsive-small my-auto">
                          <strong>{formatMoneyRaw(total)}</strong>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>

                {role !== "user" && (
                  <CardFooter>
                    <FormGroup>
                      <Label for="discount">{t("discount")}</Label>

                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <p className="text-responsive-small my-auto">RM</p>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="discount"
                          placeholder="XXX"
                          type="number"
                          onChange={onChange}
                        />
                      </InputGroup>
                    </FormGroup>
                  </CardFooter>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ModalFooter>
        <Button
          color="primary"
          loading={isLoading}
          disabled={role === "user"}
          onClick={() => {
            if (role === "user") {
              goToPaymentPage();
            } else {
              chooseForCustomer();
            }
          }}
        >
          {role === "user" ? t("pay") : "Choose"}
        </Button>
        <Button color="secondary" onClick={toggleModal}>
          {t("cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
