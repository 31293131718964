/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  // Button,
  Card,
  // CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Spinner,
  Col,
  CardHeader,
  UncontrolledCarousel,
} from "reactstrap";
import { connect } from "react-redux";
import Button from "reactstrap-button-loader";
import { withRouter } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import ApiContext from "services/ApiContext";
import QuotationAction from "store/actions/Quotation";
import UserAction from "store/actions/User";
import axios from "axios";
import "../../assets/css/transition.css";

import Gallery from "react-grid-gallery";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { insuranceCompanies } from "lib";
import { withTranslation } from "react-i18next";
import WhatsAppWidget from "react-whatsapp-widget";
import NotificationModal from "../../components/NotificationModal";
import "react-whatsapp-widget/dist/index.css";

import "./Home.css";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        type: "",
        name: "",
        carPlate: "",
        postcode: "",
        icNo: "",
        eHailing: false,
        // emailAddress: "",
        phoneNo: "",
      },
      notificationModal: false,
      status: "loading",
      quotationId: null,
      bannerReady: false,
      banners: [],
      reviews: [],
      galleries: [],
      galleryReady: false,
      maxWidth: 0,
    };
  }

  componentDidMount() {
    const { io } = this.context;

    io.socket.on("quotation", (data) => {
      const { quotationId } = this.state;

      if (data.id === quotationId) {
        this.processQuotation(data);
      }
    });
    this.retrieveBanner();
    this.retrieveReviews();
    this.retrieveGallery();
    this.getClientWidth();

    window.addEventListener("resize", this.getClientWidth);
  }

  componentWillUnmount() {
    const { io } = this.context;

    io.socket.off("quotation");

    window.removeEventListener("resize", this.getClientWidth);
  }

  getClientWidth = () => {
    this.setState({ maxWidth: document.documentElement.clientWidth });
  };

  retrieveGallery = () => {
    const { Axios } = this.context;

    Axios.get("/gallery").then((data) => {
      this.setState({
        galleryReady: true,
        galleries: data,
      });
    });
  };

  retrieveBanner = () => {
    const { Axios } = this.context;

    Axios.get("/banner").then((data) => {
      this.setState({
        bannerReady: true,
        banners: data.map((banner) => ({
          src: `/api/banner/${banner.id}`,
          caption: "",
        })),
      });
    });
  };

  retrieveReviews = () => {
    const { Axios } = this.context;

    Axios.get("/review").then((data) => {
      this.setState({
        reviews: data,
      });
    });
  };

  processQuotation = (quotation) => {
    const { dispatch, history } = this.props;
    if (quotation.status === "noSuchRecord" || quotation.status === "error") {
      this.setState({
        status: quotation.status,
      });
    } else {
      dispatch(QuotationAction.setQuotation(quotation));

      history.push(`/ncd/${quotation.id}`);
    }

    // if (quotation.status !== "pendingCarInfo") {
    //   this.setState({
    //     status: quotation.status,
    //   });
    // } else {
    //   dispatch(QuotationAction.setQuotation(quotation));

    //   history.push("/ncd/" + quotation.id);
    // }
  };

  onChange = (e) => {
    const { form } = this.state;
    form[e.target.name] = e.target.value;
    this.setState({ form });
  };

  submit = async () => {
    const { io } = this.context;
    const { form } = this.state;
    const { t, dispatch, csrf } = this.props;

    // if (!validateEmail(form.emailAddress)) {
    //   alert("Invalid email address");
    //   return;
    // }
    io.socket.post(
      `/api/quotation?_csrf=${encodeURIComponent(csrf)}`,
      form,
      async (resData, jwres) => {
        const newState = {
          status: "loading",
          loading: false,
        };
        let quotation = {};

        if (jwres.statusCode !== 200) {
          if (jwres.statusCode === 403) {
            const resp = await axios.get("/api/csrftoken");

            // eslint-disable-next-line no-underscore-dangle
            dispatch(UserAction.setCSRF(resp.data._csrf));
          }
          alert(t("errorOccur"));
        } else {
          // dispatch(
          //   QuotationAction.setQuotation({
          //     ...jwres.body,
          //   })
          // );

          // history.push(`/ncd/${jwres.body.id}`);

          newState.notificationModal = true;
          quotation = jwres.body;
          newState.quotationId = quotation.id;
        }

        this.setState(
          {
            ...newState,
          },
          () => {
            if (quotation.alreadyExist) {
              this.processQuotation(quotation);
            }
          }
        );
      }
    );
  };

  notificationHeader = () => {
    const { status } = this.state;

    switch (status) {
      case "tooEarlyRenew":
      case "noSuchRecord":
      case "error":
        return (
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              this.setState({
                notificationModal: false,
                form: {
                  name: "",
                  carPlate: "",
                  postcode: "",
                  icNo: "",
                  emailAddress: "",
                  phoneNo: "",
                },
              });
            }}
          >
            <span aria-hidden>×</span>
          </button>
        );
      default:
        return null;
    }
  };

  notificationBody = () => {
    const { status } = this.state;
    const { t } = this.props;

    switch (status) {
      case "tooEarlyRenew":
        return (
          <div className="py-3 text-center">
            <i className="ni ni-notification-70 ni-3x" />

            <h4 className="heading mt-4">{t("tooEarlyRenew.title")}</h4>
            <p>{t("tooEarlyRenew.instruction")}</p>
            <Button
              color="secondary"
              type="button"
              onClick={() => {
                this.setState({
                  notificationModal: false,
                  form: {
                    name: "",
                    carPlate: "",
                    postcode: "",
                    icNo: "",
                    emailAddress: "",
                    phoneNo: "",
                  },
                });
              }}
            >
              {t("ok")}
            </Button>
          </div>
        );

      case "noSuchRecord":
        return (
          <div className="py-3 text-center">
            <i className="fa fa-exclamation-triangle ni-3x" />

            <h4 className="heading mt-4">{t("noSuchRecord.title")}</h4>
            <p>{t("noSuchRecord.instruction")}</p>
            <Button
              color="secondary"
              type="button"
              onClick={() => {
                this.setState({
                  notificationModal: false,
                  form: {
                    name: "",
                    carPlate: "",
                    postcode: "",
                    icNo: "",
                    emailAddress: "",
                    phoneNo: "",
                  },
                });
              }}
            >
              {t("ok")}
            </Button>
          </div>
        );

      case "error":
        return (
          <div className="py-3 text-center">
            <i className="fa fa-exclamation-triangle ni-3x" />

            <h4 className="heading mt-4">{t("failRecord.title")}</h4>
            <p>{t("failRecord.instruction")}</p>
            <Button
              color="secondary"
              type="button"
              onClick={() => {
                this.setState({
                  notificationModal: false,
                });
              }}
            >
              {t("ok")}
            </Button>
          </div>
        );

      default:
        return (
          <div className="py-3 text-center">
            <Spinner color="secondary" />

            <h4 className="heading mt-4">
              {t("pleaseWait")}
              ...
            </h4>
            <p>{t("retrieveCarInfo")}</p>
          </div>
        );
    }
  };

  notificationType = () => {
    const { status } = this.state;

    switch (status) {
      case "tooEarlyRenew":
        return "warning";

      case "noSuchRecord":
        return "danger";

      case "error":
        return "danger";

      default:
        return "info";
    }
  };

  render() {
    const { t } = this.props;
    const {
      loading,
      form,
      notificationModal,
      banners,
      bannerReady,
      reviews,
      galleries,
      galleryReady,
      maxWidth,
    } = this.state;

    // const reviewImage = reviews.map((review) => ({
    //   src: `/api/review/${review.id}`,
    // }));

    let galleryRowHeight = 300;
    let photoWidth = maxWidth / 4;

    if (maxWidth < 650) {
      galleryRowHeight = 250;
      photoWidth = maxWidth / 2;
    }

    return (
      <>
        {bannerReady && banners.length > 0 && (
          <div
            style={{
              width: `${maxWidth}px`,
              position: "relative",
              left: "50%",
              right: "50%",
              marginLeft: `${maxWidth / -2}px`,
              marginRight: `${maxWidth / -2}px`,
            }}
          >
            <UncontrolledCarousel items={banners} />
          </div>
        )}
        <Row className="justify-content-center py-4">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardHeader>
                <h1 className="text-center text-muted mb-4">
                  {/* Get Your Car Insurance Quote Instantly! */}
                  {t("homeTitle")}
                </h1>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                  <FormGroup className="mb-3 justify-content-center text-center">
                    <h4 className="mb-3">{t("selectVehicleType")}</h4>
                    <Button
                      outline={form.type === "car"}
                      color={form.type === "car" ? "success" : "primary"}
                      type="button"
                      className="mb-3"
                      onClick={() => {
                        const newForm = { ...form };
                        newForm.type = "car";
                        this.setState({ form: newForm });
                      }}
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-car" />
                      </span>
                      <span className="btn-inner--text">{t("car")}</span>
                    </Button>
                    <Button
                      outline={form.type === "motocycle"}
                      color={form.type === "motocycle" ? "success" : "primary"}
                      type="button"
                      className="mb-3"
                      onClick={() => {
                        const newForm = { ...form };
                        newForm.type = "motocycle";
                        this.setState({ form: newForm });
                      }}
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-motorcycle" />
                      </span>
                      <span className="btn-inner--text">{t("motocycle")}</span>
                    </Button>
                    {/* <Button
                      outline={form.type === "lorry"}
                      color={form.type === "lorry" ? "success" : "primary"}
                      type="button"
                      className="mb-3"
                      onClick={() => {
                        const newForm = { ...form };
                        newForm.type = "lorry";
                        this.setState({ form: newForm });
                      }}
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-truck" />
                      </span>
                      <span className="btn-inner--text">Lorry</span>
                    </Button> */}
                  </FormGroup>

                  <CSSTransition
                    in={form.type !== ""}
                    timeout={300}
                    classNames="transition"
                    unmountOnExit
                  >
                    <FormGroup className="mb-2">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-car" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={t("ncdForm.carPlate")}
                          type="text"
                          name="carPlate"
                          onChange={this.onChange}
                        />
                      </InputGroup>
                    </FormGroup>
                  </CSSTransition>
                  <CSSTransition
                    in={form.carPlate !== ""}
                    timeout={300}
                    classNames="transition"
                    unmountOnExit
                  >
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-id-card" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="no-spin"
                          placeholder={t("ncdForm.icNo")}
                          type="number"
                          name="icNo"
                          onChange={this.onChange}
                        />
                      </InputGroup>
                    </FormGroup>
                  </CSSTransition>
                  <CSSTransition
                    in={form.icNo !== ""}
                    timeout={300}
                    classNames="transition"
                    unmountOnExit
                  >
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={t("ncdForm.name")}
                          type="text"
                          name="name"
                          value={form.name}
                          onChange={this.onChange}
                        />
                      </InputGroup>
                    </FormGroup>
                  </CSSTransition>
                  <CSSTransition
                    in={form.name !== ""}
                    timeout={300}
                    classNames="transition"
                    unmountOnExit
                  >
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-mobile-alt" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={t("ncdForm.mobilePhone")}
                          className="no-spin"
                          type="number"
                          name="phoneNo"
                          onChange={this.onChange}
                        />
                      </InputGroup>
                    </FormGroup>
                  </CSSTransition>

                  <CSSTransition
                    in={form.phoneNo !== ""}
                    timeout={300}
                    classNames="transition"
                    unmountOnExit
                  >
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-square-pin" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="no-spin"
                          placeholder={t("ncdForm.postCode")}
                          type="number"
                          name="postcode"
                          onChange={this.onChange}
                        />
                      </InputGroup>
                    </FormGroup>
                  </CSSTransition>
                  <CSSTransition
                    in={form.postcode !== ""}
                    timeout={300}
                    classNames="transition"
                    unmountOnExit
                  >
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        name="eHailing"
                        id="eHailing"
                        onChange={(e) => {
                          const { form: newForm } = this.state;
                          newForm.eHailing = e.target.checked;

                          this.setState({ form: newForm });
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="eHailing"
                      >
                        {t("ncdForm.eHailing")}
                      </label>
                    </div>
                  </CSSTransition>
                  {/* <CSSTransition
                    in={form.icNo !== ""}
                    timeout={300}
                    classNames="transition"
                    unmountOnExit
                  >
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email: xxxx@xxxx.com"
                          type="email"
                          name="emailAddress"
                          autoComplete="email"
                          onChange={this.onChange}
                        />
                      </InputGroup>
                    </FormGroup>
                  </CSSTransition> */}

                  <CSSTransition
                    // in={form.phoneNo !== ""}
                    in
                    timeout={300}
                    classNames="transition"
                    unmountOnExit
                  >
                    <>
                      <Row>
                        <Col>
                          <Button
                            className="my-4"
                            color="warning"
                            type="button"
                            onClick={() => {
                              this.setState({
                                form: {
                                  name: "",
                                  carPlate: "",
                                  postcode: "",
                                  icNo: "",
                                  emailAddress: "",
                                  phoneNo: "",
                                  eHailing: false,
                                },
                              });
                            }}
                          >
                            {t("ncdForm.clear")}
                          </Button>
                        </Col>

                        <Col className="text-right">
                          <Button
                            className="my-4"
                            color="primary"
                            type="button"
                            disabled={form.phoneNo === "" || form.type === ""}
                            loading={loading}
                            onClick={() => {
                              this.setState(
                                {
                                  loading: true,
                                },
                                this.submit
                              );
                            }}
                          >
                            {t("ncdForm.next")}
                          </Button>
                        </Col>
                      </Row>
                    </>
                  </CSSTransition>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row
          className="my-5 justify-content-center text-center py-4"
          style={{ color: "white" }}
        >
          <Col xs="12">
            <Row className="justify-content-center">
              <Col xs="12" md="4" className="mb-5">
                <img
                  src={require("../../assets/img/homepage/4.png")}
                  alt="Roadtax"
                  className="img-fluid mx-auto"
                />
              </Col>
            </Row>
          </Col>
          <Col xs="12">
            <Row className="justify-content-center">
              <Col xs="12">
                <h1 className="display-2 text-secondary">{t("howToRenew")}</h1>
              </Col>
              <Col xs="12">
                <div className="d-inline-flex flex-column">
                  <h2 className=" text-justify text-secondary text-responsive mb-3">
                    {t("renewSteps.0")}
                  </h2>
                  <h2 className="  text-justify text-secondary text-responsive mb-3">
                    {t("renewSteps.1")}
                  </h2>

                  <h2 className=" text-justify text-secondary  text-responsive mb-3">
                    {t("renewSteps.2")}
                  </h2>

                  <h2 className=" text-justify text-secondary  text-responsive mb-3">
                    {t("renewSteps.3")}
                  </h2>

                  <h2 className=" text-justify text-secondary text-responsive mb-3">
                    {t("renewSteps.4")}
                  </h2>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          className="my-5 justify-content-center text-center py-4"
          style={{ color: "white" }}
        >
          <Col xs="10" md="6">
            <h1 className="display-2 text-secondary">{t("allDone")}</h1>
            <h2 className=" text-secondary mb-3">{t("settleWhatsapp")}</h2>
          </Col>
        </Row>
        <Row
          className="my-5 justify-content-center text-center py-4"
          style={{ color: "white" }}
        >
          <Col xs="12" xl="9">
            <h1 className="display-2 text-secondary">{t("whyUs")}</h1>
            <Row className="justify-content-center">
              <Col md="4">
                <img
                  src={require("../../assets/img/homepage/1.png")}
                  alt={t("humanisedService")}
                  className="img-fluid mx-auto m-3"
                  style={{ maxHeight: "200px" }}
                />

                <h2 className="display-3 text-secondary">
                  {t("humanisedService")}
                </h2>
                <h3 className="text-justify  text-secondary">
                  {t("humanisedServiceDesc")}
                </h3>
              </Col>

              <Col md="4">
                <img
                  src={require("../../assets/img/homepage/2.png")}
                  alt={t("trustedService")}
                  className="img-fluid mx-auto m-3"
                  style={{ maxHeight: "200px" }}
                />
                <h2 className="display-3 text-secondary">
                  {t("trustedService")}
                </h2>
                <h3 className="text-justify  text-secondary">
                  {t("trustedServiceDesc")}
                </h3>
              </Col>
              <Col md="4">
                <img
                  src={require("../../assets/img/homepage/3.png")}
                  alt={t("afterSaleService")}
                  className="img-fluid mx-auto m-3"
                  style={{ maxHeight: "200px" }}
                />
                <h2 className="display-3 text-secondary">
                  {t("afterSaleService")}
                </h2>
                <h3 className="text-justify  text-secondary">
                  {t("afterSaleServiceDesc")}
                </h3>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Row
          className="my-6 justify-content-center text-center py-4"
          style={{ color: "white" }}
        >
          <Col xs="12">
            <Row>
              <Col xs="12">
                <h1 style={{ color: "white", fontSize: "2rem" }}>
                  Renew Online - Active in 10 mins
                </h1>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="12" md="6">
                <Row>
                  <Col xs="12" md="6" className="mx-auto">
                    <p style={{ fontSize: "1.5rem" }}>
                      <i
                        className=" ni ni-check-bold ni-2x text-warning"
                        style={{
                          position: "relative",
                          verticalAlign: "middle",
                        }}
                      />
                      Pay Online
                    </p>
                  </Col>
                  <Col xs="12" md="6" className="mx-auto">
                    <p style={{ fontSize: "1.5rem" }}>
                      <i
                        className=" ni ni-check-bold ni-2x text-warning"
                        style={{
                          position: "relative",
                          verticalAlign: "middle",
                        }}
                      />
                      No need to wait in line
                    </p>
                  </Col>
                </Row>
              </Col>

              <Col className="my-3" xs="12">
                <p style={{ fontSize: "1.5rem" }}>
                  The car insurance policy and road tax will be activated in 10
                  mins once your payment made.
                </p>
                <p>
                  <em>(Up to 30 minutes during peak hours)</em>
                </p>
                <p style={{ fontSize: "1.5rem" }}>
                  Sit Back and Relax... We Deliver to your Doorstep!
                </p>
              </Col>
            </Row>
          </Col>
        </Row> */}
        <Row className="justify-content-center text-center py-6">
          <Col xs="12" md="6" className="mb-3">
            <h1 className="display-2 text-secondary">
              {t("ourHappyCustomers")}
            </h1>

            {galleryReady ? (
              <div
                style={{
                  width: `${maxWidth}px`,
                  position: "relative",
                  left: "50%",
                  right: "50%",
                  marginLeft: `${maxWidth / -2}px`,
                  marginRight: `${maxWidth / -2}px`,
                  minHeight: "1px",
                  overflow: "auto",
                }}
              >
                <Gallery
                  images={galleries.map((gallery) => ({
                    src: `/api/gallery/${gallery.id}`,
                    thumbnail: `/api/gallery/${gallery.id}`,
                    thumbnailWidth: photoWidth,
                    thumbnailHeight: photoWidth,
                  }))}
                  enableImageSelection={false}
                  rowHeight={galleryRowHeight}
                />
              </div>
            ) : null}
          </Col>
        </Row>
        <Row
          style={{ backgroundColor: "white" }}
          className="justify-content-center text-center py-7"
        >
          <Col xs="12">
            <h1>{t("topInsuranceCompanies")}</h1>
          </Col>
          <Col xs="10" md="9" className="my-5 mx-auto">
            <Slider
              slidesToShow={3}
              infinite
              autoplay
              responsive={[
                {
                  breakpoint: 1920,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    // dots: true,
                  },
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    // dots: true,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                  },
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
            >
              {insuranceCompanies.map((insuranceCompany) => (
                <div key={insuranceCompany.name}>
                  <img
                    src={require(`assets/img/insuranceCompanies/${insuranceCompany.name}.png`)}
                    alt={insuranceCompany.displayName}
                    className="mx-auto"
                    style={{
                      height: "100px",
                      maxWidth: "15rem",
                      objectFit: "contain",
                      padding: "10px",
                    }}
                  />
                </div>
              ))}
            </Slider>
          </Col>
          <Col xs="12" className="mx-auto">
            <h1>{t("weAccept")}</h1>
          </Col>

          <Col xs="12">
            <Row className="justify-content-center">
              <Col xs="12" md="6">
                <Row>
                  <Col xs="12" md="4" className="mx-auto my-auto p-5">
                    <img
                      src={require("assets/img/paymentMethods/fpx.png")}
                      alt="FPX"
                      className="img-fluid"
                    />
                  </Col>
                  <Col xs="12" md="4" className="mx-auto my-auto p-5">
                    <img
                      className="img-fluid"
                      src={require("assets/img/paymentMethods/visamaster.png")}
                      alt="VISA MASTER"
                    />
                  </Col>
                  <Col xs="12" md="4" className="mx-auto my-auto p-5">
                    <img
                      className="img-fluid"
                      src={require("assets/img/paymentMethods/touchngo.png")}
                      alt="Touch N Go"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Row
          style={{ color: "white" }}
          className="my-5 justify-content-center text-center py-5"
        >
          <Col xs="12" md="6">
            <Row>
              <Col xs="12" md="4" className="mx-auto mb-5">
                <h3 style={{ fontSize: "2rem", color: "white" }}>1985</h3>
                <p style={{ fontSize: "1.2rem" }}>Since</p>
              </Col>
              <Col xs="12" md="4" className="mx-auto mb-5">
                <h3 style={{ fontSize: "2rem", color: "white" }}>5000+</h3>
                <p style={{ fontSize: "1.2rem" }}>Customers</p>
              </Col>
              <Col xs="12" md="4" className="mx-auto mb-5">
                <h3 style={{ fontSize: "2rem", color: "white" }}>#1</h3>
                <p style={{ fontSize: "1.2rem" }}>
                  Penang Car Insurance Platform
                </p>
              </Col>
            </Row>
          </Col>
        </Row> */}
        <Row
          style={{ color: "white" }}
          className="my-3 justify-content-center text-center"
        >
          <Col xs="12" className="mb-6">
            <h1 style={{ color: "white" }}>{t("whyCustomerLoveUs")}</h1>
            <h3 style={{ color: "white" }}>{t("awardWinningAgency")}</h3>
          </Col>
          <Col xs="10" md="9" className="mx-auto">
            {/* <Slider slidesToShow={3} infinite>
              {/* {reviews.map((review) => (
                <div key={review.id}>
                  <img
                    src={`/api/review/${review.id}`}
                    alt={`Review of ${review.id}`}
                  />
                </div>
              ))} */}
            {/* <UncontrolledCarousel
              items={reviews.map((review) => ({
                src: `/api/review/${review.id}`,
              }))}
              indicators
              controls
            /> */}
            {/* </Slider> */}

            <Slider
              slidesToShow={3}
              infinite
              autoplay
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    // dots: true,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                  },
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
            >
              {reviews.map((review) => (
                <div key={review.name}>
                  <img
                    src={`/api/review/${review.id}`}
                    alt={`review ${review.id}`}
                    className="mx-auto"
                    style={{
                      maxWidth: "90%",
                    }}
                  />
                </div>
              ))}
            </Slider>
          </Col>
          <Col xs="12" />
        </Row>
        {/*         
        <Row className="mt-8 justify-content-center" style={{ color: "white" }}>
          <Col md="10">
            <Row>
              <Col md="4" className="">
                <img
                  className="rounded float-right"
                  src={require("assets/img/company_photo.jpg")}
                  alt="Chinhock Agency"
                  style={{
                    maxHeight: "250px",
                    verticalAlign: "middle",
                  }}
                />
              </Col>
              <Col>
                <h1 style={{ color: "white" }}>About Us</h1>
                <p
                  style={{
                    color: "#f5365c",
                  }}
                >
                  <strong>
                    We are one of the largest and professional insurance
                    agencies in Butterworth, Pulau Pinang since 1985.
                  </strong>
                </p>
                <p>
                  With over 30 years of experience, Chin Hock Agency delivers
                  excellent motor insurance services targeting individual
                  customer and SME Business Owners in Penang Area. In order to
                  increase our market value, we had expanded our service area
                  thru the online channel. Currently, we are serving more than
                  5000 unique customers every year.
                  <br />
                </p>
                <a
                  href="https://g.page/chinhockagency?share"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="ni ni-pin-3" />
                  Find Us here
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row className="mt-8" style={{ color: "white" }}>
          <Col>
            <h2 className="text-center" style={{ color: "white" }}>
              Our Team Members
            </h2>
            <h3 className="text-center" style={{ color: "#11cdef" }}>
              We&apos;re ready to help!
            </h3>
            <Row>
              <Col md="3" className="text-center">
                <img
                  className="rounded-circle mx-auto mb-3"
                  src={require("assets/img/teammembers/janicelee.png")}
                  alt="Janice Lee"
                  style={{
                    width: "100%",
                    minHeight: "15rem",
                    maxHeight: "15rem",
                    objectFit: "cover",
                  }}
                />
                <p>Janice Lee</p>
                <h5 style={{ color: "#5e72e4" }}>Founder</h5>
              </Col>
              <Col md="3" className="text-center">
                <img
                  className="rounded-circle mx-auto mb-3"
                  src={require("assets/img/teammembers/shawnooi.png")}
                  alt="Shawn Ooi"
                  style={{
                    width: "100%",
                    minHeight: "15rem",
                    maxHeight: "15rem",
                    objectFit: "cover",
                  }}
                />
                <p>Shawn Ooi</p>
                <h5 style={{ color: "#5e72e4" }}>Business Development</h5>
              </Col>
              <Col md="3" className="text-center">
                <img
                  className="rounded-circle mx-auto mb-3"
                  src={require("assets/img/teammembers/msang.jpg")}
                  alt="Ms. Ang"
                  style={{
                    width: "100%",
                    minHeight: "15rem",
                    objectFit: "cover",
                    maxHeight: "15rem",
                  }}
                />
                <p>Ms. Ang</p>
                <h5 style={{ color: "#5e72e4" }}>Manager</h5>
              </Col>
              <Col md="3" className="text-center">
                <img
                  className="rounded-circle mx-auto mb-3"
                  src={require("assets/img/teammembers/msmoey.jpg")}
                  alt="Ms. Moey"
                  style={{
                    width: "100%",
                    minHeight: "15rem",
                    maxHeight: "15rem",
                    objectFit: "cover",
                  }}
                />
                <p>Ms. Moey</p>
                <h5 style={{ color: "#5e72e4" }}>Senior Admin</h5>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row className="mt-8" style={{ color: "white" }}>
          <Col>
            <h2 className="text-center" style={{ color: "white" }}>
              Our Awards
            </h2>
            <h3 className="text-center" style={{ color: "#11cdef" }}>
              We&apos;re one of the top insurance agencies in Butterworth,
              Penang
            </h3>
            <Row className="justify-content-center">
              <Col md="3" className="text-center">
                <img
                  className="rounded mx-auto"
                  src={require("assets/img/awards/award1.jpg")}
                  alt="Award"
                  style={{
                    width: "100%",
                    minHeight: "10rem",
                  }}
                />
              </Col>
              <Col md="3" className="text-center">
                <img
                  className="rounded mx-auto"
                  src={require("assets/img/awards/award2.png")}
                  alt="Award"
                  style={{
                    width: "100%",
                    minHeight: "10rem",
                  }}
                />
              </Col>
              <Col md="3" className="text-center">
                <img
                  className="rounded mx-auto"
                  src={require("assets/img/awards/award3.png")}
                  alt="Award"
                  style={{
                    width: "100%",
                    minHeight: "10rem",
                  }}
                />
              </Col>
            </Row>
            <Row className="justify-content-center mt-5">
              <Col md="3">
                <p>
                  <i className="ni ni-trophy" />
                  Amgeneral Insurance (Kurnia)
                </p>
                <h5 style={{ color: "#fb6340" }}>
                  Million Ringgit Producer YR2017 - YR2020
                </h5>
              </Col>

              <Col md="2">
                <p>
                  <i className="ni ni-trophy" />
                  Allianz Insurance
                </p>
                <h5 style={{ color: "#fb6340" }}>
                  Growth Club YR2019 - YR2020
                </h5>
              </Col>
              <Col md="2">
                <p>
                  <i className="ni ni-trophy" />
                  Pacific Insurance
                </p>
                <h5 style={{ color: "#fb6340" }}>
                  Diamond Premier Agency YR2019-YR2020
                </h5>
              </Col>
              <Col md="2">
                <p>
                  <i className="ni ni-trophy" />
                  Axa Insurance
                </p>
                <h5 style={{ color: "#fb6340" }}>
                  Silver Achiever YR2017-YR2020
                </h5>
              </Col>
            </Row>
          </Col>
        </Row> */}
        <NotificationModal
          isOpen={notificationModal}
          header={this.notificationHeader()}
          body={this.notificationBody()}
          type={this.notificationType()}
        />
        <div
          style={{
            position: "fixed",
            right: 0,
            bottom: 0,
          }}
        >
          <WhatsAppWidget phoneNumber="60129724405" />
        </div>
      </>
    );
  }
}
Home.contextType = ApiContext;

const mapStateToProps = (state) => ({
  csrf: state.User.csrf,
});

export default connect(mapStateToProps)(withRouter(withTranslation()(Home)));
