/* eslint-disable jsx-a11y/label-has-associated-control */
import Header from "components/Headers/Header";
import React, {
  useCallback,
  useContext,
  useState,
  useMemo,
  useRef,
} from "react";
import {
  CardBody,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
  Card,
  CardHeader,
  Container,
} from "reactstrap";
import { useMutation, useQuery, useQueryCache } from "react-query";
import Button from "reactstrap-button-loader";
import ApiContext from "services/ApiContext";
import moment from "moment";
import { useSelector } from "react-redux";
import { formatMoneyRaw, formatMoney } from "lib";
// import { confirmAlert } from "react-confirm-alert"; // Import
import ModifyCarModel from "../ModifyCarModel";
import UploadInsurancePolicyModal from "../UploadInsurancePolicyModal";
import AdminQuotationStep from "../../../components/AdminQuotationStep";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ChangeQuotationStatusModal from "../ChangeQuotationStatusModal";
import QuotationInfo from "./QuotationInfo";
import ChosenQuotation from "./ChosenQuotation";
import BotStatus from "./BotStatus";

function QuotationDetail(props) {
  const queryCache = useQueryCache();
  const token = useSelector((state) => state.User.token);

  const { Axios } = useContext(ApiContext);
  const [modifyCarModal, setModifyCarModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [changedQuotationData, setQuotationData] = useState({});
  const [changedChosenQuotationData, setChosenQuotationData] = useState({});
  const [customer, setCustomer] = useState({});
  const [changedCustomerData, setCustomerData] = useState({});

  const { history, match } = props;

  const quotationId = match.params.id;

  const { data, error, isLoading, isSuccess } = useQuery(
    `quotation/${quotationId}`,
    async () => Axios.get(`/quotation/${quotationId}`),
    {
      onSuccess: async (incomeData) => {
        if (incomeData.customer) {
          setCustomer(await Axios.get(`/customer/${incomeData.customer.id}`));
        }
      },
    }
  );

  const beforeunload = useRef((e) => {
    e.preventDefault();
    e.returnValue = true;
  });

  const [mutate, { isLoading: isMutateLoading }] = useMutation(
    async () => {
      if (Object.keys(changedQuotationData).length > 0) {
        await Axios.patch(`/quotation/${data.id}`, changedQuotationData);
      }
      if (Object.keys(changedChosenQuotationData).length > 0) {
        await Axios.patch(
          `/quotationDetail/${data.chosenQuotation.id}`,
          changedChosenQuotationData
        );
      }

      if (Object.keys(changedCustomerData).length > 0) {
        if (data.customer) {
          await Axios.patch(
            `/customer/${data.customer.id}`,
            changedCustomerData
          );
        } else {
          const resp = await Axios.post("/customer", {
            icNo: data.icNo,
            carPlate: data.carPlate,
            ...changedCustomerData,
          });
          await Axios.patch(
            `/quotation/${data.id}`,

            {
              customer: resp.id,
            }
          );
        }
      }

      setQuotationData({});
      setChosenQuotationData({});
      setCustomerData({});

      window.removeEventListener("beforeunload", beforeunload.current);
      setEditMode(false);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(`quotation/${quotationId}`);
      },
      onError: () => {
        alert("Failed to save data, please try again");
      },
    }
  );

  const quotationData = useMemo(() => ({ ...data, ...changedQuotationData }), [
    changedQuotationData,
    data,
  ]);
  const chosenQuotationData = useMemo(() => {
    if (!data) {
      return null;
    }
    return { ...data.chosenQuotation, ...changedChosenQuotationData };
  }, [changedChosenQuotationData, data]);

  const customerData = useMemo(() => {
    if (customer) {
      return { ...customer, ...changedCustomerData };
    }
    return {};
  }, [changedCustomerData, customer]);

  const toggleModifyCarModal = useCallback(() => {
    setModifyCarModal(!modifyCarModal);
  }, [modifyCarModal]);

  if (error) {
    alert("Failed to retrieve quotation detail, please try again later");
  }

  // const info = React.useMemo(
  //   () => [
  //     {
  //       name: "name",
  //       display: "Name",
  //     },
  //     {
  //       name: "emailAddress",
  //       display: "Email Address",
  //     },
  //     {
  //       name: "icNo",
  //       display: "Ic No.",
  //     },
  //     {
  //       name: "address",
  //       display: "Address",
  //     },
  //     {
  //       name: "postcode",
  //       display: "Postcode",
  //     },

  //     {
  //       name: "phoneNo",
  //       display: "Phone No.",
  //     },
  //     {
  //       name: "vehicleModel",
  //       display: "Vehicle Model",
  //     },
  //     {
  //       name: "vehicleVariant",
  //       display: "Vehicle Variant",
  //     },
  //     {
  //       name: "vehicleCapacity",
  //       display: "CC",
  //     },
  //     {
  //       name: "vehicleYear",
  //       display: "Vehicle Year",
  //     },

  //     {
  //       name: "carPlate",
  //       display: "Car Plate No.",
  //     },
  //     {
  //       name: "grantFd",
  //       display: "Grant",
  //       type: "fd",
  //     },

  //     {
  //       name: "marriageStatus",
  //       display: "Marriage Status",
  //     },
  //     {
  //       name: "status",
  //       display: "Status",
  //     },
  //     {
  //       name: "remark",
  //       display: "Remark (Including Car Model Info)",
  //     },
  //   ],
  //   []
  // );

  // const ncdInfo = React.useMemo(
  //   () => [
  //     {
  //       name: "type",
  //       display: "Vehicle Type",
  //     },
  //     {
  //       name: "previousProvider",
  //       display: "Previous Provider",
  //     },
  //     {
  //       name: "NCDcurrent",
  //       display: "Current Percentage",
  //     },
  //     {
  //       name: "NCDnext",
  //       display: "Next Percentage",
  //     },
  //     {
  //       name: "NCDcurrentDate",
  //       display: "Renewed Date",
  //       type: "date",
  //     },
  //     {
  //       name: "NCDnextDate",
  //       display: "Expiry Date",
  //       type: "date",
  //     },
  //     {
  //       name: "NCDcoverPeriod",
  //       display: "Coverage Period",
  //     },
  //     {
  //       name: "windscreenSumInsured",
  //       display: "Windscreen Sum Insured",
  //     },
  //   ],
  //   []
  // );

  // const quotationDetail = useMemo(
  //   () => [
  //     {
  //       name: "provider",
  //       display: "Provider",
  //     },
  //     {
  //       name: "name",
  //       display: "Name",
  //     },
  //     {
  //       name: "agreeOrMarket",
  //       display: "Agree or Market",
  //     },
  //     {
  //       name: "sumInsured",
  //       display: "Sum Insured",
  //     },
  //     {
  //       name: "price",
  //       display: "Price",
  //     },
  //
  //     {
  //       name: "features",
  //       display: "Features",
  //       type: "text",
  //     },
  //     {
  //       name: "riskExcess",
  //       display: "Risk Excess",
  //     },
  //   ],
  //   []
  // );

  const optionDetail = useMemo(() => {
    if (!quotationData) {
      return [];
    }
    return [
      {
        name: "windscreenCoverage",
        display: `Windscreen Coverage (Sum Insured: ${formatMoney(
          quotationData.windscreenSumInsured
        )})`,
        type: "boolean",
      },
      {
        name: "llop",
        display: "LLOP",
        type: "boolean",
      },
      {
        name: "specialPerils",
        display: "Special Perils",
        type: "boolean",
      },
      {
        name: "waiverBetterment",
        display: "Waiver of Betterment",
        type: "boolean",
      },
      {
        name: "allDriverCoverage",
        display: "All Driver Coverage",
        type: "boolean",
      },
    ];
  }, [quotationData]);

  const paymentDetail = useMemo(() => {
    if (!quotationData) {
      return [];
    }

    return [
      {
        name: "amount",
        display: quotationData.chosenQuotation
          ? `Insurance ${quotationData.chosenQuotation.name}`
          : "",
      },
      {
        name: "windscreenSumInsured",
        display: "Windscreen Sum Insured",
      },
      {
        name: "windscreenCoverage",
        display: "Windscreen Coverage",
        type: "boolean",
      },
      {
        name: "llop",
        display: "LLOP",
        type: "boolean",
      },
      {
        name: "specialPerils",
        display: "Special Perils",
        type: "boolean",
      },
      {
        name: "waiverBetterment",
        display: "Waiver of Betterment",
        type: "boolean",
      },
      {
        name: "allDriverCoverage",
        display: "All Driver Coverage",
        type: "boolean",
      },
    ];
  }, [quotationData]);

  const renderForm = React.useCallback(
    (infoKey, parent, changeObj, setData) => {
      const value = parent[infoKey.name];

      const onChange = (e) => {
        const formData = {
          ...changeObj,
        };
        formData[infoKey.name] = e.target.value;

        return setData(formData);
      };

      return (
        <Col lg="6" key={infoKey.name}>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor={`input-${infoKey.name}`}
            >
              {infoKey.display}
            </label>
            {infoKey.type === "fd" && value && (
              <>
                <br />
                <Button
                  color="secondary"
                  onClick={() => {
                    window.open(
                      `/api/quotation/${quotationId}/grant?auth_token=${token}`,
                      "_blank"
                    );
                  }}
                >
                  Download
                </Button>
              </>
            )}

            {infoKey.type === "date" && (
              <Input
                className="form-control-alternative"
                name={infoKey.name}
                type="text"
                disabled={!setData || !isEditMode}
                onChange={onChange}
                value={new Date(value).toDateString()}
              />
            )}

            {infoKey.type === "text" && (
              <Input
                className="form-control-alternative"
                name={infoKey.name}
                value={value}
                disabled={!setData || !isEditMode}
                onChange={onChange}
                rows="3"
                type="textarea"
              />
            )}

            {infoKey.type === "boolean" && (
              <div className="custom-control custom-checkbox mb-3">
                <input
                  className="custom-control-input"
                  defaultChecked={value}
                  disabled={!setData || !isEditMode}
                  onChange={onChange}
                  id={infoKey.name}
                  type="checkbox"
                />

                <label className="custom-control-label" htmlFor={infoKey.name}>
                  {`RM ${quotationData.payment.detail[infoKey.name].toFixed(
                    2
                  )}`}
                </label>
              </div>
            )}

            {!infoKey.type && (
              <Input
                className="form-control-alternative"
                name={infoKey.name}
                type="text"
                disabled={!setData || !isEditMode}
                onChange={onChange}
                value={value}
              />
            )}
          </FormGroup>
        </Col>
      );
    },
    [isEditMode, quotationData, quotationId, token]
  );

  // const saveQuotation = async () => {
  //   try {
  //     await Axios.patch(`/quotation/${quotationData.id}`, changedQuotationData);
  //     window.removeEventListener("beforeunload", beforeunload.current);
  //     setEditMode(false);
  //   } catch (e) {
  //     alert("Failed to save data, please try again");
  //   }
  // };

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        {isLoading && (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        )}
        {isSuccess && (
          <Card className="shadow">
            <CardHeader>
              <Row className="align-items-center">
                <Col>
                  <h3>
                    <Button
                      size="sm"
                      className="btn-icon"
                      type="button"
                      onClick={() => {
                        history.push("/admin/quotations");
                      }}
                    >
                      <span className="btn-inner--icon">
                        <i className=" ni ni-bold-left" />
                      </span>
                    </Button>
                    Quotation Id:
                    {quotationData.id}
                  </h3>
                </Col>

                <Col className="text-right float-right">
                  <ChangeQuotationStatusModal
                    quotationId={quotationId}
                    quotation={quotationData}
                  />
                  <Button
                    color="primary"
                    size="sm"
                    className="mb-3"
                    hidden={isEditMode}
                    onClick={() => {
                      setEditMode(true);
                      window.addEventListener(
                        "beforeunload",
                        beforeunload.current
                      );
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    className="mb-3"
                    hidden={!isEditMode}
                    loading={isMutateLoading}
                    onClick={mutate}
                  >
                    Save
                  </Button>

                  <Button
                    color="success"
                    size="sm"
                    className="mb-3"
                    hidden={isEditMode}
                    onClick={() => {
                      setCompleteModal(true);
                    }}
                  >
                    Complete
                  </Button>

                  <Button
                    color="info"
                    size="sm"
                    className="mb-3"
                    hidden={isEditMode}
                    onClick={() => {
                      window.open(`/quotation/${quotationData.id}`, "_blank");
                    }}
                  >
                    View Quotation
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="align-items-center">
                  <AdminQuotationStep status={quotationData.status} />
                </Col>
              </Row>
              <BotStatus quotationId={quotationId} />
            </CardHeader>
            <CardBody>
              <div className="pl-lg-4">
                <QuotationInfo
                  quotation={quotationData}
                  customer={customerData}
                  editMode={isEditMode}
                  onChange={(e) => {
                    const newData = {
                      ...changedQuotationData,
                    };
                    newData[e.target.name] = e.target.value;
                    setQuotationData(newData);
                  }}
                  onCustomerChange={(e) => {
                    const newData = {
                      ...changedCustomerData,
                    };
                    newData[e.target.name] = e.target.value;
                    setCustomerData(newData);
                  }}
                />

                {data.chosenQuotation && (
                  <ChosenQuotation
                    quotationDetail={chosenQuotationData}
                    editMode={isEditMode}
                    onChange={(e) => {
                      const newData = {
                        ...changedChosenQuotationData,
                      };
                      newData[e.target.name] = e.target.value;
                      setChosenQuotationData(newData);
                    }}
                  />
                )}

                {quotationData.status === "paid" && quotationData.payment && (
                  <Row>
                    <Col xs="12">
                      <h6 className="heading-small text-muted mb-4">
                        Quotation Detail
                      </h6>
                      <Row>
                        {/* {quotationDetail.map((infoKey) =>
                          renderForm(infoKey, quotationData.chosenQuotation)
                        )} */}

                        {Object.keys(quotationData.payment.detail).map(
                          (key) => {
                            const infoKey = optionDetail.find(
                              (option) => option.name === key
                            );
                            if (!infoKey) {
                              return <React.Fragment key={key} />;
                            }
                            return renderForm(infoKey, quotationData);
                          }
                        )}

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-Roadtax"
                            >
                              Renew Road Tax
                            </label>

                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                className="custom-control-input"
                                defaultChecked={quotationData.renewRoadTax}
                                disabled
                                id="input-Roadtax"
                                type="checkbox"
                              />

                              <label
                                className="custom-control-label"
                                htmlFor="input-Roadtax"
                              >
                                {`RM ${formatMoneyRaw(
                                  quotationData.payment.detail.roadTax
                                )}`}
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                {(quotationData.status === "paid" ||
                  quotationData.status === "processed") &&
                quotationData.payment ? (
                  <Row>
                    <Col xs="12">
                      <h6 className="heading-small text-muted mb-4">
                        Payment Detail
                      </h6>
                      {quotationData.payment.detail.discount && (
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="discount"
                              >
                                Discount
                              </label>
                              <Input
                                className="form-control-alternative"
                                name="discount"
                                type="text"
                                disabled
                                value={quotationData.payment.detail.discount}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      {paymentDetail.map((infoKey) => {
                        if (
                          Object.keys(quotationData.payment.detail).indexOf(
                            infoKey.name
                          ) !== -1
                        ) {
                          return (
                            <Row key={infoKey.name}>
                              <Col xs="6">{infoKey.display}</Col>
                              <Col className="text-right">RM</Col>
                              <Col className="text-right">
                                {quotationData.payment.detail[
                                  infoKey.name
                                ].toFixed(2)}
                              </Col>
                            </Row>
                          );
                        }
                        return <React.Fragment key={infoKey.name} />;
                      })}

                      <Row>
                        <Col xs="6">
                          <strong>Gross Premium</strong>
                        </Col>
                        <Col className="text-right">
                          <strong>RM</strong>
                        </Col>
                        <Col className="text-right ">
                          <strong>
                            {formatMoneyRaw(
                              quotationData.payment.detail.grossPremium
                            )}
                          </strong>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs="6">{`SST ${quotationData.payment.detail.sstRate}%`}</Col>
                        <Col className="text-right">RM</Col>
                        <Col className="text-right ">
                          {formatMoneyRaw(
                            quotationData.payment.detail.sstAmount
                          )}
                        </Col>
                      </Row>

                      {quotationData.payment.detail.discount && (
                        <Row>
                          <Col xs="6" className="text-danger">
                            <strong>Discount</strong>
                          </Col>
                          <Col className="text-right text-danger">
                            <strong>RM</strong>
                          </Col>
                          <Col className="text-right text-danger">
                            <strong>
                              {`-${formatMoneyRaw(
                                quotationData.payment.detail.discount
                              )}`}
                            </strong>
                          </Col>
                        </Row>
                      )}

                      {quotationData.renewRoadTax && (
                        <>
                          <Row>
                            <Col xs="6">Renew Road Tax</Col>
                            <Col className="text-right">RM</Col>
                            <Col className="text-right ">
                              {formatMoneyRaw(
                                quotationData.payment.detail.roadTax
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6">Runner Cost</Col>
                            <Col className="text-right">RM</Col>
                            <Col className="text-right ">
                              {formatMoneyRaw(
                                parseFloat(
                                  quotationData.payment.detail.deliveryCost
                                )
                              )}
                            </Col>
                          </Row>
                        </>
                      )}

                      <Row>
                        <Col xs="6">Stamp Duty</Col>
                        <Col className="text-right">RM</Col>
                        <Col className="text-right ">
                          {formatMoneyRaw(
                            parseFloat(quotationData.payment.detail.stampDuty)
                          )}
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col xs="6">
                          <h3 className="text-success">
                            {`Total Paid (${
                              quotationData.payment.billId !== ""
                                ? "Paid by billplz"
                                : "Paid manually"
                            } at ${moment(
                              parseInt(quotationData.payment.completeDate, 10)
                            ).format("DD/MM/YYYY")})`}
                          </h3>
                        </Col>

                        <Col className="text-right ">
                          <h3 className="text-success">
                            {`RM ${quotationData.payment.amount.toFixed(2)}`}
                          </h3>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </div>
            </CardBody>
          </Card>
        )}
      </Container>
      <ModifyCarModel
        quotationId={quotationId}
        isOpen={modifyCarModal}
        toggleModal={toggleModifyCarModal}
      />
      <UploadInsurancePolicyModal
        isOpen={completeModal}
        quotationId={quotationId}
        data={quotationData}
        toggleModal={() => {
          setCompleteModal(!completeModal);
        }}
      />
    </>
  );
}

export default QuotationDetail;
