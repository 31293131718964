import Header from "components/Headers/Header";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  FormText,
  Input,
  Row,
} from "reactstrap";
import Button from "reactstrap-button-loader";

import {
  insuranceCompanies,
  addons,
  kurniaCoverageTypes,
  coverageTypes,
  axaCoverageTypes,
} from "lib";
import ApiContext from "services/ApiContext";

import Uppy from "@uppy/core";
import { DashboardModal } from "@uppy/react";
import XHRUpload from "@uppy/xhr-upload";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { useSelector } from "react-redux";

import Collapse from "reactstrap/lib/Collapse";
import WaiverBettermentTable from "./waiverBettermentTable";

export default function Settings() {
  const token = useSelector((state) => state.User.token);

  const { Axios } = useContext(ApiContext);

  const [form, setForm] = useState({});
  const [status, setStatus] = useState("loading");
  const [policyWordingModal, setPolicyWordingModal] = useState(false);
  const [policyWordingPtr, setPolicyWordingPtr] = useState(null);
  const [collapse, setCollapse] = useState({});

  const csrf = useSelector((state) => state.User.csrf);
  const grantUppy = React.useMemo(
    () =>
      Uppy({
        restrictions: { maxNumberOfFiles: 1 },
        autoProceed: true,
      }).use(XHRUpload, {
        endpoint: `/api/setting/${policyWordingPtr}/policyWording`,
        fieldName: "policyWording",
        headers: {
          "x-csrf-token": csrf,
          Authorization: `Bearer ${token}`,
        },
      }),
    [csrf, policyWordingPtr, token]
  );

  const retrieveData = useCallback(async () => {
    try {
      setStatus("loading");

      const data = await Axios.get("/setting?limit=5000");

      const newForm = data.reduce((prev, curr) => {
        const newData = prev;

        newData[curr.key] = { value: curr.value, id: curr.id };
        return newData;
      }, {});

      setForm(newForm);
      setStatus("success");
    } catch (err) {
      setStatus("error");

      alert("Failed to retrieve settings, please refresh");
    }
  }, [Axios]);

  useEffect(() => {
    retrieveData();
  }, [retrieveData]);

  const onValueChange = React.useCallback(
    (e) => {
      const newForm = { ...form };

      if (!newForm[e.target.name]) {
        newForm[e.target.name] = {};
      }

      newForm[e.target.name].value = e.target.value;

      setForm(newForm);
    },
    [form]
  );

  const generalSettings = useMemo(
    () => [
      {
        name: "sstRate",
        displayName: "SST Rate",
        helper: "Put 6 if you want 6% of total cost",
      },
      {
        name: "stampDuty",
        displayName: "Stamp Duty",
        helper: "Put 10 if you want RM10 as the stamp duty",
      },
    ],
    []
  );

  const settingOptions = useMemo(
    () => [
      {
        displayName: "Bot Username",
        name: "username",
        column: 6,
        placeholder: "Username",
      },
      {
        displayName: "Bot Password",
        name: "password",
        column: 6,
        placeholder: "Password",
      },
      ...addons.map((addon) => ({
        ...addon,
        column: 12,
        displayName: `${addon.displayName} Formula`,
      })),
      {
        displayName: "Policy Wording",
        name: "policyWording",
        column: 6,
        type: "file",
      },
      {
        displayName: "Agent Code",
        name: "agentCode",
        column: 6,
        placeholder: "agent code",
      },
    ],
    []
  );

  const submitData = React.useCallback(async () => {
    setStatus("saving");
    await Promise.all(
      Object.keys(form).map((key) => {
        if (form[key].id) {
          return Axios.patch(`/setting/${form[key].id}`, {
            value: form[key].value,
          });
        }
        return Axios.post("/setting", {
          key,
          value: form[key].value,
        });
      })
    );
    alert("Saved your setting");
    await retrieveData();
    setStatus("success");
  }, [Axios, form, retrieveData]);

  const toggleCollapse = (insuranceCompanyName) => {
    const newCollapse = {
      ...collapse,
    };
    if (collapse[insuranceCompanyName]) {
      newCollapse[insuranceCompanyName] = false;
    } else {
      newCollapse[insuranceCompanyName] = true;
    }
    setCollapse(newCollapse);
  };

  const insuranceSpecificSetting = (insuranceCompany) => {
    let insuranceCoverageTypes;

    switch (insuranceCompany.name) {
      case "kurnia":
        insuranceCoverageTypes = kurniaCoverageTypes;
        break;
      case "axa":
        insuranceCoverageTypes = axaCoverageTypes;
        break;
      default:
        insuranceCoverageTypes = coverageTypes;
    }

    return (
      <Card key={insuranceCompany.name} className="mb-3">
        <CardHeader
          onClick={() => {
            toggleCollapse(insuranceCompany.name);
          }}
        >
          <Row className="align-items-center">
            <Col xs="8">{insuranceCompany.displayName}</Col>
            <Col className="text-right" xs="4">
              {collapse[insuranceCompany.name] ? (
                <i className=" ni ni-bold-down" />
              ) : (
                <i className=" ni ni-bold-up" />
              )}
            </Col>
          </Row>
        </CardHeader>
        <Collapse isOpen={collapse[insuranceCompany.name]}>
          <CardBody>
            <Row>
              {settingOptions.map((option) => {
                if (
                  ["username", "password"].indexOf(option.name) !== -1 &&
                  insuranceCompany.name !== "kurnia"
                ) {
                  return null;
                }

                return (
                  <Col md={option.column} key={option.name}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor={insuranceCompany.name + option.name}
                      >
                        {`${option.displayName}`}
                      </label>
                      {option.type === "file" && (
                        <>
                          <br />
                          {form[`${insuranceCompany.name}_PolicyWording`] ? (
                            <Button
                              color="secondary"
                              onClick={() => {
                                window.open(
                                  `/api/setting/${insuranceCompany.name}/policyWording?auth_token=${token}`,
                                  "_blank"
                                );
                              }}
                            >
                              View
                            </Button>
                          ) : (
                            <></>
                          )}
                          <Button
                            color="secondary"
                            onClick={() => {
                              setPolicyWordingPtr(insuranceCompany.name);
                              setPolicyWordingModal(true);
                            }}
                          >
                            Upload
                          </Button>
                        </>
                      )}
                      {option.name === "waiverBetterment" && (
                        <WaiverBettermentTable
                          form={form}
                          prepend={insuranceCompany.name + option.name}
                          onChange={onValueChange}
                        />
                      )}

                      {option.type !== "file" &&
                        option.name !== "waiverBetterment" && (
                          <Input
                            className="form-control-alternative"
                            name={insuranceCompany.name + option.name}
                            type="text"
                            placeholder={
                              option.placeholder
                                ? option.placeholder
                                : "formula"
                            }
                            value={
                              form[insuranceCompany.name + option.name]
                                ? form[insuranceCompany.name + option.name]
                                    .value
                                : ""
                            }
                            onChange={onValueChange}
                          />
                        )}
                    </FormGroup>
                  </Col>
                );
              })}
              <Col lg="6">
                {["en", "cn"].map((lang) => (
                  <FormGroup key={`feature_${lang}`}>
                    <label
                      className="form-control-label"
                      htmlFor={`${insuranceCompany.name}Features_${lang}`}
                    >
                      Features
                      {` (${lang.toUpperCase()})`}
                    </label>
                    <Input
                      className="form-control-alternative"
                      // defaultValue="lucky.jesse"
                      name={`${insuranceCompany.name}Features_${lang}`}
                      // placeholder="Username"
                      rows="3"
                      type="textarea"
                      value={
                        form[`${insuranceCompany.name}Features_${lang}`]
                          ? form[`${insuranceCompany.name}Features_${lang}`]
                              .value
                          : ""
                      }
                      onChange={onValueChange}
                    />
                  </FormGroup>
                ))}
              </Col>
            </Row>
            <h6 className="heading-small text-muted mb-4">Coverage</h6>
            <Row>
              {insuranceCoverageTypes.map((coverageType) =>
                ["en", "cn"].map((lang) => (
                  <Col
                    lg="6"
                    key={`${insuranceCompany.name + coverageType}_${lang}`}
                  >
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor={`${insuranceCompany.name}${coverageType}_${lang}`}
                      >
                        {`${coverageType} (${lang.toUpperCase()})`}
                      </label>
                      <Input
                        className="form-control-alternative"
                        name={`${insuranceCompany.name}${coverageType}_${lang}`}
                        rows="3"
                        type="textarea"
                        value={
                          form[
                            `${insuranceCompany.name}${coverageType}_${lang}`
                          ]
                            ? form[
                                `${insuranceCompany.name}${coverageType}_${lang}`
                              ].value
                            : ""
                        }
                        onChange={onValueChange}
                      />
                    </FormGroup>
                  </Col>
                ))
              )}
            </Row>
          </CardBody>
        </Collapse>
      </Card>
      // <div key={insuranceCompany.name}>
      //   <h6 className="heading-small text-muted mb-4">
      //     {insuranceCompany.displayName}
      //   </h6>
      //   <div className="pl-lg-4">
      //     <Row>
      //       {settingOptions.map((option) => (
      //         <Col md={option.column} key={option.name}>
      //           <FormGroup>
      //             <label
      //               className="form-control-label"
      //               htmlFor={insuranceCompany.name + option.name}
      //             >
      //               {`${option.displayName}`}
      //             </label>
      //             {option.type === "file" && (
      //               <>
      //                 <br />
      //                 {form[
      //                   `${insuranceCompany.name}_PolicyWording`
      //                 ] ? (
      //                   <Button
      //                     color="secondary"
      //                     onClick={() => {
      //                       window.open(
      //                         `/api/setting/${insuranceCompany.name}/policyWording?auth_token=${token}`,
      //                         "_blank"
      //                       );
      //                     }}
      //                   >
      //                     View
      //                   </Button>
      //                 ) : (
      //                   <></>
      //                 )}
      //                 <Button
      //                   color="secondary"
      //                   onClick={() => {
      //                     setPolicyWordingPtr(
      //                       insuranceCompany.name
      //                     );
      //                     setPolicyWordingModal(true);
      //                   }}
      //                 >
      //                   Upload
      //                 </Button>
      //               </>
      //             )}
      //             {option.name === "waiverBetterment" && (
      //               <WaiverBettermentTable
      //                 form={form}
      //                 prepend={insuranceCompany.name + option.name}
      //                 onChange={onValueChange}
      //               />
      //             )}

      //             {option.type !== "file" &&
      //               option.name !== "waiverBetterment" && (
      //                 <Input
      //                   className="form-control-alternative"
      //                   name={insuranceCompany.name + option.name}
      //                   type="text"
      //                   placeholder={
      //                     option.placeholder
      //                       ? option.placeholder
      //                       : "formula"
      //                   }
      //                   value={
      //                     form[insuranceCompany.name + option.name]
      //                       ? form[
      //                           insuranceCompany.name + option.name
      //                         ].value
      //                       : ""
      //                   }
      //                   onChange={onValueChange}
      //                 />
      //               )}
      //           </FormGroup>
      //         </Col>
      //       ))}
      //       <Col lg="6">
      //         <FormGroup>
      //           <label
      //             className="form-control-label"
      //             htmlFor={`${insuranceCompany.name}Features`}
      //           >
      //             Features
      //           </label>
      //           <Input
      //             className="form-control-alternative"
      //             // defaultValue="lucky.jesse"
      //             name={`${insuranceCompany.name}Features`}
      //             // placeholder="Username"
      //             rows="3"
      //             type="textarea"
      //             value={
      //               form[`${insuranceCompany.name}Features`]
      //                 ? form[`${insuranceCompany.name}Features`]
      //                     .value
      //                 : ""
      //             }
      //             onChange={onValueChange}
      //           />
      //         </FormGroup>
      //       </Col>
      //     </Row>
      //   </div>
      //   <hr className="my-4" />
      // </div>
    );
  };

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          {status === "success" && (
            <>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      loading={status === "saving"}
                      size="sm"
                      onClick={() => {
                        submitData();
                      }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <h6 className="heading-small text-muted mb-4">
                  General Formulas
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="windscreenFormula"
                        >
                          Windscreen Coverage
                        </label>
                        <FormText color="muted">
                          You can use keywords like sumInsured, price. E.g:
                          (WINDSCREEN_SUM_INSURED*0.15) for 15 percent
                        </FormText>
                        <Input
                          className="form-control-alternative"
                          name="windscreenFormula"
                          placeholder="formula"
                          type="text"
                          onChange={onValueChange}
                          value={
                            form.windscreenFormula
                              ? form.windscreenFormula.value
                              : ""
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="llopFormula"
                        >
                          Legal Liability of Passenger (LLOP)
                        </label>
                        <FormText color="muted">
                          You can use keywords like SUM_INSURED, price. E.g: 7.5
                          for fixed price of 7.5
                        </FormText>
                        <Input
                          className="form-control-alternative"
                          name="llopFormula"
                          placeholder="formula"
                          type="text"
                          onChange={onValueChange}
                          value={form.llopFormula ? form.llopFormula.value : ""}
                        />
                      </FormGroup>
                    </Col>
                    {generalSettings.map((setting) => (
                      <Col md="12" key={`setting-${setting.name}`}>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={setting.name}
                          >
                            {setting.displayName}
                          </label>
                          <FormText color="muted">{setting.helper}</FormText>
                          <Input
                            className="form-control-alternative"
                            name={setting.name}
                            type="text"
                            onChange={onValueChange}
                            value={
                              form[setting.name] ? form[setting.name].value : ""
                            }
                          />
                        </FormGroup>
                      </Col>
                    ))}
                  </Row>
                </div>

                {insuranceCompanies.map((insuranceCompany) =>
                  insuranceSpecificSetting(insuranceCompany)
                )}
              </CardBody>
            </>
          )}
        </Card>
      </Container>

      <DashboardModal
        uppy={grantUppy}
        closeModalOnClickOutside
        open={policyWordingModal}
        onRequestClose={() => {
          setPolicyWordingModal(false);
        }}
      />
    </>
  );
}
