/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Input,
  Col,
  Row,
  CardFooter,
  // Spinner,
  // Badge,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import Button from "reactstrap-button-loader";

import QuotationStep from "components/QuotationStep";
import ApiContext from "services/ApiContext";
import NotificationModal from "components/NotificationModal";
import QuotationAction from "store/actions/Quotation";

import Uppy from "@uppy/core";
import { DashboardModal } from "@uppy/react";
import XHRUpload from "@uppy/xhr-upload";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { useTranslation } from "react-i18next";

function NCD(props) {
  const { history, match } = props;
  const quotationId = match.params.id;
  const { t } = useTranslation();

  const { Axios, io } = useContext(ApiContext);
  const dispatch = useDispatch();

  const [remark, setRemark] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [grantModal, setGrantModal] = useState(false);
  // const [uploadedGrant, setUploadedGrant] = useState(false);

  const quotation = useSelector(
    (state) => state.Quotation.quotations[quotationId]
  );
  const csrf = useSelector((state) => state.User.csrf);
  const grantUppy = React.useMemo(
    () =>
      Uppy({
        restrictions: { maxNumberOfFiles: 1 },
        autoProceed: true,
      }).use(XHRUpload, {
        endpoint: `/api/quotation/${quotationId}/grant`,
        fieldName: "grant",
        headers: {
          "x-csrf-token": csrf,
        },
      }),
    [csrf, quotationId]
  );

  // useEffect(() => {
  //   grantUppy.on("upload-success", () => {
  //     setUploadedGrant(true);
  //   });
  // }, [grantUppy]);

  // useEffect(() => {
  //   if (!quotation) {
  //     history.push("/");
  //   }
  // }, [history, quotation]);

  useEffect(() => {
    io.socket.on("quotation", (data) => {
      if (data.id === quotationId) {
        if (data.type === "update") {
          dispatch(QuotationAction.setQuotation(data));
        }
      }
    });
  }, [dispatch, io.socket, quotation, quotationId]);

  // const

  // useEffect(() => {
  //   if (quotation && quotation.carInfoRetrieved === "pending") {
  //     setTimeout(() => {
  //       if (quotation.carInfoRetrieved === "pending") {
  //         const newQuotation = { ...quotation };
  //         newQuotation.carInfoRetrieved = "no";

  //         dispatch(QuotationAction.setQuotation(newQuotation));
  //       }
  //     }, 45000);
  //   }
  // }, [dispatch, quotation]);

  const updateRemark = useCallback(async () => {
    setLoading(true);
    try {
      await Axios.post(`/quotation/${quotationId}/updateRemark`, {
        remark,
      });
      setSuccess(true);
    } catch (error) {
      alert(t("failedRequest"));
    } finally {
      setLoading(false);
    }
  }, [Axios, quotationId, remark, t]);

  const successBody = useMemo(() => {
    if (!quotation) {
      return null;
    }

    switch (quotation.status) {
      case "tooEarlyRenew":
        return (
          <div className="py-3 text-center">
            <i className="ni ni-notification-70 ni-3x" />

            <h4 className="heading mt-4">{t("tooEarlyRenew.title")}</h4>
            <p>{t("tooEarlyRenew.instruction")}</p>
            <Button
              color="secondary"
              type="button"
              onClick={() => history.push("/")}
            >
              {t("ok")}
            </Button>
          </div>
        );

      default:
        return (
          <div className="py-3 text-center">
            <i className="ni ni-notification-70 ni-3x" />

            <h4 className="heading mt-4">{t("successRecord.title")}</h4>
            <p>{t("successRecord.instruction")}</p>
            <Button
              color="secondary"
              type="button"
              onClick={() => {
                history.push("/");
              }}
            >
              {t("ok")}
            </Button>
          </div>
        );
    }
  }, [history, quotation, t]);

  // const isReady = useMemo(() => {
  //   if (!quotation) {
  //     return false;
  //   }

  //   if (quotation.carInfoRetrieved === "pending") {
  //     return false;
  //   }

  //   if (quotation.carInfoRetrieved === "yes") {
  //     return true;
  //   }

  //   if (uploadedGrant) {
  //     return true;
  //   }
  //   return false;
  // }, [quotation, uploadedGrant]);
  // if (!quotation) {
  //   return <></>;
  // }

  useEffect(() => {
    if (!quotation) {
      history.push("/");
    }
  }, [history, quotation]);

  if (!quotation) {
    return <></>;
  }

  return (
    <>
      <Row className=" mt-md--9">
        <Col xs="12">
          <Card>
            <CardBody>
              <QuotationStep step={0} />
              <Row>
                <Col xs="12" md="8">
                  <Row>
                    <Col>
                      <Card>
                        <CardBody>
                          <h4>{t("insuranceInfo")}</h4>
                          <Row className="mb-2">
                            <Col>{t("carPlate")}</Col>
                            <Col xs="1">:</Col>
                            <Col>{quotation.carPlate}</Col>
                          </Row>
                          {quotation.previousProvider && (
                            <Row className="mb-2">
                              <Col>{t("currentInsurance")}</Col>
                              <Col xs="1">:</Col>
                              <Col>{quotation.previousProvider}</Col>
                            </Row>
                          )}
                          {quotation.NCDcurrent && (
                            <Row className="mb-2">
                              <Col>{t("noClaimDiscount")}</Col>
                              <Col xs="1">:</Col>
                              <Col>{quotation.NCDcurrent}</Col>
                            </Row>
                          )}
                          {quotation.NCDnext && (
                            <Row className="mb-2">
                              <Col>{t("nextNoClaimDiscount")}</Col>
                              <Col xs="1">:</Col>
                              <Col>{quotation.NCDnext}</Col>
                            </Row>
                          )}
                          {/* 
                          <Row className="mb-2">
                            <Col>{t("noClaimDiscount")}</Col>
                            <Col xs="1">:</Col>
                            <Col>{quotation.NCDcurrent}</Col>
                          </Row>
                          <Row className="mb-2">
                            <Col>{t("nextNoClaimDiscount")}</Col>
                            <Col xs="1">:</Col>
                            <Col>{quotation.NCDnext}</Col>
                          </Row>
                          <Row>
                            <Col>{t("coveragePeriod")}</Col>
                            <Col xs="1">:</Col>
                            <Col>{quotation.NCDcoverPeriod}</Col>
                          </Row> */}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  {quotation.carInfoRetrieved === "yes" && (
                    <Row>
                      <Col>
                        <Card>
                          <CardBody>
                            <h4>{t("carInfo")}</h4>

                            <Row>
                              <Col>{t("vehicleModel")}</Col>
                              <Col xs="1">:</Col>
                              <Col>{quotation.vehicleModel}</Col>
                            </Row>
                            <Row>
                              <Col>{t("vehicleVariant")}</Col>
                              <Col xs="1">:</Col>
                              <Col>{quotation.vehicleVariant}</Col>
                            </Row>
                            <Row>
                              <Col>{t("cc")}</Col>
                              <Col xs="1">:</Col>
                              <Col>{quotation.vehicleCapacity}</Col>
                            </Row>
                            <Row>
                              <Col>{t("carYear")}</Col>
                              <Col xs="1">:</Col>
                              <Col>{quotation.vehicleYear}</Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )}
                  {/* <Row>
                    <Col>
                      <Card>
                        <CardBody>
                          <h4>Your Car info:</h4>
                          {quotation.carInfoRetrieved === "pending" && (
                            <div className="py-3 text-center">
                              <Spinner color="primary" />
                              <h5 className="heading mt-4">
                                Please wait while we retrieve your car info.
                              </h5>
                            </div>
                          )}

                          {quotation.carInfoRetrieved === "yes" && (
                            <>
                              <Row>
                                <Col>Vehicle Model</Col>
                                <Col xs="1">:</Col>
                                <Col>{quotation.vehicleModel}</Col>
                              </Row>
                              <Row>
                                <Col>Variant</Col>
                                <Col xs="1">:</Col>
                                <Col>{quotation.vehicleVariant}</Col>
                              </Row>
                              <Row>
                                <Col>CC</Col>
                                <Col xs="1">:</Col>
                                <Col>{quotation.vehicleCapacity}</Col>
                              </Row>
                            </>
                          )}
                          {quotation.carInfoRetrieved === "no" && (
                            <>
                              <h5 className="heading mt-4">
                                Fail to retrieve your car info, please enter
                                your car model and variant in the remark and
                                upload your vehicle grant.
                              </h5>
                              <Row>
                                <Col>Grant</Col>
                                <Col xs="1">:</Col>
                                <Col>
                                  {uploadedGrant ? (
                                    <Badge color="success">Uploaded</Badge>
                                  ) : (
                                    <Button
                                      color="secondary"
                                      onClick={() => {
                                        setGrantModal(true);
                                      }}
                                    >
                                      Upload
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row> */}
                </Col>
                <Col xs="12" md="4">
                  <Card>
                    <CardBody>
                      <h4>{t("remark")}</h4>

                      <Input
                        className="form-control-alternative"
                        placeholder={t("remarkInstruction")}
                        rows="3"
                        value={remark}
                        type="textarea"
                        onChange={(e) => {
                          setRemark(e.target.value);
                        }}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                <Button
                  color="primary"
                  size="lg"
                  type="button"
                  loading={loading}
                  // disabled={!isReady}
                  onClick={() => {
                    updateRemark();
                  }}
                >
                  {`${t("getQuotation")}!`}
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <NotificationModal isOpen={success} body={successBody} type="success" />
      <DashboardModal
        uppy={grantUppy}
        closeModalOnClickOutside
        open={grantModal}
        onRequestClose={() => {
          setGrantModal(false);
        }}
      />
    </>
  );
}

export default NCD;
