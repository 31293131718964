module.exports = {
  icNo: {
    type: "string",
    example: "920123035023",
    required: true,
  },

  phoneNo: {
    type: "string",
    example: "0174XXXXXX",
  },

  carPlate: {
    type: "string",
    example: "PMF7777",
    required: true,
  },

  postcode: {
    type: "string",
    example: "12300",
    required: true,
  },

  name: {
    type: "string",
  },

  address: {
    type: "string",
  },

  contactType: {
    type: "string",
  },

  emailAddress: {
    type: "string",
    isEmail: true,
    maxLength: 200,
    example: "mary.sue@example.com",
  },

  marriageStatus: {
    type: "string",
    isIn: ["notSet", "single", "married", "divorce"],
    defaultsTo: "notSet",
  },

  eHailing: {
    type: "boolean",
    defaultsTo: false,
  },

  type: {
    type: "string",
    isIn: ["car", "lorry", "motocycle"],
    defaultsTo: "car",
  },

  //backend input
  status: {
    type: "string",
    isIn: [
      "detailFilled",
      "error", //red color
      "noSuchRecord", //black
      "pendingCarInfo",
      "failToGetCarInfo",
      "pendingQuotation",
      "tooEarlyRenew",
      "quotationReady",
      "quotationSent",
      "expired",
      "paid",
      "processed", //green
      "dropped",
    ],
    defaultsTo: "detailFilled",
  },

  quotationDate: {
    type: "number",
  },

  remark: {
    type: "string",
  },

  //ncd from my car info
  NCDnext: {
    type: "string",
  },
  NCDnextDate: {
    type: "number",
  },
  NCDcoverPeriod: {
    type: "string",
  },
  NCDcurrent: {
    type: "string",
  },
  NCDcurrentDate: {
    type: "number",
  },
  NCDexpiryDate: {
    type: "number",
  },

  previousProvider: {
    type: "string",
  },

  previousType: {
    type: "string",
  },

  //carinfo from kurnia
  carInfoRetrieved: {
    type: "string",
    isIn: ["pending", "yes", "no"],
    defaultsTo: "pending", //false if fail to retrieve from kurnia
  },

  // vehicleYear: {
  //   type: 'number',
  // },

  // vehicleModel: {
  //   type: 'string',
  // },
  // vehicleVariant: {
  //   type: 'string',
  // },
  // vehicleCapacity: {
  //   type: 'string',
  // },

  grantFd: {
    type: "string",
  },

  policyFd: {
    type: "string",
  },

  windscreenSumInsured: {
    type: "number",
  },

  roadTax: {
    type: "number",
  },

  renewRoadTax: {
    type: "boolean",
  },

  windscreenCoverage: {
    type: "boolean",
  },
  llop: {
    type: "boolean",
  },
  specialPerils: {
    type: "boolean",
  },
  waiverBetterment: {
    type: "boolean",
  },

  allDriverCoverage: {
    type: "boolean",
  },

  source: {
    type: "string",
    defaultsTo: "web",
  },

  //  ╔═╗╔╦╗╔╗ ╔═╗╔╦╗╔═╗
  //  ║╣ ║║║╠╩╗║╣  ║║╚═╗
  //  ╚═╝╩ ╩╚═╝╚═╝═╩╝╚═╝

  //  ╔═╗╔═╗╔═╗╔═╗╔═╗╦╔═╗╔╦╗╦╔═╗╔╗╔╔═╗
  //  ╠═╣╚═╗╚═╗║ ║║  ║╠═╣ ║ ║║ ║║║║╚═╗
  //  ╩ ╩╚═╝╚═╝╚═╝╚═╝╩╩ ╩ ╩ ╩╚═╝╝╚╝╚═╝
  quotationPic: {
    model: "user",
  },

  contactPic: {
    model: "user",
  },

  customer: {
    model: "customer",
  },

  chosenQuotation: {
    model: "QuotationDetail",
  },

  payment: {
    model: "Payment",
  },
};
