import React, { useContext, useState } from "react";
import Header from "components/Headers/Header";
import {
  Card,
  CardBody,
  CardHeader,
  CardImg,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import Button from "reactstrap-button-loader";

import Uppy from "@uppy/core";
import { DashboardModal } from "@uppy/react";
import XHRUpload from "@uppy/xhr-upload";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { useSelector } from "react-redux";
import { useMutation, useQuery, useQueryCache } from "react-query";
import ApiContext from "services/ApiContext";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default function Reviews() {
  const csrf = useSelector((state) => state.User.csrf);
  const { Axios } = useContext(ApiContext);
  const queryCache = useQueryCache();

  const [uploadModal, setUploadModal] = useState(false);

  const { data, isLoading, isSuccess } = useQuery("reviews", async () =>
    Axios.get("/review")
  );

  const [mutate, { isLoading: isMutateLoading }] = useMutation(
    async (id) => {
      await Axios.delete(`/review/${id}`);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(`reviews`);
      },
      onError: () => {
        alert("Failed to remove the record, please try to refresh the page");
      },
    }
  );

  const deleteAlert = React.useCallback(
    (id) => {
      confirmAlert({
        title: "Confirm to delete",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              mutate(id);
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    },
    [mutate]
  );

  const uppy = React.useMemo(
    () =>
      Uppy({
        restrictions: { allowedFileTypes: ["image/*"] },
        autoProceed: true,
      }).use(XHRUpload, {
        endpoint: `/api/review`,
        fieldName: "review",
        headers: {
          "x-csrf-token": csrf,
        },
      }),
    [csrf]
  );

  uppy.on("complete", () => {
    queryCache.invalidateQueries(`reviews`);
  });

  return (
    <>
      <Header />
      <Container className=" mt--7" fluid>
        <Row>
          <div className=" col">
            <Card className=" shadow">
              <CardHeader className=" bg-transparent">
                <Row>
                  <Col>
                    <h3 className=" mb-0">Reviews</h3>
                  </Col>
                  <Col>
                    <Button
                      className="float-right"
                      color="primary"
                      onClick={() => {
                        setUploadModal(true);
                      }}
                    >
                      Add New Review
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {isLoading && (
                  <Col xs="12">
                    <Spinner />
                  </Col>
                )}
                <Row>
                  {isSuccess &&
                    data &&
                    data.map((review) => (
                      <Col xs="12" key={review.id} className="mb-3">
                        <Card>
                          <CardImg
                            src={`/api/review/${review.id}`}
                            alt={review.id}
                          />
                          <CardBody>
                            <Button
                              size="sm"
                              loading={isMutateLoading}
                              color="warning"
                              onClick={() => {
                                deleteAlert(review.id);
                              }}
                            >
                              Delete
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <DashboardModal
        uppy={uppy}
        closeModalOnClickOutside
        open={uploadModal}
        onRequestClose={() => {
          setUploadModal(false);
        }}
      />
    </>
  );
}
