import React from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import { useSelector } from "react-redux";

export default function RenderForm(props) {
  const {
    attributeObj,
    onChange,
    value,
    name,
    editMode,
    md,
    id,
    isMoney,
  } = props;
  const token = useSelector((state) => state.User.token);

  let formComponent = null;

  if (attributeObj) {
    switch (attributeObj.type) {
      case "string":
        formComponent = (
          <Input
            className="form-control-alternative"
            name={name}
            type="text"
            disabled={!editMode}
            onChange={onChange}
            value={value}
          />
        );
        break;
      case "number":
        formComponent = (
          <Input
            className="form-control-alternative"
            name={name}
            type="number"
            disabled={!editMode}
            onChange={onChange}
            value={value}
          />
        );
        break;

      case "boolean":
        formComponent = (
          <div className="custom-control custom-checkbox mb-3">
            <input
              className="custom-control-input"
              type="checkbox"
              name={name}
              id={name}
              defaultChecked={value}
              disabled={!editMode}
              onChange={(e) => {
                onChange({
                  target: {
                    value: e.target.checked,
                  },
                });
              }}
            />
            <label className="custom-control-label" htmlFor={name}>
              {name}
            </label>
          </div>
        );
        break;

      default:
        break;
    }

    if (attributeObj.isIn) {
      formComponent = (
        <Input
          type="select"
          name={name}
          id={name}
          disabled={!editMode}
          onChange={onChange}
          value={value}
        >
          <option>Please select</option>
          {attributeObj.isIn.map((ptr) => (
            <option key={`${name}_${ptr}`} value={ptr}>
              {ptr}
            </option>
          ))}
        </Input>
      );
    }
  }
  if (name.toLowerCase().endsWith("date")) {
    formComponent = (
      <InputGroup className="input-group-alternative">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="ni ni-calendar-grid-58" />
          </InputGroupText>
        </InputGroupAddon>
        <ReactDatetime
          value={value}
          onChange={onChange}
          inputProps={{
            placeholder: "Date Picker Here",
            disabled: !editMode,
          }}
          timeFormat={false}
        />
      </InputGroup>
    );
  }
  if (name.toLowerCase().endsWith("fd")) {
    if (!value) {
      return null;
    }
    formComponent = (
      <>
        <br />
        <Button
          color="secondary"
          onClick={() => {
            window.open(
              `/api/quotation/${id}/${name.substring(
                0,
                name.length - 2
              )}?auth_token=${token}`,
              "_blank"
            );
          }}
        >
          Download
        </Button>
      </>
    );
  }

  if (isMoney) {
    formComponent = (
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>RM</InputGroupText>
        </InputGroupAddon>
        <Input
          className="pl-3"
          name="price"
          placeholder="XXX"
          type="number"
          disabled={!editMode}
          value={value}
          onChange={onChange}
        />
      </InputGroup>
    );
  }

  if (!formComponent) {
    return null;
  }

  return (
    <Col md={md}>
      <FormGroup>
        <Label className="form-control-label" for={name}>
          {name}
        </Label>
        {formComponent}
      </FormGroup>
    </Col>
  );
}

RenderForm.defaultProps = {
  md: "6",
};
