module.exports = {
  //  ╔═╗╦═╗╦╔╦╗╦╔╦╗╦╦  ╦╔═╗╔═╗
  //  ╠═╝╠╦╝║║║║║ ║ ║╚╗╔╝║╣ ╚═╗
  //  ╩  ╩╚═╩╩ ╩╩ ╩ ╩ ╚╝ ╚═╝╚═╝
  icNo: {
    type: "string",
    example: "920123035023",
    required: true,
  },
  carPlate: {
    type: "string",
    example: "PMF7777",
    required: true,
  },

  type: {
    type: "string",
    isIn: ["car", "lorry", "motocycle"],
    defaultsTo: "car",
  },
  carInfoRetrieved: {
    type: "string",
    isIn: ["pending", "yes", "no"],
    defaultsTo: "pending", //false if fail to retrieve from kurnia
  },
  vehicleModel: {
    type: "string",
  },
  vehicleVariant: {
    type: "string",
  },
  vehicleCapacity: {
    type: "string",
  },

  vehicleYear: {
    type: "number",
  },

  NCDnext: {
    type: "string",
  },
  NCDnextDate: {
    type: "number",
  },
  NCDcoverPeriod: {
    type: "string",
  },
  NCDcurrent: {
    type: "string",
  },
  NCDcurrentDate: {
    type: "number",
  },
  NCDexpiryDate: {
    type: "number",
  },

  NCDupdatedDate: {
    type: "number",
  },
  previousProvider: {
    type: "string",
  },

  previousType: {
    type: "string",
  },

  //customer info
  phoneNo: {
    type: "string",
    example: "0174XXXXXX",
  },
  emailAddress: {
    type: "string",
    isEmail: true,
    maxLength: 200,
  },
  postcode: {
    type: "string",
    example: "12300",
  },

  name: {
    type: "string",
  },
  marriageStatus: {
    type: "string",
    isIn: ["notSet", "single", "married", "divorce"],
    defaultsTo: "notSet",
  },

  address: {
    type: "string",
  },

  source: {
    type: "string", //{"type:","value":""}
  },
  //  ╔═╗╔╦╗╔╗ ╔═╗╔╦╗╔═╗
  //  ║╣ ║║║╠╩╗║╣  ║║╚═╗
  //  ╚═╝╩ ╩╚═╝╚═╝═╩╝╚═╝

  //  ╔═╗╔═╗╔═╗╔═╗╔═╗╦╔═╗╔╦╗╦╔═╗╔╗╔╔═╗
  //  ╠═╣╚═╗╚═╗║ ║║  ║╠═╣ ║ ║║ ║║║║╚═╗
  //  ╩ ╩╚═╝╚═╝╚═╝╚═╝╩╩ ╩ ╩ ╩╚═╝╝╚╝╚═╝
  quotations: {
    collection: "Quotation",
    via: "customer",
  },
};
