/* eslint-disable react/jsx-curly-newline */
import React, { useContext, useState } from "react";
// reactstrap components
import { FormGroup, Form, Input, Modal, Row, Col, Label } from "reactstrap";
import Button from "reactstrap-button-loader";
import { useMutation, useQueryCache } from "react-query";
import ApiContext from "services/ApiContext";
import NotificationModal from "components/NotificationModal";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";

function AddQuotationModal(props) {
  const { history, setRenderKey } = props;
  const userId = useSelector((state) => state.User.user.id);
  // const csrf = useSelector((state) => state.User.csrf);
  const { Axios } = useContext(ApiContext);

  const [quotationModal, setQuotationModal] = useState(false);
  const [form, setForm] = useState({});
  // const { io } = useContext(ApiContext);
  const queryCache = useQueryCache();
  const [notificationModalObj, setNotificationModalObj] = useState({
    isOpen: false,
  });
  const [isFromSale, setIsFromSale] = useState(false);

  // useEffect(() => {
  //   io.socket.on("quotation", () => {
  //     setRenderKey(uuidv4());
  //   });
  // }, [io.socket, setRenderKey]);

  const [mutate, { isLoading }] = useMutation(
    async () => {
      let formData = { ...form, source: userId };

      if (!isFromSale) {
        formData = {
          ...formData,
          quotationPic: userId,
        };
      }

      await Axios.post("/quotation", formData);

      // await new Promise((res, rej) => {
      //   let formData = { ...form, source: userId };

      //   if (!isFromSale) {
      //     formData = {
      //       ...formData,
      //       quotationPic: userId,
      //     };
      //   }

      //   io.socket.post(
      //     `/api/quotation?_csrf=${encodeURIComponent(csrf)}`,
      //     formData,
      //     (resData, jwres) => {
      //       if (jwres.statusCode !== 200) {
      //         return rej();
      //       }

      //       return res();
      //     }
      //   );
      // });
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("quotationCount");
        setQuotationModal(false);
        setRenderKey(uuidv4());
      },
      onError: () => {
        alert("Failed to create quotation");
      },
    }
  );

  const toggleModal = () => {
    setForm({});
    setQuotationModal(!quotationModal);
  };

  const onChange = (e) => {
    form[e.target.name] = e.target.value;
    setForm(form);
  };

  return (
    <>
      {/* Button trigger modal */}
      <Button
        color="primary"
        type="button"
        className="float-right"
        onClick={() => toggleModal()}
      >
        Create a quotation
      </Button>
      {/* Modal */}
      <Modal
        className="modal-dialog-centered"
        isOpen={quotationModal}
        toggle={() => toggleModal()}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="userModalLabel">
            Create a quotation
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            <span aria-hidden>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <Row>
              <Col md="12" className="mb-4">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      onChange={(e) => {
                        setIsFromSale(e.target.checked);
                      }}
                    />
                    From sales team
                  </Label>
                </FormGroup>
              </Col>

              <Col md="12">
                <FormGroup>
                  <Label for="type">Vehicle Type</Label>
                  <Input
                    type="select"
                    name="type"
                    id="type"
                    onChange={onChange}
                  >
                    <option value="">Please select</option>
                    <option>car</option>
                    <option>lorry</option>
                    <option>motocycle</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="carPlate">Car Plate</Label>
                  <Input
                    name="carPlate"
                    placeholder="PAA1234"
                    type="string"
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="postcode">Postcode</Label>
                  <Input
                    name="postcode"
                    placeholder="12345"
                    type="string"
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="icNo">IC No</Label>
                  <Input
                    name="icNo"
                    placeholder="991212071212"
                    type="string"
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    name="name"
                    placeholder="John Doe"
                    type="string"
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>

              <Col md="12">
                <FormGroup>
                  <Label for="phoneNo">Phone No.</Label>
                  <Input
                    placeholder="Mobile Phone: 01xxxxxxxx"
                    className="no-spin"
                    type="number"
                    name="phoneNo"
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>

              <Col md="12">
                <FormGroup>
                  <Label for="email">Email</Label>

                  <Input
                    placeholder="Email: xxxx@xxxx.com"
                    type="email"
                    name="emailAddress"
                    autoComplete="email"
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>

              <Col md="12">
                <FormGroup>
                  <Label for="marriageStatus">Marriage Status</Label>
                  <Input
                    type="select"
                    name="marriageStatus"
                    onChange={onChange}
                  >
                    <option value="">Please select</option>
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                    <option value="divorce">Divorce</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            Close
          </Button>
          <Button
            color="success"
            type="button"
            loading={isLoading}
            onClick={() => {
              mutate();
            }}
          >
            Create
          </Button>
        </div>
      </Modal>

      <NotificationModal
        isOpen={notificationModalObj.isOpen}
        header={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() =>
              setNotificationModalObj({
                isOpen: false,
              })
            }
          >
            <span aria-hidden>×</span>
          </button>
        }
        body={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div className="py-3 text-center">
            <i className="fa fa-exclamation-triangle ni-3x" />

            <h4 className="heading mt-4">Existing record found!</h4>
            <p>
              We found a same record created within the year, do you want to
              nagivate to this record?
            </p>
            <Button
              color="primary"
              type="button"
              onClick={() =>
                history.push(
                  `/admin/quotations/${notificationModalObj.foundId}`
                )
              }
            >
              OK
            </Button>
            <Button
              color="secondary"
              type="button"
              onClick={() =>
                setNotificationModalObj({
                  isOpen: false,
                })
              }
            >
              No
            </Button>
          </div>
        }
        type="warning"
      />
    </>
  );
}

export default AddQuotationModal;
