/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views";
import Banners from "views/Banners";
import Reviews from "views/Reviews";
import Galleries from "views/Gallery";
import Profile from "./views/examples/Profile";
import Maps from "./views/examples/Maps";
import Register from "./views/examples/Register";
import Login from "./views/public/Login";
import Tables from "./views/examples/Tables";
import Icons from "./views/examples/Icons";
import Users from "./views/Users";
import Quotations from "./views/Quotations";
import AdminQuotation from "./views/Quotations/Quotation";

import Home from "./views/public/Home";
import Quotation from "./views/public/Quotation";
import NCD from "./views/public/NCD";
import Customers from "./views/Customers";
import Settings from "./views/Settings";
import PaymentStatus from "./views/public/PaymentStatus";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    exact: true,
    dev: true,
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin",
    dev: true,
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/admin",
    dev: true,
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
    dev: true,
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin",
    dev: true,
  },

  {
    path: "/users",
    name: "Users",
    icon: "ni ni-single-02 text-yellow",
    component: Users,
    layout: "/admin",
  },
  {
    path: "/customers",
    name: "Customers",
    icon: "ni ni-circle-08 text-blue",
    component: Customers,
    layout: "/admin",
  },
  {
    path: "/quotations",
    name: "Quotations",
    icon: "ni ni-bullet-list-67 text-red",
    component: Quotations,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/quotations/:id",
    name: "Quotation",
    skipMenu: true,
    component: AdminQuotation,
    layout: "/admin",
  },
  {
    path: "/banners",
    name: "Banners",
    component: Banners,
    icon: "ni ni-album-2 text-info",
    layout: "/admin",
  },
  {
    path: "/galleries",
    name: "Galleries",
    component: Galleries,
    icon: "ni ni-album-2 text-orange",
    layout: "/admin",
  },
  {
    path: "/reviews",
    name: "Reviews",
    component: Reviews,
    icon: "ni ni-chat-round text-pink",
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    icon: "ni ni-settings text-green",
    layout: "/admin",
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    layout: "/public",
    exact: true,
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/public",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/public",
  },
  {
    path: "/quotation/:id",
    name: "Quotation",
    component: Quotation,
    layout: "/public",
  },
  {
    path: "/ncd/:id",
    name: "NCD",
    component: NCD,
    layout: "/public",
  },
  {
    path: "/payment/:id/",
    name: "Payment",
    component: PaymentStatus,
    layout: "/public",
  },
];
export default routes;
