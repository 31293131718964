/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useImperativeHandle } from "react";
import {
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
  Row,
  Spinner,
  Col,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { useTable, usePagination, useSortBy } from "react-table";

const DataTable = forwardRef((props, ref) => {
  const {
    columns,
    data,
    fetchData,
    loading,
    pageCount: controlledPageCount,
    totalCount,
    renderKey,
    onSelectAllChange,
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
      manualSortBy: true,
    },
    useSortBy,
    usePagination
  );

  useImperativeHandle(ref, () => ({
    setTablePage(newPage) {
      gotoPage(newPage);
    },
  }));

  const renderPaginationOption = React.useCallback(
    (index) => (
      <PaginationItem
        className={index === pageIndex ? "active" : ""}
        key={index}
      >
        <PaginationLink onClick={() => gotoPage(index)}>
          {index + 1}
        </PaginationLink>
      </PaginationItem>
    ),
    [gotoPage, pageIndex]
  );

  React.useEffect(() => {
    fetchData({ pageIndex, pageSize, sortBy });
  }, [fetchData, pageIndex, pageSize, sortBy, renderKey, totalCount]);

  if (loading) {
    return (
      <div className="text-center">
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <>
      <Table
        className="align-items-center table-flush"
        responsive
        {...getTableProps}
      >
        <thead className="thead-light">
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`headerGroup${i}`}>
              {headerGroup.headers.map((column) => (
                <th
                  key={`header${i}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.isCheckbox && (
                    <Input
                      type="checkbox"
                      onChange={(e) => {
                        onSelectAllChange(e.target.checked);
                      }}
                      style={{
                        position: "relative",
                        verticalAlign: "middle",
                      }}
                    />
                  )}

                  {column.render("Header")}
                  <span>
                    {column.isSorted && (column.isSortedDesc ? " 🔽" : " 🔼")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={uuidv4()}>
                {row.cells.map((cell) => (
                  <td key={uuidv4()} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
          <tr>
            {loading ? (
              // Use our custom loading state to show a loading indicator
              <td colSpan="10000">Loading...</td>
            ) : (
              <td colSpan="10000">
                {`Showing ${page.length} of ${totalCount} results`}
              </td>
            )}
          </tr>
        </tbody>
      </Table>

      <Row>
        <Col md="3" className="pl-md-4 ">
          <Input
            className="px-1"
            type="select"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((selectedPageSize) => (
              <option key={selectedPageSize} value={selectedPageSize}>
                {`Show ${selectedPageSize}`}
              </option>
            ))}
          </Input>
        </Col>
        <Col>
          <nav aria-label="...">
            <Pagination
              className="pagination justify-content-end mb-0"
              listClassName="justify-content-end mb-0"
            >
              <PaginationItem>
                <PaginationLink
                  onClick={(e) => {
                    e.preventDefault();
                    gotoPage(0);
                  }}
                >
                  <i className="fas fa-angle-double-left" />
                  <span className="sr-only">First</span>
                </PaginationLink>
              </PaginationItem>

              <PaginationItem className={!canPreviousPage ? "disabled" : ""}>
                <PaginationLink
                  onClick={(e) => {
                    e.preventDefault();
                    previousPage();
                  }}
                  tabIndex="-1"
                >
                  <i className="fas fa-angle-left" />
                  <span className="sr-only">Previous</span>
                </PaginationLink>
              </PaginationItem>
              {pageOptions
                .slice(
                  Math.max(0, pageIndex - 5),
                  Math.min(pageOptions.length, pageIndex + 5)
                )
                .map((index) => renderPaginationOption(index))}

              <PaginationItem className={!canNextPage ? "disabled" : ""}>
                <PaginationLink
                  onClick={(e) => {
                    e.preventDefault();
                    nextPage();
                  }}
                  disabled={!canNextPage}
                >
                  <i className="fas fa-angle-right" />
                  <span className="sr-only">Next</span>
                </PaginationLink>
              </PaginationItem>

              <PaginationItem>
                <PaginationLink
                  onClick={(e) => {
                    e.preventDefault();
                    gotoPage(pageOptions[pageOptions.length - 1]);
                  }}
                >
                  <i className="fas fa-angle-double-right" />
                  <span className="sr-only">Last</span>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </nav>
        </Col>
      </Row>
    </>
  );
});

export default DataTable;
