import React, { useCallback, useContext, useState } from "react";
import {
  Card,
  // CardBody,
  Col,
  CardHeader,
  CardBody,
  Row,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  Input,
  // Row,
  // Input,
  // FormGroup,
  // Label,
  // InputGroupAddon,
  // InputGroup,
} from "reactstrap";

import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "reactstrap-button-loader";

import ApiContext from "services/ApiContext";
import { useMutation, useQuery, useQueryCache } from "react-query";
import Spinner from "reactstrap/lib/Spinner";
import moment from "moment";
import AddQuotationDetailModal from "./AddQuotationDetailModal";
import SendQuotationModal from "./SendQuotationModal";

export default function AdminBar(props) {
  const { quotationId, data } = props;

  const { Axios } = useContext(ApiContext);
  const queryCache = useQueryCache();

  const [selectedDetail, setSelectedDetail] = React.useState({});
  const [quotationModal, setQuotationModal] = React.useState(false);
  const [sendQuotationModal, setSendQuotationModal] = useState(false);
  const [isCopied, setCopied] = useState(false);
  const [form, setForm] = useState({
    windscreenSumInsured: 0,
    roadTax: 0,
  });

  const toggleModal = () => {
    setQuotationModal(!quotationModal);
  };

  const toggleSendQuotationModal = useCallback(() => {
    setSendQuotationModal(!sendQuotationModal);
  }, [sendQuotationModal]);

  const onChange = useCallback(
    (e) => {
      const newForm = { ...form };
      newForm[e.target.name] = e.target.value;
      setForm(newForm);
    },
    [form]
  );

  const { isLoading } = useQuery(
    `quotation/${quotationId}`,
    async () => Axios.get(`/quotation/${quotationId}`),
    {
      onSuccess: (resp) => {
        setForm({
          windscreenSumInsured: resp.windscreenSumInsured,
          roadTax: resp.roadTax,
        });
      },
      onError: () => {
        alert("Failed to get data, please refresh the page");
      },
    }
  );

  const [mutate, { isLoading: mutateLoading }] = useMutation(
    async (newForm) => {
      await Axios.patch(`/quotation/${quotationId}`, newForm);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(`quotation/${quotationId}`);
        queryCache.invalidateQueries(`quotationDetail/${quotationId}`);
      },
      onError: () => {
        alert("Failed to update, please try to refresh the page");
      },
    }
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Col md="12" className="mb-2">
        <Card className="shadow">
          <CardHeader>
            <h5>Admin Bar</h5>
            {data.quotationDate &&
              (data.status === "quotationSent" ||
                data.status === "quotationReady") && (
                <h6>
                  {`Quotation Date: ${moment(data.quotationDate).format(
                    "DD/MM/YYYY"
                  )}`}
                </h6>
              )}

            <Button
              className="btn-icon"
              color={
                data.status !== "quotationSent" &&
                data.status !== "quotationReady"
                  ? "info"
                  : "success"
              }
              type="button"
              loading={mutateLoading}
              onClick={() => {
                mutate({
                  status:
                    data.status !== "quotationSent" &&
                    data.status !== "quotationReady"
                      ? "quotationReady"
                      : "pendingQuotation",

                  quotationDate: +new Date(),
                });
              }}
            >
              {data.status !== "quotationSent" &&
              data.status !== "quotationReady"
                ? "Not Ready"
                : ""}
              {data.status === "expired" ? " Quotation Expired" : ""}
              {data.status === "quotationSent" ? "Quotation Sent" : ""}
              {data.status === "quotationReady" ? "Quotation Ready" : ""}
            </Button>

            <Button
              className="btn-icon"
              color="success"
              type="button"
              onClick={() => {
                setSendQuotationModal(true);
                mutate({
                  status: "quotationSent",
                });
              }}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-send" />
              </span>
              Send quotation!
            </Button>
            <CopyToClipboard
              text={window.location.href}
              onCopy={() => {
                setCopied(true);
              }}
            >
              <Button className="btn-icon" color="primary" type="button">
                {isCopied ? (
                  <>
                    <span className="btn-inner--icon">
                      <i className="ni ni-check-bold" />
                    </span>
                    Copied
                  </>
                ) : (
                  <>
                    <span className="btn-inner--icon">
                      <i className="ni ni-ungroup" />
                    </span>
                    Copy Url
                  </>
                )}
              </Button>
            </CopyToClipboard>
            <Button
              className="btn-icon"
              color="primary"
              type="button"
              onClick={() => {
                setSelectedDetail({});
                toggleModal();
              }}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-add" />
              </span>
              Add quotation
            </Button>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label for="windscreenSumInsured">
                    Windsreen Sum Insured
                  </Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">RM</InputGroupAddon>

                    <Input
                      placeholder="xxxx.00"
                      className="no-spin"
                      type="number"
                      name="windscreenSumInsured"
                      value={form.windscreenSumInsured}
                      onChange={onChange}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label for="roadTax">Roadtax</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">RM</InputGroupAddon>

                    <Input
                      placeholder="xxxx.00"
                      className="no-spin"
                      type="number"
                      name="roadTax"
                      value={form.roadTax}
                      onChange={onChange}
                    />

                    <Button
                      color="primary"
                      outline
                      type="button"
                      onClick={() => {
                        Axios.post(
                          `/detail/${quotationId}/calculate-road-tax`
                        ).then((amount) => {
                          setForm({
                            roadTax: amount,
                          });
                        });
                      }}
                    >
                      Calculate
                    </Button>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Button
                  color="primary"
                  data-dismiss="modal"
                  type="button"
                  loading={mutateLoading}
                  onClick={() => mutate(form)}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <AddQuotationDetailModal
        quotationId={quotationId}
        quotationModal={quotationModal}
        toggleModal={toggleModal}
        details={selectedDetail}
      />
      <SendQuotationModal
        isOpen={sendQuotationModal}
        toggleModal={toggleSendQuotationModal}
        quotationId={quotationId}
      />
    </>
  );
}
