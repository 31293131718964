/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

// reactstrap components
import {
  Card,
  CardHeader,
  Progress,
  Container,
  Input,
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";
import Button from "reactstrap-button-loader";

import ApiContext from "services/ApiContext";

import { useMutation, useQueryCache } from "react-query";

// core components
import Header from "components/Headers/Header";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import DataTable from "components/DataTable";
import { useSelector } from "react-redux";
import DatepickerRange from "components/DatepickerRange";
import AddQuotationModal from "./AddQuotationModal";

function Quotations(props) {
  const { history } = props;

  const userId = useSelector((state) => state.User.user.id);

  const [data, setData] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [renderKey, setRenderKey] = React.useState(uuidv4());
  const [checkedRows, setCheckedRows] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState({
    key: "carPlate",
    display: "Car Plate",
  });
  const [searchValue, setSearchValue] = React.useState("");
  const [filterAssign, setFilterAssign] = useState(false);
  const [filterStatus, setFilterStatus] = useState(null);
  const [filterWeb, setFilterWeb] = useState(true);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateSearchBy, setDateSearchBy] = useState({
    key: "NCDexpiryDate",
    display: "Expiry Date",
  });
  const [quotationCount, setQuotationCount] = useState(0);
  const [isError, setIsError] = useState(false);

  const { Axios } = useContext(ApiContext);
  const queryCache = useQueryCache();

  const tableRef = useRef();

  // const {
  //   data: quotationCount,
  //   isLoading: quotationLoading,
  //   isError: countError,
  //   isSuccess,
  // } = useQuery("quotationCount", async () => Axios.get("/quotation/count"));

  const contactTypeOptions = useMemo(
    () => [
      {
        color: "success",
        value: "WhatsApp",
      },
      {
        color: "success",
        value: "Call",
      },
      {
        color: "success",
        value: "Wechat",
      },
      {
        color: "success",
        value: "Walk-in",
      },
      {
        color: "success",
        value: "SMS",
      },
      {
        color: "warning",
        value: "No pick up",
      },
      {
        color: "warning",
        value: "No service",
      },
    ],
    []
  );

  const [mutateDelete, { isLoading: isMutateDeleteLoading }] = useMutation(
    async (quotationId) => {
      await Axios.delete(`/quotation/${quotationId}`);
    },
    {
      onSuccess: () => {
        setRenderKey(uuidv4());
        queryCache.invalidateQueries("quotationCount");
      },
      onError: () => {
        alert("Failed to remove the record, please try to refresh the page");
      },
    }
  );

  const [mutateAssign, { isLoading: isMutateAssignLoading }] = useMutation(
    async ({ quotationId, type }) => {
      await Axios.post(`/quotation/${quotationId}/assign-to-self`, { type });
    },
    {
      onSuccess: () => {
        setRenderKey(uuidv4());
        queryCache.invalidateQueries("quotationCount");
      },
      onError: () => {
        alert("Request failed, please try again later");
      },
    }
  );

  const [mutate, { isLoading: isMutateLoading }] = useMutation(
    async (form) => {
      await Axios.patch(`/quotation/${form.quotationId}`, form.detail);
    },
    {
      onSuccess: () => {
        setRenderKey(uuidv4());
      },
      onError: () => {
        alert("Request failed, please try again later");
      },
    }
  );

  const deleteAlert = React.useCallback(
    (quotationId) => {
      confirmAlert({
        title: "Confirm to delete",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              mutateDelete(quotationId);
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    },
    [mutateDelete]
  );

  const deleteAllCheckedAlert = React.useCallback(() => {
    confirmAlert({
      title: `Confirm to delete ${checkedRows.length} quotation(s)`,
      message: "Are you sure to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            checkedRows.map((row) => mutateDelete(row.id));
            setCheckedRows([]);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }, [checkedRows, mutateDelete]);

  const toggleAllCheckbox = React.useCallback(
    (isChecked) => {
      if (isChecked) {
        setCheckedRows(data);
      } else {
        setCheckedRows([]);
      }
    },
    [data]
  );

  const toggleCheckbox = React.useCallback(
    (row, isChecked) => {
      const newCheckedRows = [...checkedRows];

      if (isChecked) {
        newCheckedRows.push(row);
      } else {
        const index = newCheckedRows.indexOf(row);
        if (index > -1) {
          newCheckedRows.splice(index, 1);
        }
      }
      setCheckedRows(newCheckedRows);
    },
    [checkedRows]
  );

  const retrieveLatestNCD = React.useCallback(
    async (quotationId) => {
      try {
        await Axios.post(`/quotation/${quotationId}/rerun-job`, {
          type: "ncdcheck",
        });
      } catch {
        alert(
          "Failed to initiate to retrieve the latest NCD, please try refreshing the page"
        );
      }
    },
    [Axios]
  );

  const checkAllNCDAlert = React.useCallback(() => {
    confirmAlert({
      title: "Initiate NCD checking for these customer(s)?",
      message: "Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            checkedRows.map((row) => retrieveLatestNCD(row.id));
            setCheckedRows([]);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }, [checkedRows, retrieveLatestNCD]);

  const mutateLoading =
    isMutateAssignLoading || isMutateDeleteLoading || isMutateLoading;

  const columns = React.useMemo(
    () => [
      {
        Header: " ",
        isCheckbox: true,
        Cell: ({ row }) => (
          <Input
            type="checkbox"
            checked={checkedRows.indexOf(row.original) !== -1}
            onChange={(e) => {
              toggleCheckbox(row.original, e.target.checked);
            }}
            style={{
              position: "relative",
              verticalAlign: "middle",
            }}
          />
        ),
      },
      {
        Header: "Created Date",
        accessor: "createdAt",
        Cell: ({ value }) => {
          if (value === 0) {
            return "";
          }
          return new Date(value).toDateString();
        },
      },
      {
        Header: "Name",
        accessor: "name",
      },

      {
        Header: "IC No",
        accessor: "icNo",
      },

      {
        Header: "Type",
        accessor: "type",
      },

      {
        Header: "Car Plate",
        accessor: "carPlate",
        Cell: ({ row, value }) => (
          <a
            href={`/admin/quotations/${row.original.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value}
          </a>
        ),
      },

      {
        Header: "Expiry Date",
        accessor: "NCDexpiryDate",
        Cell: ({ value }) => {
          if (value === 0) {
            return "";
          }

          const ncdDate = new Date(value);

          if (new Date() >= ncdDate) {
            return <Badge color="danger">{ncdDate.toDateString()}</Badge>;
          }

          return ncdDate.toDateString();
        },
      },
      {
        Header: "Phone No",
        accessor: "phoneNo",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (cell) => {
          let display = "";
          let percentage = "0";
          let color = "warning";

          switch (cell.value) {
            case "detailFilled": {
              display = "Detail Filled";
              percentage = "10";
              color = "warning";

              break;
            }

            case "error": {
              display = "Error";
              percentage = "100";
              color = "danger";

              break;
            }

            case "noSuchRecord": {
              display = "No record";
              percentage = "100";
              color = "danger";

              break;
            }

            case "pendingCarInfo": {
              display = "NCD Retrieved";
              percentage = "50";
              color = "warning";

              break;
            }

            case "tooEarlyRenew": {
              display = "Too early to renew";
              percentage = "100";
              color = "warning";

              break;
            }

            case "pendingQuotation": {
              display = "Pending Quotation";
              percentage = "60";
              color = "primary";

              break;
            }

            case "quotationReady": {
              display = "Quotation Ready";
              percentage = "70";
              color = "info";

              break;
            }

            case "quotationSent": {
              display = "Quotation Sent";
              percentage = "80";
              color = "info";

              break;
            }

            case "expired": {
              display = "Quotation Expired";
              percentage = "80";
              color = "warning";

              break;
            }
            case "paid": {
              display = "Paid";
              percentage = "90";
              color = "success";
              break;
            }
            case "processed": {
              display = "Processed";
              percentage = "100";
              color = "success";

              break;
            }
            case "dropped": {
              display = "Dropped";
              percentage = "100";
              color = "danger";

              break;
            }

            default: {
              display = cell.value;
              percentage = "0";
              color = "primary";
            }
          }

          return (
            <>
              <Badge color="" className="badge-dot mr-4">
                <i className={`bg-${color}`} />
                {display}
              </Badge>

              <Progress max="100" value={percentage} color={color} />
            </>
          );
        },
      },
      {
        Header: "Quotation Pic",
        accessor: "quotationPic",
        Cell: ({ value, row }) => {
          if (value) {
            let string = `${value.fullName} `;

            if (row.original.quotationDate) {
              string += new Date(row.original.quotationDate).toDateString();
            }

            return string;
          }

          return (
            <Button
              color="primary"
              size="sm"
              disabled={mutateLoading}
              onClick={() => {
                mutateAssign({
                  quotationId: row.original.id,
                  type: "quotationPic",
                });
              }}
            >
              Assign To Me
            </Button>
          );
        },
      },
      {
        Header: "Contact Pic",
        accessor: "contactPic",
        Cell: ({ value, row }) => {
          if (value) {
            const color = contactTypeOptions.find(
              (option) => option.value === row.original.contactType
            )?.color;

            return (
              <>
                {`${value.fullName} `}
                <UncontrolledDropdown size="sm">
                  <DropdownToggle caret color={color}>
                    {row.original.contactType}
                  </DropdownToggle>
                  <DropdownMenu>
                    {contactTypeOptions.map((option) => (
                      <DropdownItem
                        disabled={isMutateLoading}
                        value={option.value}
                        key={option.value}
                        onClick={() => {
                          mutate({
                            quotationId: row.original.id,
                            detail: {
                              contactType: option.value,
                            },
                          });
                        }}
                      >
                        {option.value}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>
            );
          }

          return (
            <Button
              color="primary"
              size="sm"
              disabled={mutateLoading}
              onClick={() => {
                mutateAssign({
                  quotationId: row.original.id,
                  type: "contactPic",
                });
              }}
            >
              Assign To Me
            </Button>
          );
        },
      },

      {
        Header: "Operation",
        Cell: (cell) => (
          <UncontrolledDropdown>
            <DropdownToggle caret size="sm">
              Operation
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                disabled={mutateLoading}
                onClick={() => {
                  history.push(`/admin/quotations/${cell.row.original.id}`);
                }}
              >
                View Detail
              </DropdownItem>
              <DropdownItem
                disabled={mutateLoading}
                onClick={() => {
                  window.open(`/quotation/${cell.row.original.id}`, "_blank");
                }}
              >
                View Quotation
              </DropdownItem>
              <DropdownItem divider />

              <DropdownItem divider />
              <DropdownItem
                disabled={mutateLoading}
                onClick={() => {
                  deleteAlert(cell.row.original.id);
                }}
              >
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ),
      },

      {
        Header: "Remarks",
        accessor: "remark",
      },
    ],
    [
      checkedRows,
      contactTypeOptions,
      deleteAlert,
      history,
      isMutateLoading,
      mutate,
      mutateAssign,
      mutateLoading,
      toggleCheckbox,
    ]
  );

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.setTablePage(0);
    }
  }, [searchValue]);

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      const retrieveCount = async (filter) => {
        const url = "/quotation/count";

        if (Object.keys(filter).length > 0) {
          return Axios.get(url, {
            params: {
              criteria: { where: filter },
            },
          });
        }

        return Axios.get(url);
      };

      try {
        setLoading(true);

        const skip = pageSize * pageIndex;

        let url = `/quotation?skip=${skip}&limit=${pageSize}`;

        if (!sortBy || sortBy.length === 0) {
          url += "&sort=createdAt DESC";
        } else {
          url += sortBy
            .map((column) => {
              const desc = column.desc ? "DESC" : "ASC";
              return `&sort=${column.id} ${desc}`;
            })
            .reduce((prev, cur) => prev + cur, "");
        }

        const wherefilter = {};

        if (startDate || endDate) {
          const startingPoint = startDate?.valueOf();
          const endingPoint = endDate?.valueOf();

          wherefilter[dateSearchBy.key] = {
            ">=": startingPoint,
            "<=": endingPoint,
          };
        }

        if (searchValue !== "") {
          let searchValuePtr = searchValue;
          if (searchBy.key !== "name" && searchBy.key !== "remark") {
            searchValuePtr = searchValue.toUpperCase();
          }
          wherefilter[searchBy.key] = {
            contains: searchValuePtr,
          };
        }
        if (filterWeb) {
          wherefilter.source = "web";
        }

        if (filterAssign) {
          wherefilter.or = [
            {
              quotationPic: userId,
            },
            {
              contactPic: userId,
            },
          ];
        }

        if (filterStatus) {
          switch (filterStatus) {
            case "open":
              wherefilter.status = {
                "!=": [
                  "processed",
                  "tooEarlyRenew",
                  "dropped",
                  "error",
                  "noSuchRecord",
                  "failToGetCarInfo",
                ],
              };
              break;
            default:
              wherefilter.status = filterStatus;
          }
        }

        if (Object.keys(wherefilter) !== 0) {
          url += `&where=${JSON.stringify(wherefilter)}`;
        }

        const [count, response] = await Promise.all([
          retrieveCount(wherefilter),
          Axios.get(url),
        ]);

        setQuotationCount(count);
        setData(response);
        setPageCount(Math.ceil(count / pageSize));
      } catch (_error) {
        setIsError(true);
      } finally {
        setLoading(false);
      }
    },
    [
      Axios,
      dateSearchBy.key,
      endDate,
      filterAssign,
      filterStatus,
      filterWeb,
      searchBy.key,
      searchValue,
      startDate,
      userId,
    ]
  );

  const searchByItems = useMemo(
    () => [
      {
        key: "carPlate",
        display: "Car Plate",
      },
      {
        key: "icNo",
        display: "Ic No.",
      },
      {
        key: "vehicleModel",
        display: "Vehicle Model",
      },
      {
        key: "phoneNo",
        display: "Phone No",
      },
      {
        key: "name",
        display: "Name",
      },
      {
        key: "remark",
        display: "Remark",
      },
    ],
    []
  );

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader className="border-0">
            <Row>
              <Col md="3">
                <FormGroup>
                  <UncontrolledDropdown>
                    <DropdownToggle caret>
                      {"Search By "}
                      {searchBy.display}
                    </DropdownToggle>
                    <DropdownMenu>
                      {searchByItems.map((searchByItem) => (
                        <DropdownItem
                          key={searchByItem.key}
                          onClick={() => {
                            setSearchBy({
                              ...searchByItem,
                            });
                          }}
                        >
                          {searchByItem.display}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <InputGroup className="mb-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-zoom-split-in" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={searchBy.display}
                      value={searchValue}
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      type="text"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3" className="text-right">
                <AddQuotationModal
                  history={history}
                  setRenderKey={setRenderKey}
                />
              </Col>
            </Row>

            <DatepickerRange
              searchByItems={[
                {
                  key: "NCDexpiryDate",
                  display: "Expiry Date",
                },
              ]}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              searchBy={dateSearchBy}
              setSearchBy={setDateSearchBy}
            />
            <Row>
              <Col md="12">
                <Button
                  color="info"
                  className="btn-icon btn-3 mb-3"
                  outline={!filterWeb}
                  type="button"
                  onClick={() => {
                    setFilterWeb(!filterWeb);
                  }}
                >
                  <span className="btn-inner--icon">
                    {filterWeb && <i className="ni ni-check-bold" />}
                    {!filterWeb && <i className="far fa-square" />}
                  </span>
                  From Web
                </Button>
                <Button
                  color="primary"
                  className="btn-icon btn-3 mb-3"
                  outline={filterWeb || filterAssign || filterStatus !== null}
                  type="button"
                  onClick={() => {
                    setFilterAssign(false);
                    setFilterStatus(null);
                    setFilterWeb(false);
                  }}
                >
                  <span className="btn-inner--icon">
                    {!filterWeb && !filterAssign && !filterStatus && (
                      <i className="ni ni-check-bold" />
                    )}
                    {(filterWeb || filterAssign || filterStatus) && (
                      <i className="far fa-square" />
                    )}
                  </span>
                  All
                </Button>
                <Button
                  color="primary"
                  className="btn-icon btn-3 mb-3"
                  outline={!filterAssign}
                  onClick={() => {
                    setFilterAssign(!filterAssign);
                  }}
                  type="button"
                >
                  <span className="btn-inner--icon">
                    {filterAssign ? (
                      <i className="ni ni-check-bold" />
                    ) : (
                      <i className="far fa-square" />
                    )}
                  </span>
                  Assigned To me
                </Button>
                <Button
                  color="primary"
                  className="btn-icon btn-3 mb-3"
                  outline={filterStatus !== "open"}
                  onClick={() => {
                    if (filterStatus === "open") {
                      setFilterStatus(null);
                      return;
                    }
                    setFilterStatus("open");
                  }}
                  type="button"
                >
                  <span className="btn-inner--icon">
                    {filterStatus === "open" ? (
                      <i className="ni ni-check-bold" />
                    ) : (
                      <i className="far fa-square" />
                    )}
                  </span>
                  Open
                </Button>

                <Button
                  color="primary"
                  className="btn-icon btn-3 mb-3"
                  outline={filterStatus !== "pendingQuotation"}
                  type="button"
                  onClick={() => {
                    if (filterStatus === "pendingQuotation") {
                      setFilterStatus(null);
                      return;
                    }
                    setFilterStatus("pendingQuotation");
                  }}
                >
                  <span className="btn-inner--icon">
                    {filterStatus === "pendingQuotation" ? (
                      <i className="ni ni-check-bold" />
                    ) : (
                      <i className="far fa-square" />
                    )}
                  </span>
                  Pending Quotation
                </Button>

                <Button
                  color="primary"
                  className="btn-icon btn-3 mb-3"
                  outline={filterStatus !== "expired"}
                  type="button"
                  onClick={() => {
                    if (filterStatus === "expired") {
                      setFilterStatus(null);
                      return;
                    }
                    setFilterStatus("expired");
                  }}
                >
                  <span className="btn-inner--icon">
                    {filterStatus === "expired" ? (
                      <i className="ni ni-check-bold" />
                    ) : (
                      <i className="far fa-square" />
                    )}
                  </span>
                  Quotation Expired
                </Button>

                <Button
                  color="primary"
                  className="btn-icon btn-3 mb-3"
                  outline={filterStatus !== "quotationReady"}
                  type="button"
                  onClick={() => {
                    if (filterStatus === "quotationReady") {
                      setFilterStatus(null);
                      return;
                    }
                    setFilterStatus("quotationReady");
                  }}
                >
                  <span className="btn-inner--icon">
                    {filterStatus === "quotationReady" ? (
                      <i className="ni ni-check-bold" />
                    ) : (
                      <i className="far fa-square" />
                    )}
                  </span>
                  Quotation Ready
                </Button>

                <Button
                  color="primary"
                  className="btn-icon btn-3 mb-3"
                  outline={filterStatus !== "quotationSent"}
                  type="button"
                  onClick={() => {
                    if (filterStatus === "quotationSent") {
                      setFilterStatus(null);
                      return;
                    }
                    setFilterStatus("quotationSent");
                  }}
                >
                  <span className="btn-inner--icon">
                    {filterStatus === "quotationSent" ? (
                      <i className="ni ni-check-bold" />
                    ) : (
                      <i className="far fa-square" />
                    )}
                  </span>
                  Quotation Sent
                </Button>

                <Button
                  color="primary"
                  className="btn-icon btn-3 mb-3"
                  outline={filterStatus !== "processed"}
                  type="button"
                  onClick={() => {
                    if (filterStatus === "processed") {
                      setFilterStatus(null);
                      return;
                    }
                    setFilterStatus("processed");
                  }}
                >
                  <span className="btn-inner--icon">
                    {filterStatus === "processed" ? (
                      <i className="ni ni-check-bold" />
                    ) : (
                      <i className="far fa-square" />
                    )}
                  </span>
                  Processed
                </Button>

                <Button
                  color="primary"
                  className="btn-icon btn-3 mb-3"
                  outline={filterStatus !== "paid"}
                  type="button"
                  onClick={() => {
                    if (filterStatus === "paid") {
                      setFilterStatus(null);
                      return;
                    }
                    setFilterStatus("paid");
                  }}
                >
                  <span className="btn-inner--icon">
                    {filterStatus === "paid" ? (
                      <i className="ni ni-check-bold" />
                    ) : (
                      <i className="far fa-square" />
                    )}
                  </span>
                  Paid
                </Button>
                <Button
                  color="primary"
                  className="btn-icon btn-3 mb-3"
                  outline={filterStatus !== "dropped"}
                  type="button"
                  onClick={() => {
                    if (filterStatus === "dropped") {
                      setFilterStatus(null);
                      return;
                    }
                    setFilterStatus("dropped");
                  }}
                >
                  <span className="btn-inner--icon">
                    {filterStatus === "dropped" ? (
                      <i className="ni ni-check-bold" />
                    ) : (
                      <i className="far fa-square" />
                    )}
                  </span>
                  Dropped
                </Button>
              </Col>
            </Row>
            {checkedRows.length > 0 && (
              <Row style={{ marginTop: 10 }}>
                <Col className="text-right">
                  <Button
                    color="danger"
                    onClick={() => {
                      deleteAllCheckedAlert();
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      checkAllNCDAlert();
                    }}
                  >
                    Check For Latest NCD
                  </Button>
                </Col>
              </Row>
            )}
          </CardHeader>

          <DataTable
            ref={tableRef}
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            totalCount={quotationCount}
            renderKey={renderKey}
            onSelectAllChange={toggleAllCheckbox}
          />

          {isError && (
            <div className="text-center">
              <i className="fa fa-exclamation-triangle ni-3x" />
              <h4 className="heading mt-4">Failed to retrieve data</h4>
            </div>
          )}
        </Card>
      </Container>
    </>
  );
}

export default Quotations;
