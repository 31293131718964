/* eslint-disable react/jsx-props-no-spreading */
/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useContext, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import Button from "reactstrap-button-loader";
import { v4 as uuidv4 } from "uuid";

import ApiContext from "services/ApiContext";

import { useQuery, useMutation, useQueryCache } from "react-query";
import { useTable } from "react-table";

// core components
import Header from "components/Headers/Header";
import { confirmAlert } from "react-confirm-alert"; // Import
import AddUserModal from "./AddUserModal";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

function Users() {
  const { Axios } = useContext(ApiContext);
  const queryCache = useQueryCache();
  const [editUser, setEditUser] = useState({
    isEdit: false,
    user: {},
    isOpen: false,
  });

  const { data, error, isLoading, isSuccess } = useQuery("users", async () =>
    Axios.get("/user")
  );

  if (error) {
    alert("Failed to get users, please try again later");
  }

  const [mutate, { isLoading: isMutateLoading }] = useMutation(
    async (userId) => {
      await Axios.delete(`/user/${userId}`);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("users");
      },
    }
  );

  const deleteAlert = useCallback(
    (userId) => {
      confirmAlert({
        title: "Confirm to delete",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              mutate(userId);
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    },
    [mutate]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "User Id",
        accessor: "id", // accessor is the "key" in the data
      },
      {
        Header: "Full Name",
        accessor: "fullName", // accessor is the "key" in the data
      },
      {
        Header: "Email Address",
        accessor: "emailAddress",
      },
      {
        Header: "Role",
        accessor: "role",
      },
      {
        Header: "Operation",
        Cell: (cell) => (
          <>
            <Button
              className="btn-icon btn-2"
              color="warning"
              type="button"
              disabled={isMutateLoading}
              onClick={() => {
                setEditUser({
                  isEdit: true,
                  isOpen: true,
                  user: cell.row.original,
                });
              }}
            >
              Edit
            </Button>
            <Button
              className="btn-icon btn-2"
              color="danger"
              type="button"
              disabled={isMutateLoading}
              onClick={() => {
                deleteAlert(cell.row.original.id);
              }}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-remove" />
              </span>
            </Button>
          </>
        ),
      },
    ],
    [deleteAlert, isMutateLoading]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: data || [] });

  // function renderPaginationOption(index) {
  //   return (
  //     <PaginationItem
  //       className={index === pageIndex ? "active" : ""}
  //       key={index}
  //     >
  //       <PaginationLink href="#pablo" onClick={() => gotoPage(index)}>
  //         {index + 1}
  //       </PaginationLink>
  //     </PaginationItem>
  //   );
  // }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        {isLoading ? (
          <Row>
            <Col>
              <Spinner color="primary" />
            </Col>
          </Row>
        ) : null}
        {isSuccess ? (
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <AddUserModal
                    isEdit={editUser.isEdit}
                    user={editUser.user}
                    isOpen={editUser.isOpen}
                  />
                </CardHeader>
                <Table
                  className="align-items-center table-flush"
                  responsive
                  {...getTableProps}
                >
                  <thead className="thead-light">
                    {
                      // Loop over the header rows
                      headerGroups.map((headerGroup) => (
                        // Apply the header row props
                        <tr
                          key={uuidv4()}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {
                            // Loop over the headers in each row
                            headerGroup.headers.map((column) => (
                              // Apply the header cell props
                              <th key={uuidv4()} {...column.getHeaderProps()}>
                                {
                                  // Render the header
                                  column.render("Header")
                                }
                              </th>
                            ))
                          }
                        </tr>
                      ))
                    }
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {
                      // Loop over the table rows
                      rows.map((row) => {
                        // Prepare the row for display
                        prepareRow(row);
                        return (
                          // Apply the row props
                          <tr key={uuidv4()} {...row.getRowProps()}>
                            {
                              // Loop over the rows cells
                              row.cells.map((cell) => (
                                // Apply the cell props
                                <td key={uuidv4()} {...cell.getCellProps()}>
                                  {
                                    // Render the cell contents
                                    cell.render("Cell")
                                  }
                                </td>
                              ))
                            }
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </Table>
                {/* <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem
                        className={!canPreviousPage ? "disabled" : ""}
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      {pageOptions.map((index) => {
                        return renderPaginationOption(index);
                      })}

                      <PaginationItem
                        className={!canNextPage ? "disabled" : ""}
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          disabled={!canNextPage}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter> */}
              </Card>
            </div>
          </Row>
        ) : null}
      </Container>
    </>
  );
}

export default Users;
