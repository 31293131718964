import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Row,
  Spinner,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import AddBotJobModal from "../AddBotJobModal";

export default function BotStatus(props) {
  const { quotationId } = props;
  const { Axios } = useContext(ApiContext);

  const {
    data: jobData,
    isSuccess: isJobDataSuccess,
  } = useQuery(`quotation/${quotationId}/jobs`, async () =>
    Axios.get(`/quotation/${quotationId}/jobs`)
  );

  const [isCollapse, setIsCollapse] = useState(false);

  const toggleCollapse = () => {
    setIsCollapse(!isCollapse);
  };

  if (!isJobDataSuccess) {
    return null;
  }
  const renderJobCard = (job) => {
    let color = "primary";

    switch (job.state) {
      case "wait":
      case "delay":
        color = "primary";
        break;
      case "active":
        color = "info";
        break;
      case "completed":
        color = "success";
        break;
      case "failed":
        color = "danger";
        break;
      default:
        color = "primary";
    }

    return (
      <Col md="4" key={job.id}>
        <Card className="card-stats mb-4 mb-lg-0">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle className="text-uppercase text-muted mb-0">
                  {`${job.type} ${job.insuranceName}`}
                </CardTitle>
              </div>
              <Col className="col-auto">
                <Badge color={color} onClick={(e) => e.preventDefault()}>
                  {job.state}
                </Badge>
                {job.state === "active" && <Spinner color="info" />}
              </Col>
              {/* {job.state !== "wait" &&
                job.state !== "delay" &&
                job.state !== "active" && (
                  <Col xs="12" className="mt-3">
                    <Button
                      color="primary"
                      type="button"
                      size="sm"
                      loading={isMutateRerunLoading}
                      onClick={() => {
                        confirmRerunAlert(job.type, job.insuranceName);
                      }}
                    >
                      Re-run
                    </Button>
                  </Col>
                )} */}
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  };

  return (
    <Card className="mb-3">
      <CardHeader
        onClick={() => {
          toggleCollapse();
        }}
      >
        <Row className="align-items-center">
          <Col xs="8">Bot Status</Col>
          <Col className="text-right" xs="4">
            {isCollapse ? (
              <i className=" ni ni-bold-down" />
            ) : (
              <i className=" ni ni-bold-up" />
            )}
          </Col>
        </Row>
      </CardHeader>

      <Collapse isOpen={isCollapse}>
        <CardBody>
          <Row className="mb-3">
            <Col>
              <AddBotJobModal quotationId={quotationId} />
            </Col>
          </Row>

          <Row>{jobData.map((job) => renderJobCard(job))}</Row>
        </CardBody>
      </Collapse>
    </Card>
  );
}
