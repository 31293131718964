import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    setLang: ["lang"],
  },
  {}
);
export const LangTypes = Types;
export default Creators;
